// Import React Modules
import React, { Fragment } from "react";

// Import Redux Modules
import { connect } from "react-redux";
import {
  getWaterFietsClients,
  getOneUseLoginJWT,
  clearWaterFietsResponse,
} from "reduxStates/waterFietsState/waterFietsActions";

// Import Material-UI Components
import { Box, Button, Typography, Grid, Paper } from "@mui/material";
import { makeStyles } from "@mui/styles";

// Import utils
import { iconToImg } from "utils/utils";

import { img } from "helpers/other";

const useStyles = makeStyles((theme) => ({
  flexCenter: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
  },
}));

const DashBoard = ({
  user: { loggedInUser },
  waterFiets: { waterFietsClients, waterFietsSuccess, waterFietsResult },
  getWaterFietsClients,
  getOneUseLoginJWT,
  clearWaterFietsResponse,
}) => {
  // Import style
  const classes = useStyles();

  // Create local state
  const [redirect, setRedirect] = React.useState(false);
  const [newTab, setNewTab] = React.useState(false);

  // Get waterfiets clients if 2FA is enabled
  React.useEffect(() => {
    if (loggedInUser.TwoFactorAuthEnabled) {
      getWaterFietsClients();
    }
    // Prevent any useless errors with net line:
    // eslint-disable-next-line
  }, []);

  React.useEffect(() => {
    if (waterFietsSuccess !== null) {
      if (waterFietsResult.origin === "getOneUseLoginJWT") {
        if (waterFietsSuccess === true) {
          setRedirect(waterFietsResult.loginURL);
          clearWaterFietsResponse();
        } else if (waterFietsSuccess === false) {
          clearWaterFietsResponse();
        }
      }
    }
    // Prevent any useless errors with net line:
    // eslint-disable-next-line
  }, [waterFietsResult]);

  const onGoToClient = (clientId, openInNewTab = false) => {
    setNewTab(openInNewTab);
    getOneUseLoginJWT({ clientId: clientId });
  };

  if (redirect) {
    if (newTab) {
      const newWindow = window.open(redirect, "_blank", "noopener,noreferrer");
      if (newWindow) newWindow.opener = null;
    } else {
      window.location.href = redirect;
    }
    setRedirect(false);
  }

  return (
    <Fragment>
      <Box sx={{ paddingTop: "32px" }}>
        <Typography align="center">You're logged in as:</Typography>

        <Typography align="center">
          {loggedInUser.firstName} {loggedInUser.lastName}
        </Typography>
        <Typography align="center">
          2FA enabled: {`${loggedInUser.TwoFactorAuthEnabled}`}
        </Typography>
      </Box>
      <Box sx={{ paddingTop: "32px" }}>
        <Typography variant="h4" align="center">
          WaterFiets clients:
        </Typography>

        <Box sx={{ paddingTop: "20px" }} className={classes.flexCenter}>
          {loggedInUser.TwoFactorAuthEnabled ? (
            waterFietsClients && waterFietsClients.length > 0 ? (
              <Grid container spacing={2} justifyContent="center">
                {waterFietsClients.map((client) => (
                  <Grid item xs={4} key={client._id}>
                    <Paper>
                      <Box padding="10px">
                        {client?.websiteIcon?.data && (
                          <Box
                            sx={{
                              fontSize: "0px",
                              paddingBottom: "10px",
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <img
                              src={iconToImg(client.websiteIcon)}
                              style={{ maxHeight: "120px" }}
                              alt="Client icon"
                            />
                          </Box>
                        )}
                        <Box>
                          <Typography variant="h5" align="center">
                            {client.websiteName}
                          </Typography>
                        </Box>

                        <Box display="flex" justifyContent="space-between">
                          <Box padding="10px">
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={() => {
                                onGoToClient(client._id);
                              }}
                            >
                              Go to client
                            </Button>
                          </Box>
                          <Box padding="10px">
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={() => {
                                onGoToClient(client._id, true);
                              }}
                            >
                              Go to client in new tab
                            </Button>
                          </Box>
                        </Box>
                      </Box>
                    </Paper>
                  </Grid>
                ))}
              </Grid>
            ) : (
              <Typography>
                This user does not have access to any clients
              </Typography>
            )
          ) : (
            <Typography>2FA needs to be enable for this feature</Typography>
          )}
        </Box>
      </Box>
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
  waterFiets: state.waterFiets,
});

export default connect(mapStateToProps, {
  getWaterFietsClients,
  getOneUseLoginJWT,
  clearWaterFietsResponse,
})(DashBoard);

// Import the React modules
import React, { Fragment, forwardRef } from "react";
import PropTypes from "prop-types";
import { Link as RouterLink, useLocation } from "react-router-dom";

// Import the Redux modules
import { connect } from "react-redux";
import { getLang } from "../../reduxStates/langState/langActions";
import { logout } from "../../reduxStates/authState/authActions";

// Import material-ui components
import {
  AppBar,
  Toolbar,
  Link,
  Button,
  ClickAwayListener,
  Paper,
  Popper,
  Divider,
  IconButton,
  Box,
  SwipeableDrawer,
  Fade,
} from "@mui/material";
import { makeStyles } from "@mui/styles";

import Flag from "react-world-flags";

// Import material-ui icons
import MenuIcon from "@mui/icons-material/Menu";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";

// Import page components
import SideNav from "./SideNav";

// Import images
import Logo_1 from "../../images/Design/Logo_1.svg";
import Wave from "../../images/Design/Wave.webp";

import { checkRoles } from "helpers/other";

// Sidenav width
const drawerWidth = 310;

// Create the styles
const useStyles = makeStyles((theme) => ({
  height: {
    [theme.breakpoints.down("sm")]: {
      height: "61.48px",
    },
    [theme.breakpoints.up("sm")]: {
      height: "112.175px",
    },
  },
  root: {
    display: "flex",
    flexGrow: 1,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: "#F2F2F2",
  },
  endLeft: {
    flexGrow: 1,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  fullSizeLogo: {
    flexGrow: 1,
    fontSize: "0px",
    marginLeft: theme.spacing(8.875),
  },
  mobileSizeLogo: {
    flexGrow: 1,
    fontSize: "0px",
    marginLeft: theme.spacing(2.246),
  },
  fullSizeNavigation: {
    marginRight: theme.spacing(8.875),
    display: "flex",
  },
  mobileSizeNavigation: {
    marginRight: "8px",
    display: "flex",
  },
  button: {
    textTransform: "none",
    [theme.breakpoints.down("md")]: {
      marginLeft: `${theme.spacing(0.5)}`,
    },
    [theme.breakpoints.up("md")]: {
      marginLeft: `${theme.spacing(4.69)}`,
    },
    height: "27.31px",
    paddingLeft: `${theme.spacing(2.81)}`,
    paddingRight: `${theme.spacing(2.81)}`,
    border: "2px",
    borderRadius: "0px",
    borderStyle: "solid",
    borderColor: "#F2F2F2",
    "&:hover": {
      backgroundColor: "#1D6E8F",
      color: "#F2F2F2",
    },
  },
  dropDownButton: {
    textTransform: "none",
    height: "27.31px",
    paddingLeft: `${theme.spacing(2.81)}`,
    paddingRight: `${theme.spacing(2.81)}`,
    border: "2px",
    borderRadius: "0px",
    borderStyle: "solid",
    borderColor: "#1D6E8F",
    width: "100%",
  },
  buttonActive: {
    borderStyle: "solid",
    borderColor: "#9CD5D4",
  },
  fullSizeHeader: {
    marginTop: `${theme.spacing(2.224)}`,
    marginBottom: `${theme.spacing(2.301)}`,
    display: "flex",
    alignItems: "center",
    width: "100%",
  },
  mobileSizeHeader: {
    marginTop: `${theme.spacing(1.25)}`,
    marginBottom: `${theme.spacing(1)}`,
    display: "flex",
    alignItems: "center",
    width: "100%",
  },
  fullSizeWave: {
    width: "100%",
    height: "24px",
    position: "relative",
    top: "-25px",
  },
  mobileSizeWave: {
    width: "100%",
    height: "13px",
    position: "relative",
    top: "-15px",
  },
  fullSizeWaveImg: {
    backgroundImage: `url(${Wave})`,
    height: "24px",
    width: "100%",
    backgroundRepeat: "repeat-x",
    position: "relative",
    top: "24px",
  },
  mobileSizeWaveImg: {
    backgroundImage: `url(${Wave})`,
    height: "13px",
    width: "100%",
    backgroundRepeat: "repeat-x",
    backgroundSize: "auto 13px",
    position: "relative",
    top: "13px",
  },
  popper: {
    zIndex: 1,
    "& .MuiPaper-root": {
      backgroundColor: "#1D6E8F",
      borderRadius: "0px",
      color: "#F2F2F2",
    },
  },
  menuButton: {
    width: "43.5px",
    height: "43.5px",
  },
  flag: {
    // paddingLeft: "10px",
  },
}));

const NavButton = forwardRef((props, ref) => {
  // Import the styles
  const classes = useStyles();

  const newProps = { ...props };
  delete newProps.active;

  return (
    <Fragment>
      <Button
        className={`${props.active && classes.buttonActive} ${classes.button}`}
        {...newProps}
        ref={ref}
        color="inherit"
      ></Button>
    </Fragment>
  );
});

const NavBar = ({
  auth: { isAuthenticated },
  user: { loggedInUser },
  lang: { language },
  getLang,
  logout,
}) => {
  let location = useLocation();

  // Import the styles
  const classes = useStyles();

  // Create the local states
  const [userSideNavOpen, setUserSideNavOpen] = React.useState(false);

  const [servicesDropdownOpen, setServicesDropdownOpen] = React.useState(false);
  const servicesDropdownRef = React.useRef(null);

  const [languageDropdownOpen, setLanguageDropdownOpen] = React.useState(false);
  const languageDropdownRef = React.useRef(null);

  const [studiesDropdownOpen, setStudiesDropdownOpen] = React.useState(false);
  const studiesDropdownRef = React.useRef(null);

  const [userDropdownOpen, setUserDropdownOpen] = React.useState(false);
  const userDropdownRef = React.useRef(null);

  const closeServicesDropdown = () => {
    setServicesDropdownOpen(false);
    setStudiesDropdownOpen(false);
  };

  const openServicesDropdown = () => {
    setServicesDropdownOpen(true);
  };

  const closeStudiesDropdown = () => {
    setStudiesDropdownOpen(false);
  };

  const openStudiesDropdown = () => {
    setStudiesDropdownOpen(true);
  };

  const closeLanguageDropdown = () => {
    setLanguageDropdownOpen(false);
  };

  const openLanguageDropdown = () => {
    setLanguageDropdownOpen(true);
  };

  const closeUserDropdown = () => {
    setUserDropdownOpen(false);
  };

  const openUserDropdown = () => {
    setUserDropdownOpen(true);
  };

  const toggleUserSideNav = () => {
    setUserSideNavOpen(!userSideNavOpen);
  };

  // Change the language on click
  const onLanguageClick = (id) => {
    if (language._id !== id) {
      getLang(id);
    }
  };

  // Guest links
  const guestLinks = (
    <Fragment>
      <NavButton
        active={location.pathname === "/login"}
        component={RouterLink}
        to="/login"
      >
        {language.navbar.login}
      </NavButton>
    </Fragment>
  );

  // Authorized user links
  const authLinks = (
    <Fragment>
      <Box onMouseLeave={closeUserDropdown}>
        <NavButton
          active={location.pathname.startsWith("/dashboard")}
          onClick={openUserDropdown}
          onMouseEnter={openUserDropdown}
          ref={userDropdownRef}
        >
          {[
            loggedInUser?.firstName,
            loggedInUser?.middleName,
            loggedInUser?.lastName,
          ]
            .filter(Boolean)
            .join(" ") || "loading"}
        </NavButton>
        {/* User dropdown content */}
        <Popper
          open={userDropdownOpen}
          transition={true}
          disablePortal={true}
          anchorEl={userDropdownRef.current}
          className={classes.popper}
          modifiers={[
            {
              name: "flip",
              enabled: false,
            },
            {
              name: "offset",
              enabled: true,
              options: {
                offset: [1, -27.3],
              },
            },
          ]}
        >
          {({ TransitionProps }) => (
            <Fade {...TransitionProps}>
              <Paper>
                <ClickAwayListener onClickAway={closeUserDropdown}>
                  <Box
                    display="flex"
                    flexWrap="wrap"
                    maxWidth={
                      userDropdownRef.current?.clientWidth + 8 > 150
                        ? userDropdownRef.current?.clientWidth + 8
                        : 150
                    }
                  >
                    <Button
                      className={classes.dropDownButton}
                      color="inherit"
                      onClick={openUserDropdown}
                    >
                      {!loggedInUser
                        ? "loading"
                        : [
                            loggedInUser.firstName,
                            loggedInUser.middleName,
                            loggedInUser.lastName,
                          ]
                            .filter(Boolean)
                            .join(" ")}
                    </Button>

                    <Divider
                      variant="middle"
                      style={{
                        width: "77.68%",
                        backgroundColor: "#F2F2F2",
                      }}
                    />
                    {checkRoles(
                      ["admin", "owner", "teacher"],
                      loggedInUser.roles
                    ) && (
                      <Fragment>
                        <Button
                          className={classes.dropDownButton}
                          color="inherit"
                          component={RouterLink}
                          to="/dashboard"
                          onClick={closeUserDropdown}
                        >
                          Dashboard
                        </Button>

                        <Divider
                          variant="middle"
                          style={{
                            width: "77.68%",
                            backgroundColor: "#F2F2F2",
                          }}
                        />
                      </Fragment>
                    )}

                    {checkRoles(["admin", "owner"], loggedInUser.roles) && (
                      <Fragment>
                        <Button
                          className={classes.dropDownButton}
                          color="inherit"
                          component={RouterLink}
                          to="/adminpanel/users"
                          onClick={closeUserDropdown}
                        >
                          Admin panel
                        </Button>

                        <Divider
                          variant="middle"
                          style={{
                            width: "77.68%",
                            backgroundColor: "#F2F2F2",
                          }}
                        />
                      </Fragment>
                    )}
                    <Button
                      className={classes.dropDownButton}
                      color="inherit"
                      component={RouterLink}
                      to="/profile"
                      onClick={closeUserDropdown}
                    >
                      Profile
                    </Button>

                    <Divider
                      variant="middle"
                      style={{
                        width: "77.68%",
                        backgroundColor: "#F2F2F2",
                      }}
                    />

                    <Button
                      className={classes.dropDownButton}
                      color="inherit"
                      onClick={() => {
                        closeUserDropdown();
                        logout();
                      }}
                    >
                      Logout
                    </Button>
                  </Box>
                </ClickAwayListener>
              </Paper>
            </Fade>
          )}
        </Popper>
      </Box>
    </Fragment>
  );

  const fullSize = (
    <Fragment>
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          width: "100%",
          maxHeight: "112.175px",
        }}
      >
        <Box className={classes.fullSizeHeader}>
          {/* Logo */}

          <Box className={classes.fullSizeLogo}>
            <Link
              component={RouterLink}
              to="/"
              color="inherit"
              underline="none"
            >
              <img
                src={Logo_1}
                style={{ maxHeight: "75.99px" }}
                alt="SwimTechNL Logo"
              />
            </Link>
          </Box>

          {/* Navigation */}
          <Box className={classes.fullSizeNavigation}>
            <Box onMouseLeave={closeServicesDropdown}>
              <NavButton
                active={
                  location.pathname === "/inhuur" ||
                  location.pathname.startsWith("/opleiding") ||
                  location.pathname === "/swimschool"
                }
                onClick={openServicesDropdown}
                onMouseEnter={openServicesDropdown}
                ref={servicesDropdownRef}
              >
                {language.navbar.services}
              </NavButton>
              {/* Services dropdown content */}
              <Popper
                open={servicesDropdownOpen}
                transition={true}
                disablePortal={true}
                anchorEl={servicesDropdownRef.current}
                className={classes.popper}
                modifiers={[
                  {
                    name: "flip",
                    enabled: false,
                  },
                  {
                    name: "offset",
                    enabled: true,
                    options: {
                      offset: [1, -27.3],
                    },
                  },
                ]}
              >
                {({ TransitionProps }) => (
                  <Fade {...TransitionProps}>
                    <Paper>
                      <ClickAwayListener onClickAway={closeServicesDropdown}>
                        <Box
                          sx={{
                            display: "flex",
                            flexWrap: "wrap",
                            maxWidth: "121px",
                          }}
                        >
                          <Button
                            className={classes.dropDownButton}
                            color="inherit"
                          >
                            {language.navbar.services}
                          </Button>
                          <Divider
                            variant="middle"
                            style={{
                              width: "77.68%",
                              backgroundColor: "#F2F2F2",
                            }}
                          />
                          <Box
                            onMouseLeave={closeStudiesDropdown}
                            sx={{ width: "100%" }}
                          >
                            <Button
                              className={classes.dropDownButton}
                              onClick={openStudiesDropdown}
                              onMouseEnter={openStudiesDropdown}
                              ref={studiesDropdownRef}
                              color="inherit"
                            >
                              {language.navbar.servicesDropDownContent.study}
                            </Button>
                            <Popper
                              open={studiesDropdownOpen}
                              transition={true}
                              disablePortal={true}
                              anchorEl={studiesDropdownRef.current}
                              className={classes.popper}
                              modifiers={[
                                {
                                  name: "flip",
                                  enabled: false,
                                },
                                {
                                  name: "offset",
                                  enabled: true,
                                  options: {
                                    offset: [0, -9.5],
                                  },
                                },
                              ]}
                              placement="left-start"
                            >
                              {({ TransitionProps }) => (
                                <Fade {...TransitionProps}>
                                  <Box sx={{ display: "flex" }}>
                                    <Paper
                                      style={{
                                        position: "relative",
                                        left: "11px",
                                      }}
                                    >
                                      <Box
                                        sx={{
                                          display: "flex",
                                          flexWrap: "wrap",
                                        }}
                                      >
                                        <Button
                                          className={classes.dropDownButton}
                                          component={RouterLink}
                                          to="/opleiding/zweminstructeurabc"
                                          onClick={closeServicesDropdown}
                                          color="inherit"
                                          style={{ whiteSpace: "nowrap" }}
                                        >
                                          {
                                            language.navbar
                                              .servicesDropDownContent
                                              .studyContent.link1
                                          }
                                        </Button>
                                        <Divider
                                          variant="middle"
                                          style={{
                                            width: "77.68%",
                                            backgroundColor: "#F2F2F2",
                                          }}
                                        />
                                        <Button
                                          className={classes.dropDownButton}
                                          component={RouterLink}
                                          to="/opleiding/algehelelifeguardopleiding"
                                          onClick={closeServicesDropdown}
                                          color="inherit"
                                          style={{ whiteSpace: "nowrap" }}
                                        >
                                          {
                                            language.navbar
                                              .servicesDropDownContent
                                              .studyContent.link2
                                          }
                                        </Button>
                                        <Divider
                                          variant="middle"
                                          style={{
                                            width: "77.68%",
                                            backgroundColor: "#F2F2F2",
                                          }}
                                        />
                                        <Button
                                          className={classes.dropDownButton}
                                          component={RouterLink}
                                          to="/opleiding/zrzopleiding"
                                          onClick={closeServicesDropdown}
                                          color="inherit"
                                          style={{ whiteSpace: "nowrap" }}
                                        >
                                          {
                                            language.navbar
                                              .servicesDropDownContent
                                              .studyContent.link3
                                          }
                                        </Button>
                                      </Box>
                                    </Paper>
                                    <ArrowRightIcon
                                      style={{
                                        color: "#1D6E8F",
                                        paddingTop: "4.155px",
                                      }}
                                    />
                                  </Box>
                                </Fade>
                              )}
                            </Popper>
                          </Box>
                          <Divider
                            variant="middle"
                            style={{
                              width: "77.68%",
                              backgroundColor: "#F2F2F2",
                            }}
                          />
                          <Button
                            className={classes.dropDownButton}
                            component={RouterLink}
                            to="/swimschool"
                            onClick={closeServicesDropdown}
                            color="inherit"
                            disabled
                          >
                            {language.navbar.servicesDropDownContent.swimSchool}
                          </Button>
                          <Divider
                            variant="middle"
                            style={{
                              width: "77.68%",
                              backgroundColor: "#F2F2F2",
                            }}
                          />
                          <Button
                            className={classes.dropDownButton}
                            component={RouterLink}
                            to="/inhuur"
                            onClick={closeServicesDropdown}
                            color="inherit"
                          >
                            {language.navbar.servicesDropDownContent.hire}
                          </Button>
                        </Box>
                      </ClickAwayListener>
                    </Paper>
                  </Fade>
                )}
              </Popper>
            </Box>

            <NavButton
              active={location.pathname === "/contact"}
              component={RouterLink}
              to="/contact"
            >
              {language.navbar.contact}
            </NavButton>
            <NavButton
              active={location.pathname === "/about"}
              component={RouterLink}
              to="/about"
            >
              Over ons
            </NavButton>
            {isAuthenticated ? authLinks : guestLinks}
            {language.navbar.languageDropDownContent.length !== 1 && (
              <Box onMouseLeave={closeLanguageDropdown}>
                <NavButton
                  onClick={openLanguageDropdown}
                  onMouseEnter={openLanguageDropdown}
                  ref={languageDropdownRef}
                >
                  <Flag
                    code={language.countryCode}
                    height="27.3"
                    className={classes.flag}
                  />
                </NavButton>
                <Popper
                  open={languageDropdownOpen}
                  transition={true}
                  disablePortal={true}
                  anchorEl={languageDropdownRef.current}
                  className={classes.popper}
                  modifiers={{
                    flip: { enabled: false },
                    offset: { enabled: true, offset: "1px, -27.3px" },
                  }}
                >
                  {({ TransitionProps }) => (
                    <Fade {...TransitionProps}>
                      <Paper>
                        <ClickAwayListener onClickAway={closeLanguageDropdown}>
                          <Box
                            sx={{
                              display: "flex",
                              flexWrap: "wrap",
                              maxWidth: "121px",
                            }}
                          >
                            <Button
                              className={classes.dropDownButton}
                              color="inherit"
                              onClick={openLanguageDropdown}
                            >
                              {language.navbar.language}
                            </Button>
                            {language.navbar.languageDropDownContent.map(
                              (dropDownLanguage) => (
                                <Fragment key={dropDownLanguage._id}>
                                  <Divider
                                    variant="middle"
                                    style={{
                                      width: "77.68%",
                                      backgroundColor: "#F2F2F2",
                                    }}
                                  />
                                  <Button
                                    className={classes.dropDownButton}
                                    color="inherit"
                                    onClick={() => {
                                      closeLanguageDropdown();
                                      onLanguageClick(dropDownLanguage._id);
                                    }}
                                  >
                                    <Flag
                                      code={dropDownLanguage.countryCode}
                                      height="27.3"
                                      className={classes.flag}
                                    />
                                  </Button>
                                </Fragment>
                              )
                            )}
                          </Box>
                        </ClickAwayListener>
                      </Paper>
                    </Fade>
                  )}
                </Popper>
              </Box>
            )}
          </Box>
        </Box>
        <Box className={classes.fullSizeWave}>
          <div className={classes.fullSizeWaveImg} />
        </Box>
      </Box>
    </Fragment>
  );

  // Mobile size navbar (menu buttons)
  const mobileSize = (
    <Fragment>
      <Box display="flex" flexWrap="wrap" width="100%" maxHeight="61.48px">
        <Box className={classes.mobileSizeHeader}>
          {/* Logo */}
          <Box className={classes.mobileSizeLogo}>
            <Link
              component={RouterLink}
              to="/"
              color="inherit"
              underline="none"
            >
              <img
                src={Logo_1}
                style={{ maxHeight: "43.5px" }}
                alt="SwimTechNL Logo"
              />
            </Link>
          </Box>
          <Box className={classes.mobileSizeNavigation}>
            <IconButton
              onClick={toggleUserSideNav}
              className={classes.menuButton}
            >
              <MenuIcon />
            </IconButton>
          </Box>
        </Box>
        <Box className={classes.mobileSizeWave}>
          <div className={classes.mobileSizeWaveImg} />
        </Box>
      </Box>
    </Fragment>
  );

  // Return the component
  return (
    <Fragment>
      <Box className={classes.height}>
        <Box className={classes.root}>
          {/* Navbar */}
          <AppBar className={classes.appBar}>
            <Toolbar disableGutters>
              {/* Depending on the screensize show fullsize or mobilesize */}
              <Box sx={{ display: { xs: "none", sm: "block" }, width: "100%" }}>
                {fullSize}
              </Box>
              <Box sx={{ display: { xs: "block", sm: "none" }, width: "100%" }}>
                {mobileSize}
              </Box>
            </Toolbar>
          </AppBar>

          {/* User sidenav drawer */}
          <SwipeableDrawer
            anchor={"right"}
            open={userSideNavOpen}
            onOpen={toggleUserSideNav}
            onClose={toggleUserSideNav}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true,
            }}
            sx={{ zIndex: "1300" }}
          >
            {/* Sidenav.js */}
            <SideNav setUserSideNavOpen={setUserSideNavOpen} />
          </SwipeableDrawer>
        </Box>
      </Box>
    </Fragment>
  );
};

// Set the state for this component to the global state
const mapStateToProps = (state) => ({
  lang: state.lang,
  user: state.user,
  auth: state.auth,
});

NavButton.propTypes = {
  active: PropTypes.bool,
};

// Connect this component to the redux global state and bring in the state and needed functions
export default connect(mapStateToProps, {
  getLang,
  logout,
})(NavBar);

// Import the React modules
import React from "react";
import { useCookies } from "react-cookie";

// Import material-ui components
import {
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
} from "@mui/material";

// Import Luxon components
import { DateTime } from "luxon";

import PrivacyStatement from "../PrivacyStatement/PrivacyStatement";

const CookieDialog = () => {
  // Create the local states
  const [cookies, setCookie] = useCookies(["consent"]);

  const [privacyStatementOpen, setPrivacyStatementOpen] = React.useState(false);

  const [allowFunctional, setAllowFunctional] = React.useState(
    cookies.consent ? cookies.consent.allowFunctional : true
  );

  // const [allowAnalytic, setAllowAnalytic] = React.useState(
  //   cookies.consent ? cookies.consent.allowAnalytic : false
  // );

  const allowAnalytic = false;

  const openPrivacyStatementDialog = () => {
    setPrivacyStatementOpen(true);
  };

  const closePrivacyStatementDialog = () => {
    setPrivacyStatementOpen(false);
  };

  const onAccept = () => {
    const values = JSON.stringify({
      allowFunctional: allowFunctional,
      allowAnalytic: allowAnalytic,
    });

    setCookie("consent", values, {
      path: "/",
      expires: DateTime.now().plus({ years: 1 }).toJSDate(),
      sameSite: true,
    });
  };
  return (
    // Dialog
    <Dialog
      open={true}
      maxWidth="md"
      disableBackdropClick
      disableEscapeKeyDown
      fullWidth
    >
      {/* Title */}
      <DialogTitle>Cookies on SwimTechNL</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <DialogContentText>
              To use SwimTechNL functional cookies are required
            </DialogContentText>
          </Grid>
          <Grid item xs={12}>
            <DialogContentText>
              For more information check our{" "}
              <a href="# " onClick={openPrivacyStatementDialog}>
                privacy statement
              </a>
            </DialogContentText>
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={allowFunctional}
                  disabled={
                    cookies.consent
                      ? cookies.consent.allowFunctional !== false
                      : true
                  }
                  color="primary"
                />
              }
              onChange={(e) => {
                setAllowFunctional(e.target.checked);
              }}
              label="Allow functional cookies"
            />
          </Grid>
          {/* <Grid item xs={12}>
            <FormControlLabel
              control={<Checkbox checked={allowAnalytic} color="primary" />}
              onChange={(e) => {
                setAllowAnalytic(e.target.checked);
              }}
              label="Allow analytic cookies"
            />
          </Grid> */}
        </Grid>
      </DialogContent>
      <Divider />
      {/* Bottom buttons */}
      <DialogActions>
        {/* Accept */}
        <Button
          variant="contained"
          onClick={onAccept}
          color="primary"
          disabled={!allowFunctional}
        >
          Accept
        </Button>
      </DialogActions>
      <Dialog
        fullScreen
        open={privacyStatementOpen}
        onClose={closePrivacyStatementDialog}
      >
        <PrivacyStatement
          inDialog={true}
          onClose={closePrivacyStatementDialog}
        />
      </Dialog>
    </Dialog>
  );
};

export default CookieDialog;

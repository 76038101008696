// Import the React modules
import React, { Fragment } from "react";
import { Route, Routes } from "react-router-dom";

// Import website components
import PDNE from "components/PageDoesNotExist/PageDoesNotExist";
import PrivacyStatement from "components/PrivacyStatement/PrivacyStatement";
import PrivateRoute from "components/Routing/PrivateRoute";

import Home from "./Home/Home";
import Contact from "./Contact/Contact";
import Hire from "./Hire/Hire";
import StudiesLayout from "./Studies/StudiesLayout";
import About from "./About/About";

import LoginLayout from "./Login/LoginLayout";
import RegisterLayout from "./Register/RegisterLayout";
import ProfileLayout from "./Profile/ProfileLayout";
import DashBoardLayout from "./DashBoard/DashBoardLayout";
import AdminPanelLayout from "./AdminPanel/AdminPanelLayout";

const MainLayout = () => {
  return (
    <Fragment>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/inhuur" element={<Hire />} />
        <Route path="/about" element={<About />} />
        <Route path="/privacystatement" element={<PrivacyStatement />} />
        <Route path="/opleiding/*" element={<StudiesLayout />} />
        <Route path="/login/*" element={<LoginLayout />} />
        <Route path="/register/*" element={<RegisterLayout />} />
        <Route
          path="/profile/*"
          element={
            <PrivateRoute roles={["any"]}>
              <ProfileLayout />
            </PrivateRoute>
          }
        />
        <Route
          path="/adminPanel/*"
          element={
            <PrivateRoute roles={["admin", "owner"]}>
              <AdminPanelLayout />
            </PrivateRoute>
          }
        />
        <Route
          path="/dashboard/*"
          element={
            <PrivateRoute roles={["admin", "owner", "teacher"]}>
              <DashBoardLayout />
            </PrivateRoute>
          }
        />
        <Route path="*" element={<PDNE />} />
      </Routes>
    </Fragment>
  );
};

export default MainLayout;

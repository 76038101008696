export const img = (image) => {
  if (!image) {
    return null;
  }
  var binary = "";
  var bytes = [].slice.call(new Uint8Array(image.data.data));
  bytes.forEach((b) => (binary += String.fromCharCode(b)));

  let base64Flag = `data:${image.contentType};base64,`;
  let imgStr = window.btoa(binary);

  return base64Flag + imgStr;
};

export const nameFormat = (obj, type) => {
  if (obj) {
    switch (type) {
      case "fullName":
        return [obj.firstName, obj.middleName, obj.lastName]
          .filter(Boolean)
          .join(" ");
      case "shortName":
        return `${obj.firstName[0]} ${obj.lastName}`;
      case "rev_midName":
        return `${obj.lastName}, ${obj.firstName}`;
      default:
        return [obj.firstName, obj.middleName, obj.lastName]
          .filter(Boolean)
          .join(" ");
    }
  } else {
    return "Not found";
  }
};

// Check if user has required roles
// Returns true if user has one of the roles in authRoles
export const checkRoles = (authRoles, userRoles) => {
  return authRoles.some((r) => userRoles.includes(r));
};

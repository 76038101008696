// Import the React modules
import React, { Fragment } from "react";
import { Link as RouterLink } from "react-router-dom";

// Import the Redux modules
import { connect } from "react-redux";

// Import material-ui/core components
import { Box, Typography, Grid, Container, Button } from "@mui/material";
import { makeStyles } from "@mui/styles";

// Import images
import Wave from "images/Design/Hire/Wave.svg";

import DolfijnLogo from "./img/icon_dolfijn.webp";
import KonazLogo from "./img/icon_konaz.webp";

import OptisportLogo from "./img/icon_optisport.svg";
import SROLogo from "./img/icon_SRO.webp";
import COALogo from "./img/icon_COA.webp";
import NRZLogo from "./img/icon_NRZ.webp";

import { TitleBox } from "components/Layout/PageLayout/PageLayout";

const globalStyles = makeStyles(() => ({
  imageFix: {
    fontSize: 0,
  },
}));

const mainStyles = makeStyles(() => ({
  container: {
    marginTop: "50px",
    marginLeft: "4.86%",
    marginRight: "4.86%",
    paddingBottom: "60px",
  },
  image: {
    zIndex: 1,
  },
  imageContainer: {
    marginRight: "10.6%",
    position: "relative",
    marginTop: "6.4%",
    maxWidth: "275px",
  },
  imageBorder: {
    width: "97.97%",
    height: "98.00%",
    position: "absolute",
    border: "9px solid rgba(241,90,36, 0.61)",
    top: "-38px",
    left: "65px",
    zIndex: -1,
  },
  button: {
    textTransform: "none",
    height: "64px",
    borderRadius: "0px",
    backgroundColor: "#29ABE2",
    opacity: "0.61",
    color: "#F2F2F2",
    "&:hover": {
      backgroundColor: "#1D6E8F",
      opacity: "1",
    },
    fontSize: "30px",
    "&.Mui-disabled": {
      opacity: "0.3",
      color: "#F2F2F2",
      backgroundColor: "rgba(0, 0, 0, 0.26)",
    },
  },
  wave: {
    width: "100%",
    height: "24px",
  },
  waveImg: {
    background: `url(${Wave})`,
    height: "24px",
    width: "100%",
    backgroundRepeat: "repeat-x",
  },
  greyBox: {
    background: "rgba(204, 204, 204, 0.47)",
    borderRadius: "30px",
  },
  innerGreyBox: {
    paddingTop: "27px",
    paddingBottom: "27px",
    paddingLeft: "11.41%",
    paddingRight: "11.41%",
  },
}));

const Hire = ({ lang: { language } }) => {
  const mainStyle = mainStyles();
  const globalStyle = globalStyles();

  // return the page
  return (
    <Fragment>
      <TitleBox rootColor="rgba(241,90,36, 0.61)">
        {language.hire.pageHeader}
      </TitleBox>
      <Box className={mainStyle.container}>
        <Container disableGutters>
          <Grid container spacing={6}>
            <Grid item xs={12} sm={6}>
              <Grid container spacing={6}>
                <Grid item xs={12}>
                  <Typography variant="h4" align="center">
                    Administratie
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography align="center">
                    Kunt u als vereniging of zwemschool ondersteuning gebruiken
                    bij het managen van de zwemactiviteiten? Misschien kan
                    Swimtechnl iets voor u betekenen. Dit kan op verschillende
                    manieren. Bent u geïnteresseerd en wilt u meer informatie
                    over wat er allemaal mogelijk is, neem dan contact met ons
                    op. Wij plannen graag een gesprek met u in.
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  <Box className={mainStyle.greyBox}>
                    <Box className={mainStyle.innerGreyBox} align="left">
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          marginBlockEnd: "20px",
                        }}
                      >
                        <Typography style={{ fontWeight: "bold" }}>
                          Koninklijke Amsterdamsche Zwemclub 1870
                        </Typography>
                        <img
                          src={KonazLogo}
                          style={{ width: "60px" }}
                          alt="Konaz Logo"
                        />
                      </Box>

                      <Typography paragraph align="justify">
                        Koninklijke Amsterdamsche Zwemclub 1870 is al 150 (+)
                        jaar een gerenommeerde en algemeen bekende naam in de
                        zwemwereld. Wij zijn trots om te mogen vermelden dat
                        Swimtechnl sinds 01-01-2020 Konaz1870 ondersteunt in het
                        managen van hun zwem-ABC tak. De gedeelde gedachtegang
                        is dat geleidelijk alle werkzaamheden omtrent het ABC
                        zwemmen zullen worden uitgevoerd door Swimtechnl. Het
                        einddoel is dan ook dat Konaz1870 uiteindelijk geen kind
                        meer aan het ABC zwemmen heeft, maar wel veel tevreden
                        leden en zich volledig kan richten op het verwezenlijken
                        van nog meer succesverhalen binnen het wedstrijdzwemmen
                        en doelgroepen zwemmen (voor vrouwen, 55-plussers en
                        volwassenen zonder diploma).
                      </Typography>
                      <Typography
                        align="center"
                        paragraph
                        style={{ fontStyle: "italic" }}
                      >
                        “Dankzij Swimtechnl hoeven wij ons als bestuur geen
                        zorgen meer te maken over het diplomazwemmen. Zowel
                        organisatorisch als zwemtechnisch loopt het op
                        rolletjes. Hierdoor houden wij meer tijd over voor
                        bestuurstaken zoals het ontwikkelen van een
                        langetermijnvisie of zoals recent, het vorm proberen te
                        geven aan zwemactiviteiten binnen de beperkende
                        coronamaatregelen en het ontwikkelen van verschillende
                        scenario's.”
                      </Typography>
                      <Typography align="right">
                        - Marielle Beers, Secretaris Konaz 1870
                      </Typography>
                    </Box>
                  </Box>
                </Grid>

                <Grid item xs={12}>
                  <Box className={mainStyle.greyBox}>
                    <Box className={mainStyle.innerGreyBox} align="left">
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          marginBlockEnd: "20px",
                        }}
                      >
                        <Typography style={{ fontWeight: "bold" }}>
                          Zwemvereniging De Dolfijn
                        </Typography>
                        <img
                          src={DolfijnLogo}
                          style={{ width: "60px" }}
                          alt="Dolfijn Logo"
                        />
                      </Box>
                      <Typography
                        align="center"
                        paragraph
                        style={{ fontStyle: "italic" }}
                      >
                        “Swimtechnl heeft de organisatie rondom onze zwemlessen
                        van Zwemvereniging De Dolfijn overgenomen en dat bevalt
                        goed! Met duidelijke (financiële en organisatorische)
                        afspraken zien we dat de in- en doorstroming van
                        kinderen soepel verloopt. Zowel onze vrijwilligers als
                        de ouders van leerlingen hebben een duidelijk
                        aanspreekpunt. Van het afzwemmen maken we zoveel
                        mogelijk een feestje, want natuurlijk willen we graag
                        dat kinderen ook ná het diplomazwemmen bij De Dolfijn
                        blijven.”
                      </Typography>
                      <Typography align="right">
                        - Gabrielle Philipsen, Verenigingsmanager van De Dolfijn
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={6} align="left">
              <Grid container spacing={6}>
                <Grid item xs={12}>
                  <Typography variant="h4" align="center">
                    Zwemonderwijs en toezichthouden
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography align="center" paragraph>
                    Kunt u als zwemschool of organisatie ondersteuning gebruiken
                    bij het vullen van de roosters omtrent zwemactiviteiten?
                    Neem dan contact met ons op. Misschien kan Swimtechnl iets
                    voor u betekenen. Op dit moment werken wij al samen met de
                    volgende partners:
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-evenly",
                      alignItems: "center",

                      marginBlockEnd: "20px",

                      a: {
                        width: "120px",
                        height: "120px",

                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      },

                      img: {
                        maxWidth: "120px",
                        maxHeight: "120px",
                      },
                    }}
                  >
                    <a href="https://sro.nl" target="_blank">
                      <img src={SROLogo} alt="SRO Logo" />
                    </a>

                    <a href="https://www.optisport.nl" target="_blank">
                      <img src={OptisportLogo} alt="Optisport Logo" />
                    </a>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-evenly",
                      alignItems: "center",

                      a: {
                        width: "120px",
                        height: "120px",

                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      },

                      img: {
                        maxWidth: "120px",
                        maxHeight: "120px",
                      },
                    }}
                  >
                    <a href="https://www.coa.nl/nl" target="_blank">
                      <img src={COALogo} alt="COA Logo" />
                    </a>
                    <a href="https://www.nrz-nl.nl" target="_blank">
                      <img src={NRZLogo} alt="NRZ Logo" />
                    </a>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={4} sm={6} md={8} style={{ paddingRight: "0px" }}>
              <Box
                className={mainStyle.wave}
                display="flex"
                alignItems="center"
                style={{ height: "100%" }}
              >
                <div className={mainStyle.waveImg} />
              </Box>
            </Grid>

            <Grid item xs={8} sm={6} md={4} style={{ paddingLeft: "9px" }}>
              <Button
                className={mainStyle.button}
                fullWidth
                component={RouterLink}
                to="/contact"
              >
                Contact
              </Button>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </Fragment>
  );
};

// Set the state for this component to the global state
const mapStateToProps = (state) => ({
  lang: state.lang,
});

// Connect this component to the redux global state and bring in the state and needed functions
export default connect(mapStateToProps, null)(Hire);

// Import the React modules
import React, { Fragment } from "react";
import { Route, Routes, Navigate } from "react-router-dom";

// Import the Redux modules
import { connect } from "react-redux";

// Import website components
import PDNE from "components/PageDoesNotExist/PageDoesNotExist";
import Login from "./Pages/Login/Login";
import Login2FA from "./Pages/Login2FA/Login2FA";
import Reset2FA from "./Pages/Reset2FA/Reset2FA";
import ResetPasswordInit from "./Pages/ResetPasswordInit/ResetPasswordInit";
import ResetPasswordNew from "./Pages/ResetPasswordNew/ResetPasswordNew";

const LoginLayout = ({ auth: { isAuthenticated } }) => {
  // If we are logged in we dont have to be here, so we redirect to dashboard
  if (isAuthenticated) {
    return <Navigate to="/dashboard" />;
  }

  return (
    <Fragment>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/2FA" element={<Login2FA />} />
        <Route path="/reset2FA" element={<Reset2FA />} />
        <Route path="/resetPassword" element={<ResetPasswordInit />} />
        <Route
          path="/resetPassword/:resetPasswordToken"
          element={<ResetPasswordNew />}
        />
        <Route
          path="/createpassword/:resetPasswordToken"
          element={<ResetPasswordNew />}
        />
        <Route path="*" element={<PDNE />} />
      </Routes>
    </Fragment>
  );
};

// Set the state for this component to the global state
const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, null)(LoginLayout);

// Import the React modules
import React from "react";
import { Navigate, useParams } from "react-router-dom";

// Import the Redux modules
import { connect } from "react-redux";

import {
  verifyEmail,
  clearUserResponse,
} from "reduxStates/userState/userActions";

// Import material-ui/core components
import { Box, CircularProgress } from "@mui/material";

const VerifyEmail = ({
  user: { userSuccess, userResult },
  verifyEmail,
  clearUserResponse,
}) => {
  let params = useParams();

  const [redirect, setRedirect] = React.useState(false);

  React.useEffect(() => {
    if (userSuccess !== null) {
      if (userResult.origin === "verifyEmail") {
        if (userSuccess === true) {
          clearUserResponse();
          setRedirect("/login");
        } else if (userSuccess === false) {
          clearUserResponse();
          setRedirect("/login");
        }
      }
    }
    // Prevent any useless errors with net line:
    // eslint-disable-next-line
  }, [userResult]);

  React.useEffect(() => {
    if (params.verifyEmailToken) {
      verifyEmail(params.verifyEmailToken);
    }
    // Prevent any useless errors with net line:
    // eslint-disable-next-line
  }, []);

  if (redirect) {
    return <Navigate to={redirect} />;
  }

  return (
    <Box paddingTop="100px" align="center">
      <CircularProgress />
    </Box>
  );
};

// Set the state for this component to the global state
const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps, { verifyEmail, clearUserResponse })(
  VerifyEmail
);

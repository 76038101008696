// Import the React modules
import React, { Fragment } from "react";
import { Link as RouterLink, Navigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import clsx from "clsx";

// Import the Redux modules
import { connect } from "react-redux";
import { login2FA, clearAuthResponse } from "reduxStates/authState/authActions";
import { setSnackbar } from "reduxStates/appState/appActions";

// Import material-ui/core components
import {
  Grid,
  Box,
  Link,
  TextField,
  Typography,
  Container,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { LoadingButton } from "@mui/lab";

// Import Material-UI Colors
import { red, green } from "@mui/material/colors";

// Import images
import Image from "images/Design/Login/Image.webp";

import { TitleBox } from "components/Layout/PageLayout/PageLayout";

const globalStyles = makeStyles(() => ({
  imageFix: {
    fontSize: 0,
  },
}));

const mainStyles = makeStyles(() => ({
  flexRight: {
    display: "flex",
    justifyContent: "flex-end",
  },
  container: {
    marginTop: "50px",
    marginLeft: "4.86%",
    marginRight: "4.86%",
    paddingBottom: "60px",
  },
  textFieldLabel: {
    fontSize: "17px",
    fontWeight: "600",
    lineHeight: "23px",
    color: "#000000",
    marginBottom: "3px",
  },
  textFieldSmall: {
    "& .MuiOutlinedInput-root": {
      height: "45px",
      borderRadius: "0px",
      "& input": {
        height: "45px",
        padding: "0 14px",
      },
      "& fieldset": {
        borderWidth: "2px",
        borderColor: "#9CD5D4",
      },
      "&.Mui-focused fieldset": {
        borderColor: "rgba(41,171,226, 0.61)",
      },
      "&:hover fieldset": {
        borderColor: "#29ABE2",
      },
    },
  },
  underButton: {
    fontSize: "14px",
    lineHeight: "19px",
    fontWeight: "400",
    fontStyle: "italic",
    textDecorationLine: "underline",
    color: "rgba(41,171,226,1)",
  },
  button: {
    textTransform: "none",
    height: "44px",
    borderRadius: "0px",
    backgroundColor: "#29ABE2",
    opacity: "0.61",
    color: "#F2F2F2",
    "&:hover": {
      backgroundColor: "#1D6E8F",
      opacity: "1",
    },
    fontSize: "17px",
    lineHeight: "23px",
    fontWeight: "400",
    "&.Mui-disabled": {
      opacity: "0.3",
      color: "#F2F2F2",
      backgroundColor: "rgba(0, 0, 0, 0.26)",
    },
  },
  buttonFail: {
    backgroundColor: red[500],
    opacity: "1",
    "&:hover": {
      backgroundColor: red[700],
    },
  },
  buttonSuccess: {
    backgroundColor: green[500],
    opacity: "1",
    "&:hover": {
      backgroundColor: green[700],
    },
  },
  image: {
    zIndex: 1,
  },
  imageContainer: {
    marginRight: "10.6%",
    position: "relative",
    marginTop: "6.5%",
    maxWidth: "417px",
  },
  imageBorder: {
    width: "97.96%",
    height: "96.91%",
    position: "absolute",
    border: "9px solid rgba(156, 213, 212, 0.61)",
    top: "-12.25%",
    left: "17.2%",
    zIndex: -1,
  },
}));

const Login2FA = ({
  auth: { authSuccess, authResult },
  login2FA,
  clearAuthResponse,
  setSnackbar,
}) => {
  const mainStyle = mainStyles();
  const globalStyle = globalStyles();

  // Create local state
  const [loading, setLoading] = React.useState(false);
  const [successFail, setSuccessFail] = React.useState(false);
  const [redirect, setRedirect] = React.useState(false);

  const [otpToken, setOtpToken] = React.useState("");
  const [otpTokenError, setOtpTokenError] = React.useState(false);

  // Get the current cookies
  const [cookies] = useCookies(["has2FA_JWT"]);

  const buttonClassname = clsx({
    [mainStyle.buttonFail]: successFail === "fail",
    [mainStyle.buttonSuccess]: successFail === "success",
  });

  React.useEffect(() => {
    if (authSuccess !== null) {
      if (authResult.origin === "login2FA") {
        if (authSuccess === true) {
          setLoading(false);
          setSuccessFail("success");
          clearAuthResponse();
        } else if (authSuccess === false) {
          setSuccessFail("fail");
          setLoading(false);

          if (authResult.error === "Invalid OTP code") {
            setOtpTokenError("Invalid 2FA code");
          }
          if (authResult.error === "2FA timed out") {
            setRedirect("/login");
          }

          clearAuthResponse();
        }
      }
    }
    // Prevent any useless errors with net line:
    // eslint-disable-next-line
  }, [authResult]);

  // Check if we can be here
  React.useEffect(() => {
    if (!cookies.has2FA_JWT || cookies.has2FA_JWT !== "true") {
      setRedirect("/login");
      setSnackbar("2FA timed out", "warning");
    }
    // Prevent any useless errors with net line:
    // eslint-disable-next-line
  }, [cookies]);

  const onLogin2FA = (e) => {
    e.preventDefault();
    setSuccessFail(false);
    setLoading(true);

    let error = false;
    if (otpToken === "") {
      setOtpTokenError("2FA code is required");
      error = true;
    } else if (otpToken.length !== 6) {
      setOtpTokenError("2FA code needs to be 6 digits");
      error = true;
    }

    if (!error) {
      login2FA({ otpToken: otpToken });
    } else {
      setSuccessFail("fail");
      setLoading(false);
    }
  };

  const onOtpTokenChange = (e) => {
    setOtpToken(e.target.value);
    if (e.target.value !== "" && e.target.value.length !== 6) {
      setOtpTokenError("2FA code needs to be 6 digits");
    } else {
      setOtpTokenError(false);
    }
    setSuccessFail(false);
  };

  if (redirect) {
    return <Navigate to={redirect} />;
  }

  return (
    <Fragment>
      <TitleBox rootColor="rgba(156, 213, 212, 0.61)">Login 2FA</TitleBox>
      <Box className={mainStyle.container}>
        <Container disableGutters>
          <Grid container spacing={6} direction="row-reverse">
            <Grid item xs={12} sm={6} align="left">
              <Box className={mainStyle.imageContainer}>
                <Box className={mainStyle.imageBorder} />
                <Box className={`${globalStyle.imageFix} ${mainStyle.image}`}>
                  <img src={Image} style={{ width: "100%" }} alt="water" />
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} align="left">
              <Box align="left" maxWidth="367px">
                <form noValidate onSubmit={onLogin2FA}>
                  <Box>
                    <Typography className={mainStyle.textFieldLabel}>
                      2FA code
                    </Typography>
                    <TextField
                      variant="outlined"
                      fullWidth
                      className={mainStyle.textFieldSmall}
                      type="number"
                      spellCheck={false}
                      value={otpToken}
                      onChange={onOtpTokenChange}
                      required
                      error={otpTokenError !== false}
                      helperText={otpTokenError}
                      disabled={loading}
                    />
                  </Box>
                  <Box
                    sx={{ paddingTop: "3px" }}
                    className={mainStyle.flexRight}
                  >
                    <Link
                      component={RouterLink}
                      className={mainStyle.underButton}
                      to="/login/reset2FA"
                      disabled={loading}
                    >
                      Reset 2FA
                    </Link>
                  </Box>
                  <Box paddingTop="25px">
                    <Grid container spacing={4}>
                      <Grid item xs={12}>
                        <LoadingButton
                          className={`${mainStyle.button} ${buttonClassname}`}
                          fullWidth
                          type="submit"
                          loading={loading}
                        >
                          Verify code
                        </LoadingButton>
                      </Grid>
                    </Grid>
                  </Box>
                </form>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </Fragment>
  );
};

// Set the state for this component to the global state
const mapStateToProps = (state) => ({
  auth: state.auth,
});

// Connect this component to the redux global state and bring in the state and needed functions
export default connect(mapStateToProps, {
  login2FA,
  clearAuthResponse,
  setSnackbar,
})(Login2FA);

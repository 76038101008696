// Import the React modules
import React, { Fragment } from "react";

// Import material-ui components
import { makeStyles } from "@mui/styles";
import { AppBar, Toolbar, IconButton, Box, Link } from "@mui/material";

import Wave from "../../images/Design/Wave.webp";
import Logo_1 from "../../images/Design/Logo_1.svg";
import CloseIcon from "@mui/icons-material/Close";

// Create the styles
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexGrow: 1,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: "#F2F2F2",
  },
  fullSizeHeader: {
    marginTop: theme.spacing(2.224),
    marginBottom: theme.spacing(2.301),
    display: "flex",
    alignItems: "center",
    width: "100%",
  },
  fullSizeOffset: {
    marginTop: "112.175px",
  },
  mobileSizeOffset: {
    marginTop: "61.48px",
  },
  fullSizeLogo: {
    flexGrow: 1,
    fontSize: "0px",
    marginLeft: theme.spacing(8.875),
  },
  fullSizeWave: {
    width: "100%",
    height: "24px",
    position: "relative",
    top: "-25px",
  },
  mobileSizeWave: {
    width: "100%",
    height: "13px",
    position: "relative",
    top: "-15px",
  },
  fullSizeWaveImg: {
    backgroundImage: `url(${Wave})`,
    height: "24px",
    width: "100%",
    backgroundRepeat: "repeat-x",
    position: "relative",
    top: "24px",
  },
  mobileSizeWaveImg: {
    backgroundImage: `url(${Wave})`,
    height: "13px",
    width: "100%",
    backgroundRepeat: "repeat-x",
    backgroundSize: "auto 13px",
    position: "relative",
    top: "13px",
  },
  mobileSizeHeader: {
    marginTop: theme.spacing(1.25),
    marginBottom: theme.spacing(1),
    display: "flex",
    alignItems: "center",
    width: "100%",
  },
  mobileSizeLogo: {
    flexGrow: 1,
    fontSize: "0px",
    marginLeft: theme.spacing(2.246),
  },
}));

const FakeNavbar = ({ inDialog, onClose }) => {
  // Import the styles
  const classes = useStyles();

  return (
    <Fragment>
      <Box className={classes.root}>
        <AppBar className={classes.appBar}>
          <Toolbar disableGutters>
            {/* FullSize */}
            <Box sx={{ display: { xs: "none", sm: "block" }, width: "100%" }}>
              <Box
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                  width: "100%",
                  maxHeight: "112.175px",
                }}
              >
                <Box className={classes.fullSizeHeader}>
                  <Box className={classes.fullSizeLogo}>
                    <Link color="inherit" underline="none">
                      <img
                        src={Logo_1}
                        style={{ maxHeight: "75.99px" }}
                        alt="SwimTechNL Logo"
                      />
                    </Link>
                  </Box>
                  {inDialog && (
                    <IconButton
                      onClick={onClose}
                      aria-label="close"
                      sx={{ marginRight: "71px" }}
                    >
                      <CloseIcon />
                    </IconButton>
                  )}
                </Box>
                <Box className={classes.fullSizeWave}>
                  <div className={classes.fullSizeWaveImg} />
                </Box>
              </Box>
            </Box>
            {/* Mobile size */}
            <Box sx={{ display: { xs: "block", sm: "none" }, width: "100%" }}>
              <Box
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                  width: "100%",
                  maxHeight: "61.48px",
                }}
              >
                <Box className={classes.mobileSizeHeader}>
                  <Box className={classes.mobileSizeLogo}>
                    <Link color="inherit" underline="none">
                      <img
                        src={Logo_1}
                        style={{ maxHeight: "43.5px" }}
                        alt="SwimTechNL Logo"
                      />
                    </Link>
                  </Box>
                  {inDialog && (
                    <IconButton
                      onClick={onClose}
                      aria-label="close"
                      sx={{ marginRight: "8px", maxHeight: "43.5px" }}
                    >
                      <CloseIcon />
                    </IconButton>
                  )}
                </Box>
                <Box className={classes.mobileSizeWave}>
                  <div className={classes.mobileSizeWaveImg} />
                </Box>
              </Box>
            </Box>
          </Toolbar>
        </AppBar>
      </Box>
      {/* Move all other content down */}
      <Box sx={{ display: { xs: "none", sm: "block" } }}>
        <div className={classes.fullSizeOffset} />
      </Box>
      <Box sx={{ display: { xs: "block", sm: "none" } }}>
        <div className={classes.mobileSizeOffset} />
      </Box>
    </Fragment>
  );
};

export default FakeNavbar;

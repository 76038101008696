// Import the React modules
import React, { Fragment } from "react";
import { Navigate } from "react-router-dom";
import clsx from "clsx";

// Import the Redux modules
import { connect } from "react-redux";
import { register, clearUserResponse } from "reduxStates/userState/userActions";

// Import material-ui/core components
import { Grid, Box, TextField, Typography, Container } from "@mui/material";
import { makeStyles } from "@mui/styles";

import { LoadingButton } from "@mui/lab";

// Import Material-UI Colors
import { red, green } from "@mui/material/colors";

// Import images
import Image from "images/Design/Login/Image.webp";

import { TitleBox } from "components/Layout/PageLayout/PageLayout";

const globalStyles = makeStyles(() => ({
  imageFix: {
    fontSize: 0,
  },
}));

const mainStyles = makeStyles(() => ({
  container: {
    marginTop: "50px",
    marginLeft: "4.86%",
    marginRight: "4.86%",
    paddingBottom: "60px",
  },
  textFieldLabel: {
    fontSize: "17px",
    fontWeight: "600",
    lineHeight: "23px",
    color: "#000000",
    marginBottom: "3px",
  },
  textFieldSmall: {
    "& .MuiOutlinedInput-root": {
      height: "45px",
      borderRadius: "0px",
      "& input": {
        height: "45px",
        padding: "0 14px",
      },
      "& fieldset": {
        borderWidth: "2px",
        borderColor: "#9CD5D4",
      },
      "&.Mui-focused fieldset": {
        borderColor: "rgba(41,171,226, 0.61)",
      },
      "&:hover fieldset": {
        borderColor: "#29ABE2",
      },
    },
  },
  underButton: {
    fontSize: "14px",
    lineHeight: "19px",
    fontWeight: "400",
    fontStyle: "italic",
    textDecorationLine: "underline",
    // color: "rgba(41,171,226,1)",
    color: "rgba(0, 0, 0, 0.26)",
  },
  button: {
    textTransform: "none",
    height: "44px",
    borderRadius: "0px",
    backgroundColor: "#29ABE2",
    opacity: "0.61",
    color: "#F2F2F2",
    "&:hover": {
      backgroundColor: "#1D6E8F",
      opacity: "1",
    },
    fontSize: "17px",
    lineHeight: "23px",
    fontWeight: "400",
    "&.Mui-disabled": {
      opacity: "0.3",
      color: "#F2F2F2",
      backgroundColor: "rgba(0, 0, 0, 0.26)",
    },
  },
  buttonFail: {
    backgroundColor: red[500],
    opacity: "1",
    "&:hover": {
      backgroundColor: red[700],
    },
  },
  buttonSuccess: {
    backgroundColor: green[500],
    opacity: "1",
    "&:hover": {
      backgroundColor: green[700],
    },
  },
  image: {
    zIndex: 1,
  },
  imageContainer: {
    marginRight: "10.6%",
    position: "relative",
    marginTop: "6.5%",
    maxWidth: "417px",
  },
  imageBorder: {
    width: "97.96%",
    height: "96.91%",
    position: "absolute",
    border: "9px solid rgba(156, 213, 212, 0.61)",
    top: "-12.25%",
    left: "17.2%",
    zIndex: -1,
  },
}));

const Register = ({
  user: { userSuccess, userResult },
  register,
  clearUserResponse,
}) => {
  const mainStyle = mainStyles();
  const globalStyle = globalStyles();

  const [loading, setLoading] = React.useState(false);
  const [successFail, setSuccessFail] = React.useState(false);
  const [redirect, setRedirect] = React.useState(false);

  const [firstName, setFirstName] = React.useState("");
  const [firstNameError, setFirstNameError] = React.useState(false);

  const [middleName, setMiddleName] = React.useState("");
  const [middleNameError, setMiddleNameError] = React.useState(false);

  const [lastName, setLastName] = React.useState("");
  const [lastNameError, setLastNameError] = React.useState(false);

  const [email, setEmail] = React.useState("");
  const [emailError, setEmailError] = React.useState(false);

  const [password, setPassword] = React.useState("");
  const [passwordError, setPasswordError] = React.useState(false);

  const [confirmPassword, setConfirmPassword] = React.useState("");
  const [confirmPasswordError, setConfirmPasswordError] = React.useState(false);

  const buttonClassname = clsx({
    [mainStyle.buttonFail]: successFail === "fail",
    [mainStyle.buttonSuccess]: successFail === "success",
  });

  React.useEffect(() => {
    if (userSuccess !== null) {
      if (userResult.origin === "register") {
        if (userSuccess === true) {
          clearUserResponse();
          setLoading(false);
          setSuccessFail("success");
          setRedirect("/register/verifyemailsend");
        } else if (userSuccess === false) {
          setSuccessFail("fail");
          setLoading(false);

          if (userResult.error === "Duplicate field value entered") {
            setEmailError("User with this email already exists");
          }

          clearUserResponse();
        }
      }
    }
    // Prevent any useless errors with net line:
    // eslint-disable-next-line
  }, [userResult]);

  const onRegister = (e) => {
    e.preventDefault();
    setSuccessFail(false);
    setLoading(true);

    let error = false;
    if (firstName === "") {
      setFirstNameError("Firstname is required");
      error = true;
    }
    if (lastName === "") {
      setLastNameError("Firstname is required");
      error = true;
    }
    if (email === "") {
      setEmailError("Email is required");
      error = true;
    }
    if (
      !/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        email
      )
    ) {
      setEmailError("Invalid email");
      error = true;
    }

    if (password === "") {
      setPasswordError("Password is required");
      error = true;
    } else {
      if (password.length < 8 || password.length > 20) {
        setPasswordError(
          "Password must be at least 8 characters and max 20 characters"
        );
        error = true;
      } else {
        if (
          /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,20}$/.test(password) === false
        ) {
          setPasswordError(
            "Password must contain at least one number, one uppercase and one lowercase letter"
          );
          error = true;
        }
      }
    }

    if (confirmPassword === "") {
      setConfirmPasswordError("Confirm password is required");
      error = true;
    }

    if (!error) {
      if (password !== confirmPassword) {
        setPasswordError("Passwords must match");
        setConfirmPasswordError("Passwords must match");
        error = true;
      }
    }

    if (!error) {
      register({
        firstName,
        middleName,
        lastName,
        email,
        password,
      });
    } else {
      setSuccessFail("fail");
      setLoading(false);
    }
  };

  if (redirect) {
    return <Navigate to={redirect} state={{ email: email }} />;
  }

  // Return the component
  return (
    <Fragment>
      <TitleBox rootColor="rgba(156, 213, 212, 0.61)">Register</TitleBox>
      <Box className={mainStyle.container}>
        <Container disableGutters>
          <Grid container spacing={6} direction="row-reverse">
            <Grid item xs={12} sm={6} align="left">
              <Box className={mainStyle.imageContainer}>
                <Box className={mainStyle.imageBorder} />
                <Box className={`${globalStyle.imageFix} ${mainStyle.image}`}>
                  <img src={Image} style={{ width: "100%" }} alt="water" />
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} align="left">
              <Box align="left" maxWidth="367px">
                <Box>
                  <Typography className={mainStyle.textFieldLabel}>
                    First name
                  </Typography>
                  <TextField
                    variant="outlined"
                    fullWidth
                    className={mainStyle.textFieldSmall}
                    value={firstName}
                    onChange={(e) => {
                      setFirstName(e.target.value);
                      setFirstNameError(false);
                      setSuccessFail(false);
                    }}
                    required
                    error={firstNameError !== false}
                    helperText={firstNameError}
                    disabled={loading}
                  />
                </Box>
                <Box paddingTop="25px">
                  <Typography className={mainStyle.textFieldLabel}>
                    Middle name
                  </Typography>
                  <TextField
                    variant="outlined"
                    fullWidth
                    className={mainStyle.textFieldSmall}
                    value={middleName}
                    onChange={(e) => {
                      setMiddleName(e.target.value);
                      setMiddleNameError(false);
                      setSuccessFail(false);
                    }}
                    error={middleNameError !== false}
                    helperText={middleNameError}
                    disabled={loading}
                  />
                </Box>
                <Box paddingTop="25px">
                  <Typography className={mainStyle.textFieldLabel}>
                    Last name
                  </Typography>
                  <TextField
                    variant="outlined"
                    fullWidth
                    className={mainStyle.textFieldSmall}
                    value={lastName}
                    onChange={(e) => {
                      setLastName(e.target.value);
                      setLastNameError(false);
                      setSuccessFail(false);
                    }}
                    required
                    error={lastNameError !== false}
                    helperText={lastNameError}
                    disabled={loading}
                  />
                </Box>
                <Box paddingTop="25px">
                  <Typography className={mainStyle.textFieldLabel}>
                    Email
                  </Typography>
                  <TextField
                    variant="outlined"
                    spellCheck={false}
                    fullWidth
                    className={mainStyle.textFieldSmall}
                    value={email}
                    onChange={(e) => {
                      setEmail(e.target.value);
                      setEmailError(false);
                      setSuccessFail(false);
                    }}
                    required
                    error={emailError !== false}
                    helperText={emailError}
                    disabled={loading}
                  />
                </Box>
                <Box paddingTop="25px">
                  <Typography className={mainStyle.textFieldLabel}>
                    Password
                  </Typography>
                  <TextField
                    type="password"
                    variant="outlined"
                    spellCheck={false}
                    fullWidth
                    className={mainStyle.textFieldSmall}
                    value={password}
                    onChange={(e) => {
                      setPassword(e.target.value);
                      setPasswordError(false);
                      setSuccessFail(false);
                    }}
                    required
                    error={passwordError !== false}
                    helperText={passwordError}
                    disabled={loading}
                  />
                </Box>
                <Box paddingTop="25px">
                  <Typography className={mainStyle.textFieldLabel}>
                    Confirm password
                  </Typography>
                  <TextField
                    type="password"
                    variant="outlined"
                    spellCheck={false}
                    fullWidth
                    className={mainStyle.textFieldSmall}
                    value={confirmPassword}
                    onChange={(e) => {
                      setConfirmPassword(e.target.value);
                      setConfirmPasswordError(false);
                      setSuccessFail(false);
                    }}
                    required
                    error={confirmPasswordError !== false}
                    helperText={confirmPasswordError}
                    disabled={loading}
                  />
                </Box>
                <Box paddingTop="25px">
                  <LoadingButton
                    className={`${mainStyle.button} ${buttonClassname}`}
                    loading={loading}
                    fullWidth
                    onClick={onRegister}
                  >
                    Register
                  </LoadingButton>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </Fragment>
  );
};

// Set the state for this component to the global state
const mapStateToProps = (state) => ({
  user: state.user,
});

// Connect this component to the redux global state and bring in the state and needed functions
export default connect(mapStateToProps, { register, clearUserResponse })(
  Register
);

// Import React Modules
import React, { Fragment } from "react";
import { Navigate } from "react-router-dom";
import clsx from "clsx";

// Import Redux Modules
import { connect } from "react-redux";
import {
  clearUserResponse,
  deleteUser,
} from "reduxStates/userState/userActions";

// Import Material-UI Components
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Grid,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { LoadingButton } from "@mui/lab";

// Import Material-UI Colors
import { red, green } from "@mui/material/colors";

// Create style
const useStyles = makeStyles((theme) => ({
  buttonFail: {
    backgroundColor: `${red[500]} !important`,
    opacity: "1",
    "&:hover": {
      backgroundColor: `${red[700]} !important`,
    },
  },
  buttonSuccess: {
    backgroundColor: `${green[500]} !important`,
    opacity: "1",
    "&:hover": {
      backgroundColor: `${green[700]} !important`,
    },
  },
}));

const DeleteDialog = ({
  user: { loadingUser, userSuccess, userResult },
  deleteDialogOpen,
  closeDeleteDialog,
  single,
  deleteUser,
  clearUserResponse,
}) => {
  // Import style
  const classes = useStyles();

  // // Create local state
  const [loading, setLoading] = React.useState(false);
  const [successFail, setSuccessFail] = React.useState(false);
  const [redirect, setRedirect] = React.useState(false);

  // Red or green button class
  const buttonClassname = clsx({
    [classes.buttonFail]: successFail === "fail",
    [classes.buttonSuccess]: successFail === "success",
  });

  // // UseEffect functions
  // Set the appropiate errors/success with the result from backend
  React.useEffect(() => {
    if (userSuccess !== null) {
      if (userResult.origin === `deleteUser ID: ${single._id}`) {
        if (userSuccess === true) {
          setLoading(false);
          setSuccessFail(false);

          setRedirect("/adminpanel/users");

          closeDeleteDialog();

          clearUserResponse();
        } else if (userSuccess === false) {
          setLoading(false);
          setSuccessFail("fail");

          clearUserResponse();
        }
      }
    }
    // Prevent any useless errors with net line:
    // eslint-disable-next-line
  }, [userResult]);

  // // Button functions
  // Delete the user
  const onDelete = () => {
    setSuccessFail(false);
    setLoading(true);

    deleteUser(single._id);
  };

  // Close the dialog
  const onCancel = () => {
    setSuccessFail(false);
    setLoading(false);

    closeDeleteDialog();
  };

  if (redirect) {
    return <Navigate to={redirect} />;
  }

  return (
    <Fragment>
      <Dialog open={deleteDialogOpen} maxWidth="xl" disableEscapeKeyDown>
        <DialogTitle>
          {`Are you sure you want to delete ${[
            single?.firstName,
            single?.middleName,
            single?.lastName,
          ]
            .filter(Boolean)
            .join(" ")}?`}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>This cannot be undone!</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Grid container spacing={1} justifyContent="flex-end">
            <Grid item>
              <Button
                variant="contained"
                onClick={onCancel}
                color="primary"
                disabled={loadingUser || loading}
              >
                Cancel
              </Button>
            </Grid>
            <Grid item>
              <LoadingButton
                variant="contained"
                onClick={onDelete}
                color="error"
                className={buttonClassname}
                disabled={loadingUser}
                loading={loading}
              >
                Delete{" "}
                {[single?.firstName, single?.middleName, single?.lastName]
                  .filter(Boolean)
                  .join(" ")}
              </LoadingButton>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};

// Set the state for this component to the global state
const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps, { deleteUser, clearUserResponse })(
  DeleteDialog
);

// Import the React modules
import React, { Fragment } from "react";

// Import website components
import FakeNavbar from "../FakeComponents/FakeNavbar";
import FakeHome from "../FakeComponents/FakeHome";
import CookieDialog from "./CookieDialog";

const CookieOptions = () => {
  return (
    <Fragment>
      <FakeNavbar />
      <FakeHome />
      <CookieDialog />
    </Fragment>
  );
};

// Connect this component to the redux global state and bring in the state and needed functions
export default CookieOptions;

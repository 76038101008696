// Import the types
import {
  SET_AUTH_RESPONSE,
  CLEAR_AUTH_RESPONSE,
  SET_AUTH_LOADING_TRUE,
  SET_AUTH_LOADING_FALSE,
  SET_AUTH_ERROR,
  USER_LOADED,
  RESET_ALL,
  SET_SNACKBAR_NOTISTACK,
} from "../types";

// Import required modules
import axios from "axios";

// Import functions from other states
import { setSnackbar } from "../appState/appActions";

// Create the json config
const config = {
  headers: {
    "Content-Type": "application/json",
  },
};

export const loginInit = (formData) => async (dispatch) => {
  dispatch(setLoadingTrue());
  const origin = "loginInit";

  try {
    const response = await axios.post("/api/auth/login/init", formData, config);

    // Set Auth response
    response.data.data["origin"] = origin;
    dispatch({
      type: SET_AUTH_RESPONSE,
      payload: response.data,
    });

    // Check if 2FA is enabled
    if (
      response.data.data.result &&
      response.data.data.result === "2FA enabled"
    ) {
      // Set loading to false
      dispatch(setLoadingFalse());
    } else {
      response.data.data["origin"] = undefined;
      // It is not enabled so we can save user
      dispatch({
        type: USER_LOADED,
        payload: response.data.data,
      });
    }
  } catch (err) {
    dispatch(authError(err, origin));
  }
};

export const login2FA = (formData) => async (dispatch) => {
  dispatch(setLoadingTrue());
  const origin = "login2FA";

  try {
    const response = await axios.post("/api/auth/login/2FA", formData, config);

    // Set Auth response
    response.data.data["origin"] = origin;
    dispatch({
      type: SET_AUTH_RESPONSE,
      payload: response.data,
    });

    response.data.data["origin"] = undefined;
    // Save user
    dispatch({
      type: USER_LOADED,
      payload: response.data.data,
    });
  } catch (err) {
    dispatch(authError(err, origin));
  }
};

export const loginReset2FA = (formData) => async (dispatch) => {
  dispatch(setLoadingTrue());
  const origin = "loginReset2FA";

  try {
    const response = await axios.put(
      "/api/auth/login/reset2FA",
      formData,
      config
    );

    // Set Auth response
    response.data.data["origin"] = origin;
    dispatch({
      type: SET_AUTH_RESPONSE,
      payload: response.data,
    });

    response.data.data["origin"] = undefined;
    // Save user
    dispatch({
      type: USER_LOADED,
      payload: response.data.data,
    });
  } catch (err) {
    dispatch(authError(err, origin));
  }
};

export const logout = () => async (dispatch) => {
  dispatch(setLoadingTrue());
  const origin = "logout";

  dispatch(resetAll());

  try {
    await axios.get("/api/auth/logout");
  } catch (err) {
    dispatch(authError(err, origin));
  }
};

export const resetPasswordInit = (formData) => async (dispatch) => {
  dispatch(setLoadingTrue());
  const origin = "resetPasswordInit";

  try {
    const response = await axios.post(
      "/api/auth/login/resetpassword/init",
      formData,
      config
    );

    dispatch(setSnackbar("Email sent"));

    // Set auth response
    response.data.data["origin"] = origin;
    dispatch({
      type: SET_AUTH_RESPONSE,
      payload: response.data,
    });

    // Set loading to false
    dispatch(setLoadingFalse());
  } catch (err) {
    dispatch(authError(err, origin));
  }
};

export const resetPasswordCheck = (passwordResetToken) => async (dispatch) => {
  dispatch(setLoadingTrue());
  const origin = "resetPasswordCheck";

  try {
    if (passwordResetToken.length < 5) {
      passwordResetToken = "0000000000";
    }

    const response = await axios.get(
      `/api/auth/login/resetpassword/check/${passwordResetToken}`
    );

    // Set auth response
    response.data.data["origin"] = origin;
    dispatch({
      type: SET_AUTH_RESPONSE,
      payload: response.data,
    });

    // Set loading to false
    dispatch(setLoadingFalse());
  } catch (err) {
    dispatch(authError(err, origin));
  }
};

export const resetPasswordNew = (passwordResetToken, formData) => async (
  dispatch
) => {
  dispatch(setLoadingTrue());
  const origin = "resetPasswordNew";

  try {
    const response = await axios.put(
      `/api/auth/login/resetpassword/new/${passwordResetToken}`,
      formData,
      config
    );

    // Set auth response
    response.data.data["origin"] = origin;
    dispatch({
      type: SET_AUTH_RESPONSE,
      payload: response.data,
    });

    dispatch(setSnackbar("Successfully updated password"));

    // Check if 2FA is enabled
    if (
      response.data.data.result &&
      response.data.data.result === "2FA enabled"
    ) {
      // Set loading to false
      dispatch(setLoadingFalse());
    } else {
      response.data.data["origin"] = undefined;
      // It is not enabled so we can save user
      dispatch({
        type: USER_LOADED,
        payload: response.data.data,
      });
    }
  } catch (err) {
    dispatch(authError(err, origin));
  }
};

// Set loading to true
export const setLoadingTrue = () => async (dispatch) => {
  dispatch({ type: SET_AUTH_LOADING_TRUE });
};

// Set loading to false
export const setLoadingFalse = () => async (dispatch) => {
  dispatch({ type: SET_AUTH_LOADING_FALSE });
};

// Clear auth response
export const clearAuthResponse = () => async (dispatch) => {
  dispatch({ type: CLEAR_AUTH_RESPONSE });
};

// Invalid login token
export const invalidLoginToken = () => async (dispatch) => {
  dispatch(resetAll());
  let snackbar = {
    message: "You have been logged out",
    variant: "info",
    key: "Invalid Login Token",
    autoHideDuration: 60000,
  };
  dispatch({
    type: SET_SNACKBAR_NOTISTACK,
    payload: snackbar,
  });
};

// Reset all
export const resetAll = () => async (dispatch) => {
  dispatch(setLoadingTrue());

  dispatch({ type: RESET_ALL });
};

// Auth error
export const authError = (err, origin) => async (dispatch) => {
  // Check if we got the correct response from the backend
  if (err && err.response && err.response.data && err.response.data.data) {
    // We did so lets check what went wrong on the backend
    let error = err.response.data.data.error;

    if (error === "Invalid login JWT") {
      // It was a invalid token (meaning the JWT was used elsewhere)
      dispatch(invalidLoginToken());
    } else if (error === "Not authorized to access this route") {
      if (origin === "loginOneUseJWT") {
        dispatch(setSnackbar(`${error}`, "error"));
      }
      // We have invalid JWT for some reason
      dispatch(resetAll());
    } else {
      if (
        !error.endsWith(", Email is not verified") &&
        error !== "Invalid OTP code" &&
        error !== "Invalid password" &&
        error !== "Invalid email" &&
        error !== "Invalid backup code"
      ) {
        if (error === "SwimTechNL user cannot access this route") {
          if (origin === "loginInit") {
            dispatch(
              setSnackbar(
                "SwimTechNL user needs to login via SwimTechNL.nl",
                "warning"
              )
            );
          } else {
            // We have not handled this error show snackbar
            dispatch(
              setSnackbar(
                `Unhandled auth error from ${origin}: ${error}`,
                "error"
              )
            );
            console.error(`Unhandled auth error from ${origin}: `, error);
          }
        } else {
          // We have not handled this error show snackbar
          dispatch(
            setSnackbar(
              `Unhandled auth error from ${origin}: ${error}`,
              "error"
            )
          );
          console.error(`Unhandled auth error from ${origin}: `, error);
        }
      }
    }

    // No matter what happend we will set the response and error
    // Set auth Error
    dispatch({ type: SET_AUTH_ERROR, payload: error });

    // Set Auth response
    err.response.data.data["origin"] = origin;
    dispatch({
      type: SET_AUTH_RESPONSE,
      payload: err.response.data,
    });
  } else {
    // The request didnt even get to the backend set error and show snackbar
    dispatch({ type: SET_AUTH_ERROR, payload: err });

    // Set Auth response
    let data = {
      success: false,
      data: { origin: origin, error: "Internal server error" },
    };
    dispatch({
      type: SET_AUTH_RESPONSE,
      payload: data,
    });

    // Show snackbar for dev
    dispatch(setSnackbar(`${err}`, "error"));
    console.error("Unhandled auth error: ", err);
  }
};

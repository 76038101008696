// Import the types
import {
  SET_AUTH_RESPONSE,
  CLEAR_AUTH_RESPONSE,
  SET_AUTH_LOADING_TRUE,
  SET_AUTH_LOADING_FALSE,
  SET_AUTH_ERROR,
  USER_LOADED,
  RESET_ALL,
} from "../types";

// Create the initial state
const initialState = {
  loadingAuth: true,
  authSuccess: null,
  authResult: null,
  authError: null,
  isAuthenticated: false,
};

// Export the new new state or updated state
const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_AUTH_RESPONSE:
      return {
        ...state,
        authSuccess: action.payload.success,
        authResult: action.payload.data,
      };
    case CLEAR_AUTH_RESPONSE:
      return {
        ...state,
        authSuccess: null,
        authResult: null,
      };
    case SET_AUTH_LOADING_TRUE:
      return {
        ...state,
        loadingAuth: true,
      };
    case SET_AUTH_LOADING_FALSE:
      return {
        ...state,
        loadingAuth: false,
      };
    case SET_AUTH_ERROR:
      return {
        ...state,
        authError: action.payload,
        loadingAuth: false,
      };
    case USER_LOADED:
      return {
        ...state,
        isAuthenticated: true,
        loadingAuth: false,
      };
    case RESET_ALL:
      return {
        ...state,
        loadingAuth: false,
        authSuccess: null,
        authResult: null,
        authError: null,
        isAuthenticated: false,
      };
    default:
      return state;
  }
};

export default authReducer;

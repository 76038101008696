// Import the types
import {
  SET_USER_RESPONSE,
  CLEAR_USER_RESPONSE,
  SET_USER_LOADING_TRUE,
  SET_USER_LOADING_FALSE,
  SET_USER_ERROR,
  USER_LOADED,
  GET_ALL_USERS,
} from "../types";

// Import required modules
import axios from "axios";

// Import functions from other states
import { setSnackbar } from "../appState/appActions";
import { invalidLoginToken, resetAll } from "../authState/authActions";

// Create the json config
const config = {
  headers: {
    "Content-Type": "application/json",
  },
};

export const getLoggedInUser = () => async (dispatch) => {
  dispatch(setLoadingTrue());
  const origin = "getLoggedInUser";

  try {
    const response = await axios.get("/api/user/getLoggedInUser");

    // Save user
    dispatch({
      type: USER_LOADED,
      payload: response.data.data,
    });
  } catch (err) {
    dispatch(userError(err, origin));
  }
};

export const editLoggedInUser = (formData) => async (dispatch) => {
  dispatch(setLoadingTrue());
  const origin = "editLoggedInUser";

  try {
    const response = await axios.put(
      "/api/user/editLoggedInUser",
      formData,
      config
    );

    // Set user response
    response.data.data["origin"] = origin;
    dispatch({
      type: SET_USER_RESPONSE,
      payload: response.data,
    });

    // Save user
    dispatch({
      type: USER_LOADED,
      payload: response.data.data,
    });
  } catch (err) {
    dispatch(userError(err, origin));
  }
};

export const editLoggedInUsersPassword = (formData) => async (dispatch) => {
  dispatch(setLoadingTrue());
  const origin = "editLoggedInUsersPassword";

  try {
    const response = await axios.put(
      "/api/user/editLoggedInUsersPassword",
      formData,
      config
    );

    // Set user response
    response.data.data["origin"] = origin;
    dispatch({
      type: SET_USER_RESPONSE,
      payload: response.data,
    });

    dispatch(setSnackbar("Successfully updated password"));
    // Save user
    dispatch(setLoadingFalse());
  } catch (err) {
    dispatch(userError(err, origin));
  }
};

export const register = (formData) => async (dispatch) => {
  dispatch(setLoadingTrue());
  const origin = "register";

  try {
    const response = await axios.post("/api/user/register", formData, config);

    // Set user response
    response.data.data["origin"] = origin;
    dispatch({
      type: SET_USER_RESPONSE,
      payload: response.data,
    });

    // Set loading to false
    dispatch(setLoadingFalse());
  } catch (err) {
    dispatch(userError(err, origin));
  }
};

export const verifyEmail = (emailVerifyToken) => async (dispatch) => {
  dispatch(setLoadingTrue());
  const origin = "verifyEmail";

  try {
    if (emailVerifyToken.length < 0) {
      emailVerifyToken = "0000000000";
    }

    const response = await axios.put(
      `/api/user/verifyemail/verify/${emailVerifyToken}`
    );

    dispatch(setSnackbar("Email verified"));

    // Set user response
    response.data.data["origin"] = origin;
    dispatch({
      type: SET_USER_RESPONSE,
      payload: response.data,
    });

    // Set loading to false
    dispatch(setLoadingFalse());
  } catch (err) {
    dispatch(userError(err, origin));
  }
};

export const resendVerifyEmail = (JWT) => async (dispatch) => {
  dispatch(setLoadingTrue());
  const origin = "resendVerifyEmail";

  try {
    const response = await axios.put(`/api/user/verifyemail/resend/${JWT}`);

    dispatch(setSnackbar("Email send"));

    // Set user response
    response.data.data["origin"] = origin;
    dispatch({
      type: SET_USER_RESPONSE,
      payload: response.data,
    });

    // Set loading to false
    dispatch(setLoadingFalse());
  } catch (err) {
    dispatch(userError(err, origin));
  }
};

export const enable2FAInit = () => async (dispatch) => {
  dispatch(setLoadingTrue());
  const origin = "enable2FAInit";

  try {
    const response = await axios.post("/api/user/2FA/enable/init");

    // Set user response
    response.data.data["origin"] = origin;
    dispatch({
      type: SET_USER_RESPONSE,
      payload: response.data,
    });

    // Set loading to false
    dispatch(setLoadingFalse());
  } catch (err) {
    dispatch(userError(err, origin));
  }
};

export const enable2FAVerify = (formData) => async (dispatch) => {
  dispatch(setLoadingTrue());
  const origin = "enable2FAVerify";

  try {
    const response = await axios.put(
      "/api/user/2FA/enable/verify",
      formData,
      config
    );

    // Set user response
    response.data.data["origin"] = origin;
    dispatch({
      type: SET_USER_RESPONSE,
      payload: response.data,
    });

    dispatch(setSnackbar("Successfully enabled 2FA"));

    // Save user
    dispatch({
      type: USER_LOADED,
      payload: response.data.data.data,
    });
  } catch (err) {
    dispatch(userError(err, origin));
  }
};

export const disable2FA = (formData) => async (dispatch) => {
  dispatch(setLoadingTrue());
  const origin = "disable2FA";

  try {
    const response = await axios.put("/api/user/2FA/disable", formData, config);

    // Set user response
    response.data.data["origin"] = origin;
    dispatch({
      type: SET_USER_RESPONSE,
      payload: response.data,
    });

    dispatch(setSnackbar("Successfully disabled 2FA"));
    // Save user
    dispatch({
      type: USER_LOADED,
      payload: response.data.data.data,
    });
  } catch (err) {
    dispatch(userError(err, origin));
  }
};

export const getAllUsers = () => async (dispatch) => {
  dispatch(setLoadingTrue());
  const origin = "getLoggedInUser";

  try {
    const response = await axios.get("/api/user/getAllUsers");

    // Save users
    dispatch({
      type: GET_ALL_USERS,
      payload: response.data.data,
    });
  } catch (err) {
    dispatch(userError(err, origin));
  }
};

export const createUser = (formData) => async (dispatch) => {
  dispatch(setLoadingTrue());
  const origin = "createUser";

  try {
    const response = await axios.post("/api/user", formData, config);

    // User response
    response.data.data["origin"] = origin;
    dispatch({
      type: SET_USER_RESPONSE,
      payload: response.data,
    });

    dispatch(setSnackbar("User created and welcome email send"));

    dispatch(getAllUsers());
  } catch (err) {
    dispatch(userError(err, origin));
  }
};

export const editUser = (formData, userID) => async (dispatch) => {
  dispatch(setLoadingTrue());
  const origin = `editUser ID: ${userID}`;

  try {
    const response = await axios.put(`/api/user/${userID}`, formData, config);

    // Set user response
    response.data.data["origin"] = origin;
    dispatch({
      type: SET_USER_RESPONSE,
      payload: response.data,
    });

    // Get new user list
    dispatch(getAllUsers());
  } catch (err) {
    dispatch(userError(err, origin));
  }
};

export const deleteUser = (userID) => async (dispatch) => {
  dispatch(setLoadingTrue());
  const origin = `deleteUser ID: ${userID}`;

  try {
    const response = await axios.delete(`/api/user/${userID}`);

    // Set user response
    response.data.data["origin"] = origin;
    dispatch({
      type: SET_USER_RESPONSE,
      payload: response.data,
    });

    dispatch(setSnackbar("Successfully deleted user"));
    // Get new user list
    dispatch(getAllUsers());
  } catch (err) {
    dispatch(userError(err, origin));
  }
};

// Set loading to true
export const setLoadingTrue = () => async (dispatch) => {
  dispatch({ type: SET_USER_LOADING_TRUE });
};

// Set loading to false
export const setLoadingFalse = () => async (dispatch) => {
  dispatch({ type: SET_USER_LOADING_FALSE });
};

// Clear user response
export const clearUserResponse = () => async (dispatch) => {
  dispatch({ type: CLEAR_USER_RESPONSE });
};

// User error
export const userError = (err, origin) => async (dispatch) => {
  // Check if we got the correct response from the backend
  if (err && err.response && err.response.data && err.response.data.data) {
    // We did so lets check what went wrong on the backend
    let error = err.response.data.data.error;

    if (error === "Invalid login JWT") {
      // It was a invalid token (meaning the JWT was used elsewhere)
      dispatch(invalidLoginToken());
    } else if (error === "Not authorized to access this route") {
      // We have invalid JWT for some reason
      dispatch(resetAll());
    } else {
      // We have not handled this error show snackbar
      dispatch(
        setSnackbar(`Unhandled user error from ${origin}: ${error}`, "error")
      );
      console.error(`Unhandled user error from ${origin}: `, error);
    }

    // No matter what happend we will set the response and error
    // Set user Error
    dispatch({ type: SET_USER_ERROR, payload: error });

    // Set User response
    err.response.data.data["origin"] = origin;
    dispatch({
      type: SET_USER_RESPONSE,
      payload: err.response.data,
    });
  } else {
    // The request didnt even get to the backend set error and show snackbar
    dispatch({ type: SET_USER_ERROR, payload: err });

    // Set User response
    let data = {
      success: false,
      data: { origin: origin, error: "Internal server error" },
    };
    dispatch({
      type: SET_USER_RESPONSE,
      payload: data,
    });

    // Show snackbar for dev
    dispatch(setSnackbar(`${err}`, "error"));
    console.error("Unhandled user error: ", err);
  }
};

// Import the React modules
import React from "react";

// Import material-ui components
import { makeStyles } from "@mui/styles";
import { LinearProgress, Container } from "@mui/material";

// Create the styles
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",

    marginTop: theme.spacing(2),
  },
}));

const Preloader = () => {
  // Import the styles
  const classes = useStyles();

  // Return the component
  return (
    <div className={classes.root}>
      <Container>
        <LinearProgress />
      </Container>
    </div>
  );
};

// Connect this component to the redux global state and bring in the state and needed functions
export default Preloader;

// Import the React modules
import React, { Fragment } from "react";
import QRCode from "qrcode.react";
import clsx from "clsx";

// Import the Redux modules
import { connect } from "react-redux";
import {
  enable2FAInit,
  enable2FAVerify,
  clearUserResponse,
} from "reduxStates/userState/userActions";

// Import Material-UI Components
import {
  Divider,
  Grid,
  TextField,
  Button,
  Box,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Container,
  Stepper,
  Step,
  StepLabel,
  Typography,
  Paper,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { LoadingButton } from "@mui/lab";

// Import Material-UI Colors
import { red, green } from "@mui/material/colors";

const useStyles = makeStyles((theme) => ({
  buttonFail: {
    backgroundColor: red[500],
    opacity: "1",
    "&:hover": {
      backgroundColor: red[700],
    },
  },
  buttonSuccess: {
    backgroundColor: green[500],
    opacity: "1",
    "&:hover": {
      backgroundColor: green[700],
    },
  },
  divider: {
    marginTop: "8px",
    marginBottom: "12px",
  },
  flexCenter: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
  },
}));

const Enable2FADialog = ({
  user: { loggedInUser, userSuccess, userResult },
  enable2FAInit,
  enable2FAVerify,
  clearUserResponse,
  enable2FADialogOpen,
  closeEnable2FADialog,
}) => {
  // Import style
  const classes = useStyles();

  // // Create local state
  const [loading, setLoading] = React.useState(false);
  const [successFail, setSuccessFail] = React.useState(false);
  const [activeStep, setActiveStep] = React.useState(0);

  // 2FA stuff
  const [backupCodes, setBackupCodes] = React.useState([]);
  const [keyURI, setKeyURI] = React.useState("");

  // Verify code
  const [verifyCode, setVerifyCode] = React.useState("");
  const [verifyCodeError, setVerifyCodeError] = React.useState(false);

  // Red or green button class
  const buttonClassname = clsx({
    [classes.buttonFail]: successFail === "fail",
    [classes.buttonSuccess]: successFail === "success",
  });

  // // UseEffect functions
  // Initialize 2FA setup
  React.useEffect(() => {
    if (loggedInUser) {
      if (loggedInUser.TwoFactorAuthEnabled === false) {
        if (enable2FADialogOpen) {
          setLoading(true);
          enable2FAInit();
        }
      }
    }
    // Prevent any useless errors with net line:
    // eslint-disable-next-line
  }, [loggedInUser, enable2FADialogOpen]);

  // Revieve result from backend
  React.useEffect(() => {
    if (userSuccess !== null) {
      if (userResult.origin === "enable2FAInit") {
        if (userSuccess === true) {
          setKeyURI(userResult.keyuri);
          setBackupCodes(userResult.backupCodes);
          setLoading(false);
          clearUserResponse();
        } else if (userSuccess === false) {
          onCancel();
          clearUserResponse();
        }
      } else if (userResult.origin === "enable2FAVerify") {
        if (userSuccess === true) {
          onCancel();
          clearUserResponse();
        } else if (userSuccess === false) {
          setLoading(false);
          setSuccessFail("fail");
          if (userResult.error === "Invalid OTP code") {
            setVerifyCodeError("Invalid 2FA code");
          }

          clearUserResponse();
        }
      }
    }
    // Prevent any useless errors with net line:
    // eslint-disable-next-line
  }, [userResult]);

  // // Button functions
  // Cancel (reset all values)
  const onCancel = () => {
    setBackupCodes([]);
    setKeyURI("");
    setVerifyCode("");
    setVerifyCodeError(false);
    setActiveStep(0);
    setLoading(false);
    setSuccessFail(false);
    closeEnable2FADialog();
  };

  // Verify
  const verify2FA = () => {
    setSuccessFail(false);
    setLoading(true);

    let error = false;

    if (verifyCode === "") {
      setVerifyCodeError("2FA code is required");
      error = true;
    } else if (verifyCode.length !== 6) {
      setVerifyCodeError("2FA code needs to be 6 numbers");
      error = true;
    }

    if (!error) {
      enable2FAVerify({ otpToken: verifyCode });
    } else {
      setSuccessFail("fail");
      setLoading(false);
    }
  };

  // // Change functions
  // Verify code change
  const onVerifyCodeChange = (e) => {
    setVerifyCode(e.target.value);
    setVerifyCodeError(false);
    setSuccessFail(false);
  };

  return (
    <Fragment>
      <Dialog open={enable2FADialogOpen} maxWidth="md" fullWidth>
        <DialogTitle>Enable 2FA</DialogTitle>
        <Divider />
        <DialogContent>
          <Container>
            <Stepper activeStep={activeStep}>
              <Step>
                <StepLabel>Save backup codes</StepLabel>
              </Step>
              <Step>
                <StepLabel>Scan QR code</StepLabel>
              </Step>
              <Step>
                <StepLabel>Verify 2FA is working</StepLabel>
              </Step>
            </Stepper>
            <Box sx={{ marginTop: "10px" }}>
              {activeStep === 0 &&
                (backupCodes.length !== 0 ? (
                  <Fragment>
                    <Typography variant="h4" align="center">
                      Please save the following backup codes
                    </Typography>
                    <Typography variant="h6" align="center">
                      These codes are the only way to get back into your account
                      if you loose your authentication device
                    </Typography>
                    <Box paddingTop="10px" paddingBottom="10px">
                      <Grid container spacing={2}>
                        {backupCodes.map((code) => (
                          <Grid item xs={6} key={code}>
                            <Paper style={{ backgroundColor: "#F2F2F2" }}>
                              <Typography variant="h5" align="center">
                                {code}
                              </Typography>
                            </Paper>
                          </Grid>
                        ))}
                      </Grid>
                    </Box>
                  </Fragment>
                ) : (
                  <Box align="center">
                    <CircularProgress />
                  </Box>
                ))}
              {activeStep === 1 &&
                (keyURI ? (
                  <Fragment>
                    <Typography variant="h4" align="center">
                      Please scan the QR code using a authenticator app
                    </Typography>
                    <Typography variant="h6" align="center">
                      Most app's should work but we advice Google Authenticator
                    </Typography>
                    <Box
                      sx={{ paddingTop: "10px", paddingBottom: "10px" }}
                      className={classes.flexCenter}
                    >
                      <QRCode value={keyURI} />
                    </Box>
                  </Fragment>
                ) : (
                  <Box align="center">
                    <CircularProgress />
                  </Box>
                ))}
              {activeStep === 2 &&
                (keyURI ? (
                  <Fragment>
                    <Typography variant="h4" align="center">
                      Please enter the code from the authenticator app
                    </Typography>
                    <Box paddingTop="10px" paddingBottom="10px" align="center">
                      <TextField
                        type="number"
                        label="2FA code"
                        variant="outlined"
                        spellCheck={false}
                        value={verifyCode}
                        onChange={onVerifyCodeChange}
                        error={verifyCodeError !== false}
                        helperText={verifyCodeError}
                        disabled={loading}
                        required
                        fullWidth
                      />
                    </Box>
                  </Fragment>
                ) : (
                  <Box align="center">
                    <CircularProgress />
                  </Box>
                ))}
            </Box>
          </Container>
        </DialogContent>
        <Divider />
        <DialogActions>
          <Grid container spacing={2} justifyContent="space-between">
            <Grid item>
              <Button
                variant="outlined"
                color="error"
                onClick={onCancel}
                disabled={loading}
              >
                Cancel
              </Button>
            </Grid>
            <Grid item>
              <Grid container spacing={2} justifyContent="flex-end">
                {activeStep === 0 && (
                  <Fragment>
                    <Grid item>
                      <LoadingButton
                        variant="outlined"
                        color="primary"
                        className={buttonClassname}
                        disabled
                        loading={loading}
                      >
                        Back
                      </LoadingButton>
                    </Grid>
                    <Grid item>
                      <LoadingButton
                        variant="outlined"
                        color="primary"
                        onClick={() => {
                          setActiveStep(1);
                          setSuccessFail(false);
                        }}
                        className={buttonClassname}
                        disabled={backupCodes.length === 0}
                        loading={loading}
                      >
                        I have saved the codes
                      </LoadingButton>
                    </Grid>
                  </Fragment>
                )}
                {activeStep === 1 && (
                  <Fragment>
                    <Grid item>
                      <LoadingButton
                        variant="outlined"
                        color="primary"
                        onClick={() => {
                          setActiveStep(0);
                          setSuccessFail(false);
                        }}
                        className={buttonClassname}
                        loading={loading}
                      >
                        Back
                      </LoadingButton>
                    </Grid>
                    <Grid item>
                      <LoadingButton
                        variant="outlined"
                        color="primary"
                        onClick={() => {
                          setActiveStep(2);
                          setSuccessFail(false);
                        }}
                        className={buttonClassname}
                        loading={loading}
                      >
                        I have scanned the QR code
                      </LoadingButton>
                    </Grid>
                  </Fragment>
                )}
                {activeStep === 2 && (
                  <Fragment>
                    <Grid item>
                      <LoadingButton
                        variant="outlined"
                        color="primary"
                        onClick={() => {
                          setActiveStep(1);
                          setSuccessFail(false);
                          setVerifyCode("");
                          setVerifyCodeError(false);
                        }}
                        className={buttonClassname}
                        loading={loading}
                      >
                        Back
                      </LoadingButton>
                    </Grid>
                    <Grid item>
                      <LoadingButton
                        variant="outlined"
                        color="primary"
                        onClick={verify2FA}
                        className={buttonClassname}
                        loading={loading}
                      >
                        Verify 2FA
                      </LoadingButton>
                    </Grid>
                  </Fragment>
                )}
              </Grid>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps, {
  enable2FAInit,
  enable2FAVerify,
  clearUserResponse,
})(Enable2FADialog);

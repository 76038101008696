// Import the React modules
import React, { Fragment } from "react";
import { Link as RouterLink } from "react-router-dom";

// Import the Redux modules
import { connect } from "react-redux";

// Import Material-UI Components
import {
  Box,
  CircularProgress,
  Container,
  Typography,
  Fab,
} from "@mui/material";
import { makeStyles } from "@mui/styles";

// Import Material-UI Icons
import AddIcon from "@mui/icons-material/Add";

// Import website components
import Item from "./Item";

// Import helpers
import { checkRoles } from "helpers/other";

// Create style
const useStyles = makeStyles((theme) => ({
  fab: {
    position: "fixed",
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
}));

const Summary = ({ user: { users, userLoading, loggedInUser } }) => {
  // Import style
  const classes = useStyles();

  // Create local state
  const [expanded, setExpanded] = React.useState([]);

  // Change expanded
  const expandedChange = (accordionID) => (event, isExpanded) => {
    if (isExpanded) {
      // Panel is expanded
      // Add to expanded array
      let newExpanded = [...expanded];
      newExpanded.push(accordionID);
      setExpanded(newExpanded);
    } else {
      // Panel is closed
      // Remove from expanded array
      let indexToRemove = expanded.indexOf(accordionID);

      if (indexToRemove !== -1) {
        let newExpanded = [...expanded];
        newExpanded.splice(indexToRemove, 1);
        setExpanded(newExpanded);
      }
    }
  };

  return (
    <Fragment>
      <Container>
        <Box sx={{ paddingTop: "16px", paddingBottom: "16px" }}>
          {/* Header */}
          <Box sx={{ paddingBottom: "16px" }}>
            <Typography variant="h4" align="center">
              Users
            </Typography>
          </Box>
          {/* Summary */}
          {!userLoading ? (
            users.map((singleUser) => (
              <Item
                key={singleUser._id}
                content={singleUser}
                expanded={expanded}
                expandedChange={expandedChange}
              />
            ))
          ) : (
            <Box align="center">
              <CircularProgress />
            </Box>
          )}
        </Box>
      </Container>
      <Fab
        color="primary"
        className={classes.fab}
        component={RouterLink}
        to="/adminpanel/users/new"
        disabled={userLoading || !checkRoles(["admin"], loggedInUser.roles)}
      >
        <AddIcon />
      </Fab>
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps, null)(Summary);

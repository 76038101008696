// Import the React modules
import React, { Fragment } from "react";
import { Route, Routes } from "react-router-dom";

// Import the Redux modules
import { connect } from "react-redux";
import { getAllUsers } from "reduxStates/userState/userActions";

// Import Material-UI Components
import { Box, CircularProgress, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";

// Import website components
import Summary from "./Components/Summary/Summary";
import Single from "./Components/Single/Single";
import New from "./Components/New/New";

import PDNE from "components/PageDoesNotExist/PageDoesNotExist";

const useStyles = makeStyles((theme) => ({
  flexCenter: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
  },
}));

const UsersLayout = ({
  user: { users, loggedInUser, loadingUser },
  getAllUsers,
}) => {
  // Import style
  const classes = useStyles();

  // Get all users
  React.useEffect(() => {
    if (!users && !loadingUser) {
      if (loggedInUser.TwoFactorAuthEnabled) {
        getAllUsers();
      }
    }
    // Prevent any useless errors with net line:
    // eslint-disable-next-line
  }, [users, loadingUser]);

  if (!loggedInUser.TwoFactorAuthEnabled) {
    return (
      <Box sx={{ paddingTop: "32px" }} className={classes.flexCenter}>
        <Typography variant="h4">
          2FA needs to be enabled to use this feature
        </Typography>
      </Box>
    );
  }

  if (!users) {
    return (
      <Box sx={{ paddingTop: "32px" }} className={classes.flexCenter}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Fragment>
      <Routes>
        <Route path="/" element={<Summary />} />
        <Route path="/new" element={<New />} />
        <Route path="/:id" element={<Single />} />
        <Route path="*" element={<PDNE />} />
      </Routes>
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps, { getAllUsers })(UsersLayout);

// Import the React modules
import React, { Fragment } from "react";
import clsx from "clsx";

// Import the Redux modules
import { connect } from "react-redux";
import {
  disable2FA,
  clearUserResponse,
} from "reduxStates/userState/userActions";

// Import Material-UI Components
import {
  Divider,
  Grid,
  TextField,
  Button,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { LoadingButton } from "@mui/lab";

// Import Material-UI Colors
import { red, green } from "@mui/material/colors";

const useStyles = makeStyles((theme) => ({
  buttonFail: {
    backgroundColor: red[500],
    opacity: "1",
    "&:hover": {
      backgroundColor: red[700],
    },
  },
  buttonSuccess: {
    backgroundColor: green[500],
    opacity: "1",
    "&:hover": {
      backgroundColor: green[700],
    },
  },
  divider: {
    marginTop: "8px",
    marginBottom: "12px",
  },
}));

const Disable2FADialog = ({
  user: { loggedInUser, userSuccess, userResult },
  disable2FA,
  clearUserResponse,
  disable2FADialogOpen,
  closeDisable2FADialog,
}) => {
  // Import style
  const classes = useStyles();

  // // Create local state
  const [loading, setLoading] = React.useState(false);
  const [successFail, setSuccessFail] = React.useState(false);

  // Otp code
  const [otpCode, setOtpCode] = React.useState("");
  const [otpCodeError, setOtpCodeError] = React.useState(false);

  // Backup code
  const [backupCode, setBackupCode] = React.useState("");
  const [backupCodeError, setBackupCodeError] = React.useState(false);

  // Red or green button class
  const buttonClassname = clsx({
    [classes.buttonFail]: successFail === "fail",
    [classes.buttonSuccess]: successFail === "success",
  });

  // // UseEffect functions
  // Revieve result from backend
  React.useEffect(() => {
    if (userSuccess !== null) {
      if (userResult.origin === "disable2FA") {
        if (userSuccess === true) {
          onCancel();
          clearUserResponse();
        } else if (userSuccess === false) {
          setLoading(false);
          setSuccessFail("fail");

          if (userResult.error === "Invalid OTP code") {
            setOtpCodeError("Invalid 2FA code");
          }

          if (userResult.error === "Invalid backup code") {
            setBackupCodeError("Invalid backup code");
          }

          clearUserResponse();
        }
      }
    }
    // Prevent any useless errors with net line:
    // eslint-disable-next-line
  }, [userResult]);

  // // Button functions
  // Cancel (reset all values)
  const onCancel = () => {
    setLoading(false);
    setSuccessFail(false);

    setOtpCode("");
    setOtpCodeError(false);

    setBackupCode("");
    setBackupCodeError(false);

    closeDisable2FADialog();
  };

  // Disable 2FA
  const onDisable2FA = () => {
    setSuccessFail(false);
    setLoading(true);

    let error = false;
    if (otpCode) {
      if (otpCode.length !== 6) {
        setOtpCodeError("2FA code needs to be 6 numbers");
        error = true;
      }
    }

    if (backupCode) {
      if (backupCode.length !== 8) {
        setBackupCodeError("Backup code needs to be 8 numbers");
        error = true;
      }
    }

    if (!otpCode && !backupCode) {
      setOtpCodeError("2FA code or Backup code is required");
      setBackupCodeError("2FA code or Backup code is required");
      error = true;
    }

    if (!error) {
      if (otpCode) {
        disable2FA({ otpToken: otpCode });
      } else {
        disable2FA({ backupCode: backupCode });
      }
    } else {
      setSuccessFail("fail");
      setLoading(false);
    }
  };

  // // Change functions
  // otp code
  const onOtpCodeChange = (e) => {
    setOtpCode(e.target.value);
    setOtpCodeError(false);
    setSuccessFail(false);
  };

  // Backup code
  const onBackupCodeChange = (e) => {
    setBackupCode(e.target.value);
    setBackupCodeError(false);
    setSuccessFail(false);
  };

  return (
    <Fragment>
      <Dialog open={disable2FADialogOpen} maxWidth="md" fullWidth>
        <DialogTitle>Disable 2FA</DialogTitle>
        <Divider />
        <DialogContent>
          <Box align="center">
            <Typography variant="h6">Using 2FA code</Typography>
            <TextField
              type="number"
              label="2FA code"
              variant="outlined"
              spellCheck={false}
              value={otpCode}
              onChange={onOtpCodeChange}
              error={otpCodeError !== false}
              helperText={otpCodeError}
              disabled={loading}
              required
              fullWidth
            />
          </Box>
          <Box align="center">
            <Typography variant="h6">Using backup code</Typography>
            <TextField
              type="number"
              label="Backup code"
              variant="outlined"
              spellCheck={false}
              value={backupCode}
              onChange={onBackupCodeChange}
              error={backupCodeError !== false}
              helperText={backupCodeError}
              disabled={loading}
              required
              fullWidth
            />
          </Box>
        </DialogContent>
        <Divider />
        <DialogActions>
          <Grid container spacing={2} justifyContent="space-between">
            <Grid item>
              <Button
                variant="outlined"
                color="error"
                onClick={onCancel}
                disabled={loading}
              >
                Cancel
              </Button>
            </Grid>
            <Grid item>
              <LoadingButton
                variant="outlined"
                color="primary"
                onClick={onDisable2FA}
                className={buttonClassname}
                loading={loading}
              >
                Disable 2FA
              </LoadingButton>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps, { disable2FA, clearUserResponse })(
  Disable2FADialog
);

// Import the React modules
import React, { Fragment } from "react";
import { useCookies } from "react-cookie";

// Import material-ui components
import { makeStyles } from "@mui/styles";
import {
  List,
  ListItem,
  ListItemText,
  Container,
  Typography,
  Box,
} from "@mui/material";

// Import website components
import FakeNavbar from "../FakeComponents/FakeNavbar";

const useStyles = makeStyles(() => ({
  listItem: {
    paddingTop: "0px",
    paddingBottom: "0px",
  },
}));

const PrivacyStatement = ({ inDialog = false, onClose = null }) => {
  const classes = useStyles();

  const [cookies] = useCookies(["consent"]);

  return (
    <Fragment>
      <Box paddingTop="16px">
        {(!cookies.consent || !cookies.consent.allowFunctional) && (
          <FakeNavbar inDialog={inDialog} onClose={onClose} />
        )}
        <Container>
          <Typography variant="h3" align="center">
            Privacy statement SwimTechNL
          </Typography>
          <br />
          <Typography variant="body1">
            Dit is het privacy statement van de de heer G. Hehenkamp handelend
            onder de naam SwimTechNL, hierna: SwimTechNL. SwimTechNL is
            ingeschreven bij de Kamer van Koophandel onder nummer 68861753 en is
            gevestigd aan het Avalonpad 17, 3813 HG te Amersfoort. In dit
            document wordt uitgelegd hoe SwimTechNL omgaat met het verwerken van
            uw persoonsgegevens. Bij de verwerking van uw persoonsgegevens
            betracht SwimTechNL de grootst mogelijke zorgvuldigheid.
          </Typography>
          <br />
          <Typography variant="h6">Verkrijging persoonsgegevens:</Typography>
          <Typography variant="body1">
            Indien u gebruikt maakt van de diensten van SwimTechNL, verstrekt u
            zelf een aantal persoonsgegevens aan SwimTechNL, of worden
            persoonsgegevens van u verkregen in het kader van de overeenkomst.
            Onder persoonsgegevens wordt verstaan alle gegevens die betrekking
            hebben op geïdentificeerde of identificeerbare natuurlijke persoon.
          </Typography>
          <br />
          <Typography variant="h6">Categorieën persoonsgegevens</Typography>
          <Typography variant="body1">
            SwimTechNL verwerkt de volgende categorieën van persoonsgegevens:
          </Typography>
          <List className={classes.listItem}>
            <ListItem className={classes.listItem}>
              <ListItemText inset>- Contact- en NAW-gegevens;</ListItemText>
            </ListItem>
            <ListItem className={classes.listItem}>
              <ListItemText inset>- Financiële gegevens;</ListItemText>
            </ListItem>
            <ListItem className={classes.listItem}>
              <ListItemText inset>
                - Overige door u zelf verstrekte persoonsgegevens.
              </ListItemText>
            </ListItem>
          </List>
          <br />
          <Typography variant="h6">Doel van de verwerking</Typography>
          <Typography variant="body1">
            De persoonsgegevens die door SwimTechNL verwerkt worden, hebben ten
            doel om:
          </Typography>
          <List className={classes.listItem}>
            <ListItem className={classes.listItem}>
              <ListItemText inset>
                - Contact met u op te nemen om u te informeren over de door u
                afgenomen diensten en de uitvoering daarvan;
              </ListItemText>
            </ListItem>
            <ListItem className={classes.listItem}>
              <ListItemText inset>
                - Het uitvoeren van haar diensten;
              </ListItemText>
            </ListItem>
            <ListItem className={classes.listItem}>
              <ListItemText inset>
                - Verbeteren van de dienstverlening;
              </ListItemText>
            </ListItem>
            <ListItem className={classes.listItem}>
              <ListItemText inset>
                - Nakomen van wettelijke verplichtingen;
              </ListItemText>
            </ListItem>
            <ListItem className={classes.listItem}>
              <ListItemText inset>
                - Het doen van marketing en communicatieuitingen;
              </ListItemText>
            </ListItem>
            <ListItem className={classes.listItem}>
              <ListItemText inset>
                - Verbeteren van de website door middel van het analyseren van
                bezoekersgedrag op de website;
              </ListItemText>
            </ListItem>
            <ListItem className={classes.listItem}>
              <ListItemText inset>
                - Het versturen van nieuwsbrieven;
              </ListItemText>
            </ListItem>
            <ListItem className={classes.listItem}>
              <ListItemText inset>
                - Gegevensuitwisseling met derden ten behoeve van de uitvoering
                van de dienstverlening.
              </ListItemText>
            </ListItem>
          </List>
          <br />
          <Typography variant="h6">Grondslag verwerking</Typography>
          <Typography variant="body1">
            Het verwerken van persoonsgegevens is slechts mogelijk op grond van
            de hiernavolgende grondslagen: (i) wettelijke verplichting, (ii)
            uitvoering overeenkomst, (iii) verkregen (uitdrukkelijke)
            toestemming van de betrokkenen, en (iv) gerechtvaardigd belang. Bij
            het verlenen van de diensten van SwimTechNL worden persoonsgegevens
            verwerkt. SwimTechNL verwerkt uitsluitend gegevens die SwimTechNL
            noodzakelijk acht voor (het verbeteren van) de dienstverlening en
            gaat zorgvuldig om met de (persoons)gegevens die zij over u en uw
            gebruik van de diensten heeft verzameld. De grondslag voor het
            verwerken van deze gegevens is de overeenkomst die u met SwimTechNL
            bent aangegaan. Ook kunnen er persoonsgegevens van u verwerkt worden
            door het bezoeken van de website www.swimtechnl.nl indien u hiervoor
            toestemming hebt gegeven.
          </Typography>
          <br />
          <Typography variant="h6">Noodzaak verwerking</Typography>
          <Typography variant="body1">
            De verwerking van uw persoonsgegevens is noodzakelijk om de
            dienstverlening uit te kunnen voeren. De diensten die SwimTechNL
            biedt, kunnen zonder het verwerken van uw persoonsgegevens niet
            volledig uitgevoerd worden. Indien voor specifieke doeleinden met
            betrekking tot de persoonsgegevens uw expliciete toestemming is
            vereist, dient u hiervoor separaat toestemming te geven.
          </Typography>
          <br />
          <Typography variant="h6">Geautomatiseerde besluitvorming</Typography>
          <Typography variant="body1">
            Er is geen sprake van geautomatiseerde besluitvorming.
          </Typography>
          <br />
          <Typography variant="h6">Bewaartermijn</Typography>
          <Typography variant="body1">
            De door SwimTechNL verwerkte persoonsgegevens worden bewaard conform
            de relevante wet- en regelgeving. Indien een langere bewaartermijn
            noodzakelijk is op grond van wet- of regelgeving, worden de
            persoonsgegevens langer bewaard conform deze eisen. Alle (verkregen)
            persoonsgegevens worden niet langer bewaard dan strikt noodzakelijk.
          </Typography>
          <br />
          <Typography variant="h6">Verwerking door derden</Typography>
          <Typography variant="body1">
            SwimTechNL deelt persoonsgegevens slechts met derden indien dit
            strikt noodzakelijk is voor de uitvoering van een overeenkomst en om
            te voldoen aan relevante wet- en regelgeving. Er worden geen
            persoonsgegevens verkocht. Op SwimTechNL kan een wettelijke
            verplichting rusten om persoonsgegevens met derden te delen. Indien
            met derden persoonsgegevens worden gedeeld, worden hiervoor
            verwerkersovereenkomsten gesloten. De derden waarmee
            persoonsgegevens gedeeld worden zijn:
          </Typography>
          <List className={classes.listItem}>
            <ListItem className={classes.listItem}>
              <ListItemText inset>
                - De Boekhouder, ten behoeve van de uitvoering van een
                overeenkomst. De categorieën van persoonsgegevens die verwerkt
                worden zijn contact- en NAW-gegevens en financiële gegevens.
              </ListItemText>
            </ListItem>
            <ListItem className={classes.listItem}>
              <ListItemText inset>
                - Softwareleveranciers, ten behoeve van de uitvoering van de
                overeenkomst. De categorieën van persoonsgegevens die verwerkt
                worden zijn de locatiegegevens, het e-mailadres en contact- en
                NAW-gegevens.
              </ListItemText>
            </ListItem>
            <ListItem className={classes.listItem}>
              <ListItemText inset>
                - Websitebeheerder, ten behoeve van uitvoering van de
                overeenkomst. De categorieën van persoonsgegevens die verwerkt
                worden zijn de locatiegegevens, het e-mailadres en contact- en
                NAW-gegevens.
              </ListItemText>
            </ListItem>
            <ListItem className={classes.listItem}>
              <ListItemText inset>
                - Door SwimTechNL ingeschakelde derden, ten behoeve van
                uitvoering van de overeenkomst. De categorieën van
                persoonsgegevens die verwerkt worden zijn de contact- en
                NAW-gegevens.
              </ListItemText>
            </ListItem>
          </List>
          <br />
          <Typography variant="h6">Beveiliging van Persoonsgegevens</Typography>
          <Typography variant="body1">
            SwimTechNL neemt het beschermen van uw persoonsgegevens serieus en
            neemt dienaangaande, rekening houdend met de stand van de techniek,
            uitvoeringskosten, alsook met de aard, omvang, context en de
            verwerkingsdoeleinden en de qua waarschijnlijkheid en ernst
            uiteenlopende risico’s voor de rechten en vrijheden van personen,
            passende technische en organisatorische maatregelen om een op het
            risico afgestemd beveiligingsniveau te waarborgen.
          </Typography>
          <br />
          <Typography variant="h6">Disclaimer website</Typography>
          <Typography variant="body1">
            Door het gebruiken van de website stemt u in met de disclaimer.
            SwimTechNL behoudt zich het recht voor te allen tijde de inhoud van
            haar website en/of deze disclaimer te wijzigen zonder haar klanten
            en/of gebruikers van de website over deze wijziging te informeren.
            De inhoud van de website is met de grootst mogelijke zorgvuldigheid
            samengesteld, maar kan desondanks mogelijke onjuistheden bevatten of
            onvolledig zijn. SwimTechNL aanvaardt op geen enkele wijze enig
            aansprakelijkheid voor schade die is ontstaan door, of voortvloeit
            uit het gebruik van de website. Het gebruiken van deze website komt
            geheel voor eigen rekening en risico van een gebruiker van de
            website. Aan de inhoud van de website kunnen geen rechten ontleend
            worden. Alle teksten op de website zijn auteursrechtelijk beschermd
            en eigendom van SwimTechNL voor zover deze niet aan derden
            toebehoren.
          </Typography>
          <br />
          <Typography variant="h6">Cookieverklaring</Typography>
          <Typography variant="body1">
            SwimTechNL maakt gebruik van technische en functionele cookies om de
            website te optimaliseren. Cookies zijn kleine tekstbestanden die
            door het bezoek aan een website worden meegestuurd om
            gebruikerservaringen van bezoekers efficiënter te maken. SwimTechNL
            mag, volgens de wet, cookies op uw apparaat opslaan als deze cookies
            strikt noodzakelijk zijn om de website te kunnen gebruiken. Voor
            andere soort cookies is uw toestemming nodig. Wij raden aan om de
            cookies te accepteren in verband met de gebruiksvriendelijkheid van
            de website. Bezoekers van de website hebben een check in
            mogelijkheid.
          </Typography>
          <br />
          <Typography variant="body1">
            De cookies die SwimTechNL gebruikt zijn functionele cookies: deze
            cookies zorgen ervoor dat de website naar behoren werkt. Deze
            cookies hebben geen gevolgen voor de persoonlijke levenssfeer zodat
            er geen toestemming gevraagd en verleend hoeft te worden. Met deze
            cookies wordt bijvoorbeeld uw browserinstellingen opgeslagen zodat
            onze website het beste bekeken kan worden, of dat de website
            bereikbaar blijft (load-balancing) maar ook cookies die ervoor
            zorgen dat er geen andere cookies mogen worden geplaats (no-follow).
          </Typography>
          <br />
          <Typography variant="body1">
            Op de website van SwimTechNL worden tevens cookies van Google
            geplaatst ten behoeve van Google Analytics. Wij houden geen
            informatie bij van hetgeen u op internet doet. Google Analytics is
            mogelijk verplicht op grond van geldende wet- en regelgeving inzage
            te geven in deze gegevens Dit heeft tot gevolg dat SwimTechNL
            informatie niet kan koppelen aan een natuurlijke persoon. Indien u
            hierover vragen heeft kunt u contact opnemen via
            swimtechnl@gmail.com.
          </Typography>
          <br />
          <Typography variant="h6">Gebruik Social Media </Typography>
          <Typography variant="body1">
            SwimTechNL maakt gebruik van cookies van derde partijen om de
            website te optimaliseren. Sommige cookies worden geplaatst door
            diensten van derden die op de website worden weergegeven. Met derden
            wordt bedoeld Google Analytics en Social Media ( Facebook). Op het
            gebruik van cookies van andere bedrijven (derden) is het privacy- en
            cookiebeleid van het betreffende bedrijf van toepassing. Wanneer u
            op de website de Social Media button aanklikt, wordt een Social
            Media cookie geplaatst. Hierdoor kan de Social Media partij uw
            IP-adres herkennen zodra u een artikel van de website deelt. Voor de
            cookies van Social Media partijen en de data en/of persoonsgegevens
            die zij hiermee verzamelen, verwijst SwimTechNL u naar de privacy-
            en cookieverklaringen van deze partijen.
          </Typography>
          <br />
          <Typography variant="h6">Browserinstellingen</Typography>
          <Typography variant="body1">
            Als u niet wilt dat websites cookies plaatsen op uw apparaat waarmee
            u de website bekijkt, kunt u uw browserinstellingen aanpassen.
            Voordat een cookie geplaatst wordt, krijgt u een waarschuwing en
            dient u toestemming te geven voor de cookie. Doet u dat niet, kan
            dat bijvoorbeeld tot gevolg hebben dat de website minder goed werkt.
            U kunt de instellingen van uw browser aanpassen zodat uw browser
            alle cookies en ook de cookies van derde(n) weigert. Ook kunt u
            geplaatste cookies verwijderen. Hiervoor moet u de instellingen van
            uw browser aanpassen via voorkeuren en vervolgens kunt u de privacy
            instellingen aanpassen.
          </Typography>
          <br />
          <Typography variant="body1">
            Deze privacyverklaring is niet van toepassing op websites van derden
            die door middel van links met deze website zijn verbonden. Wij
            kunnen niet garanderen dat deze derden op een betrouwbare of veilige
            manier met uw persoonsgegevens omgaan. Wij raden u aan de
            privacyverklaring van deze websites te lezen alvorens van deze
            websites gebruik te maken.
          </Typography>
          <br />
          <Typography variant="h6">Rechten van betrokkenen</Typography>
          <Typography variant="body1">
            Als betrokkene heeft u de volgende rechten:
          </Typography>
          <List className={classes.listItem}>
            <ListItem className={classes.listItem}>
              <ListItemText inset>
                - Recht op inzage;
                <br /> Als betrokkene heeft u het recht om uitsluitsel te
                krijgen over het al dan niet verwerken van uw persoonsgegevens
                en, wanneer dat het geval is, om inzage te verkrijgen met
                betrekking tot de verwerking daarvan.
              </ListItemText>
            </ListItem>
            <ListItem className={classes.listItem}>
              <ListItemText inset>
                - Recht op rectificatie;
                <br /> U heeft het recht om rectificatie en aanvulling van
                onjuiste gegevens te verkrijgen.
              </ListItemText>
            </ListItem>
            <ListItem className={classes.listItem}>
              <ListItemText inset>
                - Recht op gegevenswissing.
                <br /> Zonder onredelijke vertraging dient een betrokkene
                wissing van hem betreffende persoonsgegevens te verkrijgen.
              </ListItemText>
            </ListItem>
            <ListItem className={classes.listItem}>
              <ListItemText inset>
                - Recht op beperking van de verwerking;
                <br /> Als betrokkene heeft u het recht op beperking van de
                verwerking van uw persoonsgegevens. Neem hiervoor contact op met
                SwimTechNL.
              </ListItemText>
            </ListItem>
            <ListItem className={classes.listItem}>
              <ListItemText inset>
                - Recht op overdraagbaarheid van gegevens;
                <br /> Een betrokkene heeft het recht om de hem betreffende
                persoonsgegevens die hij verstrekt heeft aan SwimTechNL, in een
                gestructureerde, gangbare en machineleesbare vorm te verkrijgen,
                en deze gegevens tevens aan SwimTechNL over te dragen zonder
                gehinderd te worden door de verwerkingsverantwoordelijke aan wie
                ze verstrekt waren.
              </ListItemText>
            </ListItem>
            <ListItem className={classes.listItem}>
              <ListItemText inset>
                - Recht van bezwaar;
                <br /> De betrokkene heeft te allen tijde het recht om vanwege
                met zijn specifieke situatie verband houdende redenen bezwaar te
                maken tegen de verwerking van hem betreffende persoonsgegevens
                met inbegrip van profilering op basis van die bepalingen.
                SwimTechNL staakt de verwerking van de persoonsgegevens tenzij
                er sprake is van dwingende gerechtvaardigde gronden voor de
                verwerking die zwaarder wegen dan de belangen, vrijheden en
                rechten van betrokkene die verband houden met de uitoefening
                van, instelling of onderbouwing van een rechtsvordering.
              </ListItemText>
            </ListItem>
          </List>
          <br />
          <Typography variant="h6">Klacht</Typography>
          <Typography variant="body1">
            Indien u een klacht heeft over de wijze waarop SwimTechNL met uw
            persoonsgegevens omgaat, of een van bovengenoemde rechten wenst uit
            te oefenen, kunt u contact opnemen via de website, per e-mail
            info@swimtechnl.nl of telefonisch via 06 50 88 59 99. Om er zeker
            van te zijn dat u een recht wenst uit te oefenen verzoekt SwimTechNL
            u om een kopie van uw identiteitsbewijs mee te sturen. U kunt
            hierbij uw pasfoto en MRZ (strook met nummers onderaan) zwart maken
            ter bescherming van uw privacy. SwimTechNL zal zo spoedig mogelijk,
            doch uiterlijk binnen 4 weken op uw verzoek reageren. Tevens kunt u
            een klacht indienen bij de Autoriteit Persoonsgegevens. Dit is de
            bevoegde handhavingsinstantie. U kunt hiervoor contact opnemen via
            deze link:
            <a href="https://autoriteitpersoonsgegevens.nl/nl/contact-met-de-autoriteit-persoonsgegevens/tip-ons">
              https://autoriteitpersoonsgegevens.nl/nl/contact-met-de-autoriteit-persoonsgegevens/tip-ons
            </a>
            .
          </Typography>
          <br />
          <Typography variant="h6">Wijzigingen Privacy Statement</Typography>
          <Typography variant="body1">
            SwimTechNL kan te allen tijde de privacyverklaring aanpassen. Op de
            website staat de meest recente versie gepubliceerd. Houd daarom
            altijd de website in de gaten voor de meest recente versie. Als het
            nieuwe privacy statement gevolgen heeft voor de wijze waarop wij
            reeds verzamelde gegevens met betrekking tot u verwerken, dan
            brengen wij u per e-mail daarvan op de hoogte.
          </Typography>
          <br />
          <Typography variant="h6">
            Deze versie is voor het laatst vastgesteld op 31 maart 2020.
          </Typography>
        </Container>
      </Box>
    </Fragment>
  );
};

export default PrivacyStatement;

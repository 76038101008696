// // Global
export const RESET_ALL = "RESET_ALL";

export const USER_LOADED = "USER_LOADED";

// // App
export const SET_APP_RESPONSE = "SET_APP_RESPONSE";
export const CLEAR_APP_RESPONSE = "CLEAR_APP_RESPONSE";

export const SET_APP_LOADING_TRUE = "SET_APP_LOADING_TRUE";
export const SET_APP_LOADING_FALSE = "SET_APP_LOADING_FALSE";

export const SET_APP_ERROR = "SET_APP_ERROR";

export const SET_SNACKBAR_NOTISTACK = "SET_SNACKBAR_NOTISTACK";
export const REMOVE_SNACKBAR_NOTISTACK = "REMOVE_SNACKBAR_NOTISTACK";

// // Language
export const SET_LANG_LOADING_TRUE = "SET_LANG_LOADING_TRUE";
export const SET_LANG_LOADING_FALSE = "SET_LANG_LOADING_FALSE";

export const SET_LANG_ERROR = "SET_LANG_ERROR";

export const GET_LANG = "GET_LANG";

// // User
export const SET_USER_RESPONSE = "SET_USER_RESPONSE";
export const CLEAR_USER_RESPONSE = "CLEAR_USER_RESPONSE";

export const SET_USER_LOADING_TRUE = "SET_USER_LOADING_TRUE";
export const SET_USER_LOADING_FALSE = "SET_USER_LOADING_FALSE";

export const SET_USER_ERROR = "SET_USER_ERROR";

export const GET_ALL_USERS = "GET_ALL_USERS";

// // Auth
export const SET_AUTH_RESPONSE = "SET_AUTH_RESPONSE";
export const CLEAR_AUTH_RESPONSE = "CLEAR_AUTH_RESPONSE";

export const SET_AUTH_LOADING_TRUE = "SET_AUTH_LOADING_TRUE";
export const SET_AUTH_LOADING_FALSE = "SET_AUTH_LOADING_FALSE";

export const SET_AUTH_ERROR = "SET_AUTH_ERROR";

// // WaterFiets
export const SET_WATERFIETS_RESPONSE = "SET_WATERFIETS_RESPONSE";
export const CLEAR_WATERFIETS_RESPONSE = "CLEAR_WATERFIETS_RESPONSE";

export const SET_WATERFIETS_LOADING_TRUE = "SET_WATERFIETS_LOADING_TRUE";
export const SET_WATERFIETS_LOADING_FALSE = "SET_WATERFIETS_LOADING_FALSE";

export const SET_WATERFIETS_ERROR = "SET_WATERFIETS_ERROR";

export const WATERFIETS_CLIENTS_LOADED = "WATERFIETS_CLIENTS_LOADED";

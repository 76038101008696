// Import the types
import {
  SET_LANG_LOADING_TRUE,
  SET_LANG_LOADING_FALSE,
  SET_LANG_ERROR,
  GET_LANG,
} from "../types";

// Create the initial state
const initialState = {
  loadingLang: false,
  langError: null,
  language: null,
};

// Export the new new state or updated state
const langReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_LANG_LOADING_TRUE:
      return {
        ...state,
        loadingLang: true,
      };
    case SET_LANG_LOADING_FALSE:
      return {
        ...state,
        loadingLang: false,
      };
    case SET_LANG_ERROR:
      return {
        ...state,
        langError: action.payload,
        loadingLang: false,
      };
    case GET_LANG:
      return {
        ...state,
        language: action.payload,
        loadingLang: false,
      };
    default:
      return state;
  }
};

export default langReducer;

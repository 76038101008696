// Import React Modules
import React from "react";
import { Navigate } from "react-router-dom";

// Import Redux Modules
import { connect } from "react-redux";

// Import Material-UI Components
import { Box, CircularProgress } from "@mui/material";

// Import Page Components
import NotAuthorized from "./NotAuthorized";

const PrivateRoute = ({
  auth: { isAuthenticated, loadingAuth },
  user: { loggedInUser },
  roles,
  children,
}) => {
  // If we are loading the user show the preloader
  if (loadingAuth) {
    return (
      <Box
        sx={{ paddingTop: "100px", display: "flex", justifyContent: "center" }}
      >
        <CircularProgress />
      </Box>
    );
  }

  if (!isAuthenticated) {
    return <Navigate to="/login" />;
  }

  if (
    (loggedInUser && roles.some((r) => loggedInUser.roles.includes(r))) ||
    roles.includes("any")
  ) {
    return children;
  }

  return <NotAuthorized />;
};

// Set the state for this component to the global state
const mapStateToProps = (state) => ({
  auth: state.auth,
  user: state.user,
});

// Connect this component to the redux global state and bring in the state and needed functions
export default connect(mapStateToProps, null)(PrivateRoute);

// Import the React modules
import React, { Fragment } from "react";

// Import material-ui/core components
import { Box, Typography, Grid, Container } from "@mui/material";
import { makeStyles } from "@mui/styles";

// Import images
import Mees from "images/Design/About/Mees.webp";
import Govert from "images/Design/About/Govert.webp";

import { TitleBox } from "components/Layout/PageLayout/PageLayout";

const globalStyles = makeStyles(() => ({
  imageFix: {
    fontSize: 0,
  },
}));

const mainStyles = makeStyles((theme) => ({
  container: {
    marginTop: "50px",
    marginLeft: "4.86%",
    marginRight: "4.86%",
    paddingBottom: "60px",
  },
  image: {
    zIndex: 1,
  },
  imageContainer: {
    position: "relative",
    marginTop: "6.5%",
    [theme.breakpoints.down("md")]: {
      maxWidth: "305px",
    },
    [theme.breakpoints.up("lg")]: {
      maxWidth: "397px",
    },
  },
  imageBorder: {
    width: "97.97%",
    height: "98.00%",
    position: "absolute",
    border: "9px solid rgba(41, 171, 226, 0.61)",
    top: "-38px",
    left: "65px",
    zIndex: -1,
  },
}));

const About = () => {
  const mainStyle = mainStyles();
  const globalStyle = globalStyles();

  return (
    <Fragment>
      <TitleBox rootColor="rgba(41, 171, 226, 0.61)">Over ons</TitleBox>
      <Box className={mainStyle.container}>
        <Container>
          <Grid container spacing={6} alignItems="stretch" rowSpacing={14}>
            <Grid item xs={12} sm={6}>
              <Box className={mainStyle.imageContainer}>
                <Box className={mainStyle.imageBorder} />
                <Box className={`${globalStyle.imageFix} ${mainStyle.image}`}>
                  <img
                    src={Mees}
                    style={{ width: "100%" }}
                    alt="Mees Hehenkamp"
                  />
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography paragraph style={{ textDecoration: "underline" }}>
                ‘Met wie heb ik het genoegen…’
              </Typography>
              <Typography paragraph align="justify">
                Met plezier stel ik me aan u voor. Mijn naam is Mees Hehenkamp.
                Geboren in 1994 te Utrecht, maar getogen in Amersfoort. Ik ben
                de jongste uit een gezin van vier kinderen; twee broers en een
                zus. Mijn middelbare schooltijd heb ik doorgebracht op het
                Eemlandcollege en aansluitend ben ik culturele antropologie gaan
                studeren in Utrecht. In mijn studententijd was ik woonachtig in
                het bruisende en sfeervolle Utrecht. Hier heb ik mijn partner
                Leonie leren kennen. Na mijn afstuderen woon ik, nu samen met
                haar, weer in Amersfoort. Sporten heb ik als ontspanning altijd
                leuk gevonden, zwemmen, voetballen, fietsen… Niet te fanatiek
                daar het leven met al haar diverse uitdagingen zoals erop uit
                trekken met vrienden mij ook bijzonder aantrekt. Het
                ondernemerschap zit bij mij vanaf kinds af aan in het bloed en
                daarbij, niet minder belangrijk, ook iets voor een ander kunnen
                betekenen. Ondertussen heb ik een baan als trainee om mijzelf
                verder te ontwikkelen in de financiële wereld en daarnaast ben
                ik samen met mijn broer Swimtechnl aan het opbouwen waarmee wij
                o.a. zwemscholen of organisaties ondersteuning bieden bij het
                vullen van de roosters omtrent zwemactiviteiten. Naast
                natuurlijk het opbouwen van onze eigen zwemschool Swimtechnl en
                het zelf geven van zwemlessen. Hierbij is ons gezamenlijke doel
                veel kinderen en volwassenen zwemdiploma’s te laten halen maar
                vooral ook plezier aan het zwemmen te beleven. Dit is een
                inspirerende uitdaging die ik met plezier aanga en waar bewegen,
                gezond leven, contact met allerlei mensen van jong tot ouder en
                ondernemerschap samenkomen.
              </Typography>
              <Typography>Mees Hehenkamp, DGA.</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Box className={mainStyle.imageContainer}>
                <Box className={mainStyle.imageBorder} />
                <Box className={`${globalStyle.imageFix} ${mainStyle.image}`}>
                  <img
                    src={Govert}
                    style={{ width: "100%" }}
                    alt="Govert Hehenkamp"
                  />
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography paragraph style={{ textDecoration: "underline" }}>
                ‘Met wie heb ik het genoegen…’
              </Typography>
              <Typography paragraph align="justify">
                Graag zou ik mijzelf willen voorstellen als een zelfstandige,
                enthousiaste jonge man die weet wat hij wil. Zwemschool
                Swimtechnl leiden en onder andere zwemverenigingen en/of
                organisaties ondersteuning bieden bij het vullen van de roosters
                omtrent zwemactiviteiten. Hierdoor kunnen de zwemdocenten zich
                volledig richten op het geven van de zwemlessen waar veel
                kinderen en volwassenen plezier aan beleven. En zelf blijf ik
                ook enthousiast in het bad staan om lessen te geven. Mijn naam
                is Govert Hehenkamp. Geboren in 1987 en opgegroeid en nog steeds
                wonende in Amersfoort. Ik ben de oudste in ons ouderlijk gezin.
                Ik heb twee broers en een zus. Na mijn middelbare schooltijd op
                de Amersfoortse Berg heb ik mij verdiept in diverse
                administratieve werkzaamheden, salarisadministratie en
                boekhouden. Hierin ben ik in deeltijd werkzaam, daarnaast geef
                ik al vanaf mijn 16e zwemles. Het zwemmen en het opzetten van
                mijn onderneming Swimtechnl dat is waar mijn hart ligt en waar
                ik in de toekomst steeds meer mee bezig wil zijn. Actief aan de
                gang zijn en werken met allerlei mensen daar zet ik mij graag
                voor in. Kinderen iets aanleren ook wanneer dit moeilijker gaat
                of misschien wel juist wanneer dit een extra uitdaging vergt,
                geeft mij de energie om dit vak mooi te vinden. Samen met mijn
                broer werken we aan ons gezamenlijk doel Swimtechnl succesvol te
                laten zijn, voor de verenigingen en organisaties die wij
                ondersteunen, voor de mensen die bij ons lessen volgen en last
                but not least voor de werknemers die ons helpen de lessen te
                geven. Nu nog veelal in Amsterdam maar op een later moment in
                meerdere plaatsen.
              </Typography>
              <Typography>Govert Hehenkamp, DGA.</Typography>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </Fragment>
  );
};

export default About;

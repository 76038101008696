// Import the React modules
import React, { Fragment } from "react";
import { Link as RouterLink } from "react-router-dom";

// Import the Redux modules
import { connect } from "react-redux";

// Import material-ui/core components
import { Box, Typography, Grid, Container, Button } from "@mui/material";
import { makeStyles } from "@mui/styles";

// Import images
import Image from "images/Design/Opleiding/Image_1.webp";
import Wave_1 from "images/Design/Opleiding/Wave_1.svg";
import Wave_2 from "images/Design/Opleiding/Wave_2.svg";

// Import docs
import SwimTechNLAlgemeneBepalingen from "./SwimtechNL_Academy_Algemene_Bepalingen_Stageovereenkomst.pdf";
import SwimTechNLStagehandboek from "./SwimtechNL_Academy_Stagehandboek.pdf";
import SwimTechNLStageblad from "./SwimtechNL_Academy_Stageblad.pdf";

import { TitleBox } from "components/Layout/PageLayout/PageLayout";

const globalStyles = makeStyles(() => ({
  imageFix: {
    fontSize: 0,
  },
}));

const mainStyles = makeStyles((theme) => ({
  container: {
    marginTop: "50px",
    marginLeft: "4.86%",
    marginRight: "4.86%",
    paddingBottom: "60px",
  },
  image: {
    zIndex: 1,
  },
  imageContainer: {
    position: "relative",
    marginTop: "6.5%",
    [theme.breakpoints.down("md")]: {
      maxWidth: "305px",
    },
    [theme.breakpoints.up("lg")]: {
      maxWidth: "441px",
    },
  },
  imageBorder: {
    width: "97.96%",
    height: "96.91%",
    position: "absolute",
    border: "9px solid rgba(41, 171, 226, 0.61)",
    top: "-13%",
    left: "14.5%",
    zIndex: -1,
  },
  wave: {
    width: "100%",
    height: "24px",
  },
  waveImg1: {
    background: `url(${Wave_1})`,
    height: "24px",
    width: "100%",
    backgroundRepeat: "repeat-x",
  },
  waveImg2: {
    background: `url(${Wave_2})`,
    height: "24px",
    width: "100%",
    backgroundRepeat: "repeat-x",
  },
  greyBox: {
    background: "rgba(204, 204, 204, 0.47)",
    borderRadius: "30px",
  },
  innerGreyBox: {
    paddingTop: "27px",
    paddingBottom: "27px",
    paddingLeft: "11.41%",
    paddingRight: "11.41%",
  },
  button: {
    textTransform: "none",
    height: "64px",
    backgroundColor: "#29ABE2",
    opacity: "0.61",
    borderRadius: "0px",
    color: "#F2F2F2",
    fontSize: "30px",
    "&:hover": {
      opacity: "1",
      backgroundColor: "#1D6E8F",
    },
    "&.Mui-disabled": {
      opacity: "0.3",
      color: "#F2F2F2",
      backgroundColor: "rgba(0, 0, 0, 0.26)",
    },
  },
  olNumber: {
    paddingLeft: "25px",
    margin: "0px",
    fontSize: "15px",
    lineHeight: "20px",
  },
  ulDash: {
    paddingLeft: "20px",
    margin: "0px",
    fontSize: "15px",
    lineHeight: "20px",
    listStyleType: "none",
    "& li": {
      "&::before": {
        display: "inline-block",
        content: '"-"',
        width: "1em",
        marginLeft: "-1em",
      },
    },
  },
}));

const ZwemInstructeurABC = ({ lang: { language } }) => {
  const mainStyle = mainStyles();
  const globalStyle = globalStyles();

  return (
    <Fragment>
      <TitleBox rootColor="rgba(41,171,226, 0.61)">
        {language.study.page3.pageHeader}
      </TitleBox>
      <Box className={mainStyle.container}>
        <Container disableGutters>
          <Grid container spacing={6} alignItems="stretch">
            <Grid item xs={12} sm={6} align="left">
              <Box className={mainStyle.imageContainer}>
                <Box className={mainStyle.imageBorder} />
                <Box className={`${globalStyle.imageFix} ${mainStyle.image}`}>
                  <img
                    src={Image}
                    style={{ width: "100%" }}
                    alt="swimming child"
                  />
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Box align="left">
                <Typography paragraph style={{ textDecoration: "underline" }}>
                  {language.study.page3.section1.part1}
                </Typography>
                <Typography align="justify">
                  {language.study.page3.section1.part2}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box className={mainStyle.wave}>
                <div className={mainStyle.waveImg1} />
              </Box>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Box align="left">
                <Typography paragraph style={{ textDecoration: "underline" }}>
                  {language.study.page3.section2.part1}
                </Typography>
                <Typography paragraph align="justify">
                  {language.study.page3.section2.part2}
                </Typography>
                <Typography paragraph align="justify">
                  {language.study.page3.section2.part3}
                </Typography>
                <Typography paragraph style={{ textDecoration: "underline" }}>
                  {language.study.page3.section2.part4}
                </Typography>
                <ol className={mainStyle.olNumber}>
                  {language.study.page3.section2.part5.map((item) => (
                    <li key={item}>{item}</li>
                  ))}
                </ol>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Box className={mainStyle.greyBox}>
                <Box className={mainStyle.innerGreyBox} align="left">
                  <Typography paragraph style={{ textDecoration: "underline" }}>
                    {language.study.page3.section3.part1}
                  </Typography>
                  <ul className={mainStyle.ulDash}>
                    {language.study.page3.section3.part2.map((item, index) => {
                      if (
                        index !==
                        language.study.page3.section3.part2.length - 1
                      ) {
                        return (
                          <li
                            style={{
                              marginBottom: "16px",
                            }}
                            key={item}
                          >
                            {item}
                          </li>
                        );
                      } else {
                        return <li key={item}>{item}</li>;
                      }
                    })}
                  </ul>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Box align="left">
                <Typography paragraph style={{ textDecoration: "underline" }}>
                  {language.study.page3.section4.part1}
                </Typography>
                <Typography align="justify">
                  {language.study.page3.section4.part2}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Box align="left">
                <Typography paragraph style={{ textDecoration: "underline" }}>
                  {language.study.page3.section5.part1}
                </Typography>
                <Typography paragraph>
                  {language.study.page3.section5.part2}
                </Typography>
                <Typography paragraph align="justify">
                  {language.study.page3.section5.part3}
                </Typography>
                <Typography align="justify">
                  {language.study.page3.section5.part4}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Box className={mainStyle.greyBox}>
                <Box className={mainStyle.innerGreyBox} align="left">
                  <Typography paragraph>
                    Kosten: 1499,- p.p. (Inclusief opleiding, lesmateriaal en
                    praktijkexamen, EHBO-diploma en certificaat zwemmend redden
                    voor zwembaden van de Reddingsbrigade.)
                  </Typography>
                  <Typography>{language.study.page3.section6.part2}</Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Box align="left">
                <Typography paragraph style={{ textDecoration: "underline" }}>
                  {language.study.page3.section7.part1}
                </Typography>
                <Typography paragraph>
                  {language.study.page3.section7.part2}
                </Typography>
                <Typography>{language.study.page3.section7.part3}</Typography>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box className={mainStyle.greyBox}>
                <Box className={mainStyle.innerGreyBox} align="left">
                  <Typography paragraph style={{ textDecoration: "underline" }}>
                    Documenten
                  </Typography>
                  <ul className={mainStyle.ulDash}>
                    <li
                      style={{
                        marginBottom: "16px",
                      }}
                    >
                      <a href={SwimTechNLAlgemeneBepalingen} target="_blank">
                        Algemene Bepalingen Stageovereenkomst
                      </a>
                    </li>
                    <li
                      style={{
                        marginBottom: "16px",
                      }}
                    >
                      <a href={SwimTechNLStagehandboek} target="_blank">
                        Stagehandboek
                      </a>
                    </li>
                    <li>
                      <a href={SwimTechNLStageblad} target="_bland">
                        Stageblad
                      </a>
                    </li>
                  </ul>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={4} style={{ paddingRight: "0px" }}>
              <Box
                className={mainStyle.wave}
                display="flex"
                alignItems="center"
                style={{ height: "100%" }}
              >
                <div className={mainStyle.waveImg2} />
              </Box>
            </Grid>

            <Grid item xs={8} style={{ paddingLeft: "9px" }}>
              <Button
                className={mainStyle.button}
                fullWidth
                component={RouterLink}
                to="/opleiding/inschrijven/zweminstructeurabc"
              >
                {language.study.global.enlist}
              </Button>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </Fragment>
  );
};

// Set the state for this component to the global state
const mapStateToProps = (state) => ({
  lang: state.lang,
});

// Connect this component to the redux global state and bring in the state and needed functions
export default connect(mapStateToProps, null)(ZwemInstructeurABC);

// Import the types
import {
  SET_APP_RESPONSE,
  CLEAR_APP_RESPONSE,
  SET_APP_LOADING_TRUE,
  SET_APP_LOADING_FALSE,
  SET_APP_ERROR,
  SET_SNACKBAR_NOTISTACK,
  REMOVE_SNACKBAR_NOTISTACK,
} from "../types";

// Import required modules
import axios from "axios";
import { invalidLoginToken, resetAll } from "../authState/authActions";

// Create the json config
const config = {
  headers: {
    "Content-Type": "application/json",
  },
};

export const sendContactEmail = (formData) => async (dispatch) => {
  dispatch(setLoadingTrue());
  const origin = "sendContactEmail";

  try {
    // Send the request
    const response = await axios.post(
      "/api/email/sendContactEmail",
      formData,
      config
    );

    // Set App response
    response.data.data["origin"] = origin;
    dispatch({
      type: SET_APP_RESPONSE,
      payload: response.data,
    });

    // Set loading to false
    dispatch(setLoadingFalse());

    dispatch(setSnackbar(response.data.data.result));
  } catch (err) {
    dispatch(appError(err, origin));
  }
};

export const sendEnlistEmail = (formData) => async (dispatch) => {
  dispatch(setLoadingTrue());
  const origin = "sendEnlistEmail";

  try {
    // Send the request
    const response = await axios.post(
      "/api/email/sendEnlistEmail",
      formData,
      config
    );

    // Set App response
    response.data.data["origin"] = origin;
    dispatch({
      type: SET_APP_RESPONSE,
      payload: response.data,
    });

    // Set loading to false
    dispatch(setLoadingFalse());

    dispatch(setSnackbar(response.data.data.result));
  } catch (err) {
    dispatch(appError(err, origin));
  }
};

// Set loading to true
export const setLoadingTrue = () => async (dispatch) => {
  dispatch({ type: SET_APP_LOADING_TRUE });
};

// Set loading to false
export const setLoadingFalse = () => async (dispatch) => {
  dispatch({ type: SET_APP_LOADING_FALSE });
};

// Set default snackbar
export const setSnackbar =
  (
    message,
    variant = "success",
    key = new Date().getTime() + Math.random(),
    autoHideDuration = 5000
  ) =>
  async (dispatch) => {
    let snackbar = {
      message: message,
      variant: variant,
      key: key,
      autoHideDuration: autoHideDuration,
    };

    dispatch({
      type: SET_SNACKBAR_NOTISTACK,
      payload: snackbar,
    });
  };

// Set custom snackbar
export const setSnackbarNotistack = (snackbar) => async (dispatch) => {
  dispatch({
    type: SET_SNACKBAR_NOTISTACK,
    payload: snackbar,
  });
};

// Remove a snackbar
export const removeSnackbarNotistack = (key) => async (dispatch) => {
  dispatch({ type: REMOVE_SNACKBAR_NOTISTACK, payload: { key } });
};

// Clear the app response
export const clearAppResponse = () => async (dispatch) => {
  dispatch({ type: CLEAR_APP_RESPONSE });
};

// App error
export const appError = (err, origin) => async (dispatch) => {
  // Check if we got the correct response from the backend
  if (err && err.response && err.response.data && err.response.data.data) {
    // We did so lets check what went wrong on the backend
    let error = err.response.data.data.error;

    if (error === "Invalid login JWT") {
      // It was a invalid token (meaning the JWT was used elsewhere)
      dispatch(invalidLoginToken());
    } else if (error === "Not authorized to access this route") {
      // We have invalid JWT for some reason
      dispatch(resetAll());
    } else {
      // We have not handled this error show snackbar
      dispatch(
        setSnackbar(
          `Unhandled myLessons error from: ${origin}: ${error}`,
          "error"
        )
      );
      console.error(`Unhandled app error from: ${origin}: ${error}`);
    }

    // No matter what happend we will set the response and error
    // Set app error
    dispatch({
      type: SET_APP_ERROR,
      payload: error,
    });

    // Set App response
    err.response.data.data["origin"] = origin;
    dispatch({
      type: SET_APP_RESPONSE,
      payload: err.response.data,
    });
  } else {
    // The request didnt even get to the backend set error and show snackbar
    // Set app error
    dispatch({
      type: SET_APP_ERROR,
      payload: err,
    });

    // Set App response
    let data = {
      success: false,
      data: { origin: origin, error: "Internal server error" },
    };
    dispatch({
      type: SET_APP_RESPONSE,
      payload: data,
    });

    // Show snackbar for dev
    dispatch(setSnackbar(`${err}`, "error"));
    console.error("Unhandled app error: ", err);
  }
};

// Import the Redux modules
import { combineReducers } from "redux";

// Import the reducers
import appReducer from "./reduxStates/appState/appReducer";
import langReducer from "./reduxStates/langState/langReducer";
import userReducer from "./reduxStates/userState/userReducer";
import authReducer from "./reduxStates/authState/authReducer";
import waterFietsReducer from "./reduxStates/waterFietsState/waterFietsReducer";

// Combine the reducers to one global state and export
export default combineReducers({
  lang: langReducer,
  app: appReducer,
  auth: authReducer,
  user: userReducer,
  waterFiets: waterFietsReducer,
});

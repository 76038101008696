// Import the React modules
import React, { Fragment } from "react";
import {
  Route,
  Routes,
  useLocation,
  Link as RouterLink,
} from "react-router-dom";

// Import the Redux modules
import { connect } from "react-redux";

// Import Material-UI Components
import {
  Box,
  CircularProgress,
  Container,
  Typography,
  Tabs,
  Tab,
  Grid,
} from "@mui/material";
import { makeStyles } from "@mui/styles";

// Import website components
import PDNE from "components/PageDoesNotExist/PageDoesNotExist";

import Summary from "./Components/Summary/Summary";
import EditAccount from "./Components/EditAccount/EditAccount";
import Security from "./Components/Security/Security";

const useStyles = makeStyles((theme) => ({
  tabs: {
    borderWidth: "1px",
    borderStyle: "solid",
    borderColor: theme.palette.divider,
    borderRadius: 10,
  },
  tabBottomBorder: {
    borderBottomWidth: "1px",
    borderBottomStyle: "solid",
    borderBottomColor: `${theme.palette.divider}`,
  },
  tab: {
    alignItems: "flex-start",
    paddingLeft: "28px",
    textTransform: "none",
  },
}));

const ProfileLayout = ({ user: { loggedInUser } }) => {
  // Import style
  const classes = useStyles();

  // Create local state
  const [selectedTab, setSelectedTab] = React.useState("0");

  // Get the current location (url)
  let location = useLocation();

  React.useEffect(() => {
    if (location.pathname === "/profile") {
      setSelectedTab("0");
    } else if (location.pathname === "/profile/edit") {
      setSelectedTab("1");
    } else if (location.pathname === "/profile/security") {
      setSelectedTab("2");
    }
  }, [location.pathname]);

  // Show preloader weh loading user
  if (!loggedInUser) {
    return (
      <Box paddingTop="100px" align="center">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Fragment>
      <Container maxWidth="md">
        {/* Header */}
        <Box style={{ paddingTop: "25px" }}>
          <Typography variant="h4">
            {[
              loggedInUser.firstName,
              loggedInUser.middleName,
              loggedInUser.lastName,
            ]
              .filter(Boolean)
              .join(" ")}
          </Typography>
          <Typography variant="h6">{loggedInUser.email}</Typography>
        </Box>
        {/* Main */}
        <Box style={{ paddingTop: "16px", paddingBottom: "50px" }}>
          <Grid container spacing={3}>
            <Grid item xs={3}>
              <Tabs
                orientation="vertical"
                className={classes.tabs}
                value={selectedTab}
              >
                <Tab
                  label="Summary"
                  className={`${classes.tab} ${classes.tabBottomBorder}`}
                  value="0"
                  component={RouterLink}
                  to="/profile"
                />
                <Tab
                  label="Edit account"
                  className={`${classes.tab} ${classes.tabBottomBorder}`}
                  value="1"
                  component={RouterLink}
                  to="/profile/edit"
                />
                <Tab
                  label="Security"
                  className={classes.tab}
                  value="2"
                  component={RouterLink}
                  to="/profile/security"
                />
              </Tabs>
            </Grid>
            <Grid item xs={9}>
              <Routes>
                <Route path="/" element={<Summary />} />
                <Route path="/edit" element={<EditAccount />} />
                <Route path="/security" element={<Security />} />
                <Route path="*" element={<PDNE />} />
              </Routes>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps, null)(ProfileLayout);

// Import the React modules
import React, { Fragment } from "react";
import { useLocation } from "react-router-dom";
import { useCookies } from "react-cookie";
import Scrollbars from "react-custom-scrollbars-2";

// Import the Redux modules
import { connect } from "react-redux";
import { resetAll } from "./reduxStates/authState/authActions";

import { getLang } from "./reduxStates/langState/langActions";
import { getLoggedInUser } from "./reduxStates/userState/userActions";

// Import website components
import NavBar from "./components/NavBar/NavBar";
import Footer from "./components/Footer/Footer";
import SnackbarsNotistack from "./components/SnackbarsNotistack/SnackbarsNotistack";
import Preloader from "./components/preLoader";
import CookieOptions from "./components/CookiesOptions/CookieOptions";
import PrivacyStatement from "./components/PrivacyStatement/PrivacyStatement";
import LoadingTimeout from "./components/LoadingTimeout/LoadingTimeout";

import MainLayout from "./views/MainLayout";

import FlexLayout from "components/Layout/FlexLayout/FlexLayout";

const RootLayout = ({
  user: { loggedInUser },
  auth: { isAuthenticated },
  lang: { language, loadingLang },
  getLang,
  getLoggedInUser,
  resetAll,
}) => {
  // Get the current location (url)
  let location = useLocation();

  // Create local state
  const [limitExceeded, setLimitExceeded] = React.useState(false);
  const time = React.useRef(0);
  const timer = React.useRef(false);

  // Get the current cookies
  const [cookies] = useCookies(["consent"]);

  const scrollbars = React.createRef();

  // Get the current logged in user (if we have a login_JWT)
  React.useEffect(() => {
    // Get the user
    if (!loggedInUser || !isAuthenticated) {
      if (cookies.hasLoginJWT && cookies.hasLoginJWT === "true") {
        getLoggedInUser();
      } else {
        resetAll();
      }
    }

    // Prevent any useless errors with net line:
    // eslint-disable-next-line
  }, []);

  // Get the current language and start timer
  React.useEffect(() => {
    if (cookies.consent && cookies.consent.allowFunctional) {
      // Get the language
      if (!language && !loadingLang) {
        getLang();
        // start timeout timer
        timer.current = setInterval(function () {
          if (time.current > 10) {
            setLimitExceeded(true);
          }
          ++time.current;
        }, 1000);
        return () => {
          clearInterval(timer.current);
        };
      }
    }

    // Prevent any useless errors with net line:
    // eslint-disable-next-line
  }, [cookies]);

  // Clear the timer if done
  React.useEffect(() => {
    if (cookies.consent && cookies.consent.allowFunctional) {
      if (language && !loadingLang) {
        // Clear timer
        clearInterval(timer.current);
        setLimitExceeded(false);
      }
    }
    // Prevent any useless errors with net line:
    // eslint-disable-next-line
  }, [language, loadingLang]);

  // Scroll up if we change page
  React.useEffect(() => {
    if (scrollbars.current) {
      // scrollbars.current.scrollToTop();
      scrollbars.current.view.scroll({
        top: 0,
        behavior: "smooth",
      });
    }
    // Prevent any useless errors with net line:
    // eslint-disable-next-line
  }, [location.pathname]);

  // Show cookieOptions if we have no cookies and we are not on privacystatement
  if (!cookies.consent || !cookies.consent.allowFunctional) {
    if (location.pathname !== "/privacystatement") {
      return <CookieOptions />;
    } else {
      return <PrivacyStatement />;
    }
  }

  // Return loadingtimeout if we have cookie and loading timed out
  if (limitExceeded) {
    if (cookies.consent && cookies.consent.allowFunctional) {
      return <LoadingTimeout />;
    }
  }

  // Return preloader if we have no lang and we have cookie
  if (!language || (loadingLang && !limitExceeded)) {
    if (cookies.consent && cookies.consent.allowFunctional) {
      return <Preloader />;
    }
  }

  return (
    <Fragment>
      <FlexLayout root>
        <FlexLayout header>
          <NavBar />
        </FlexLayout>
        <FlexLayout content>
          <Scrollbars
            ref={scrollbars}
            autoHide
            autoHideTimeout={1000}
            autoHideDuration={200}
            style={{ width: "100%", height: "100%" }}
            renderTrackVertical={({ style, ...props }) => (
              <div
                {...props}
                style={{
                  ...style,
                  right: "2px",
                  bottom: "2px",
                  top: "22px",
                  borderRadius: "10px",
                  backgroundColor: "#9CD5D4",
                }}
              />
            )}
            renderThumbVertical={({ style, ...props }) => (
              <div
                {...props}
                style={{
                  ...style,
                  cursor: "pointer",
                  borderRadius: "inherit",
                  backgroundColor: "#1D6E8F",
                }}
              />
            )}
          >
            <FlexLayout root>
              <FlexLayout content>
                <MainLayout />
              </FlexLayout>
              {!location.pathname.startsWith("/adminpanel") && (
                <FlexLayout footer>
                  <Footer />
                </FlexLayout>
              )}
            </FlexLayout>
          </Scrollbars>
        </FlexLayout>
      </FlexLayout>
      <SnackbarsNotistack />
    </Fragment>
  );
};

// Set the state for this component to the global state
const mapStateToProps = (state) => ({
  lang: state.lang,
  auth: state.auth,
  user: state.user,
});

// Connect this component to the redux global state and bring in the state and needed functions
export default connect(mapStateToProps, { getLang, getLoggedInUser, resetAll })(
  RootLayout
);

// Import the React modules
import React, { Fragment } from "react";

// Import Material-UI components
import { Box } from "@mui/material";
import { makeStyles } from "@mui/styles";

// Create style
const useStyles = makeStyles(() => ({
  flexRoot: {
    display: "flex",
    flexFlow: "column",
    height: "100%",
  },
  flexHeader: {
    flexGrow: 0,
    flexShrink: 1,
    flexBasis: "auto",
  },
  flexContent: {
    flexGrow: 1,
    flexShrink: 1,
    flexBasis: "auto",
  },
  flexFooter: {
    flexGrow: 0,
    flexShrink: 1,
    flexBasis: "auto",
  },
}));

const FlexLayout = ({ root, header, content, footer, style, children }) => {
  const classes = useStyles();
  return (
    <Fragment>
      {root ? (
        <Box className={classes.flexRoot} style={style}>
          {children}
        </Box>
      ) : header ? (
        <Box className={classes.flexHeader} style={style}>
          {children}
        </Box>
      ) : content ? (
        <Box className={classes.flexContent} style={style}>
          {children}
        </Box>
      ) : footer ? (
        <Box className={classes.flexFooter} style={style}>
          {children}
        </Box>
      ) : (
        <Box>{children}</Box>
      )}
    </Fragment>
  );
};

export default FlexLayout;

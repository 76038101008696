// Import React Modules
import React, { Fragment } from "react";
import { Link as RouterLink } from "react-router-dom";

// Import Material-UI Components
import {
  Typography,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  AccordionActions,
  Button,
  Divider,
  Grid,
} from "@mui/material";

// Import Material-UI Icons
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const Item = ({ content, expanded, expandedChange }) => {
  return (
    <Fragment>
      <Accordion
        expanded={expanded.includes(content._id)}
        onChange={expandedChange(content._id)}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>
            {[content?.firstName, content?.middleName, content?.lastName]
              .filter(Boolean)
              .join(" ")}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Typography variant="body1">Email: {content.email}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1">
                Roles: {content.roles.join(", ")}
              </Typography>
            </Grid>
          </Grid>
        </AccordionDetails>
        <Divider />
        <AccordionActions>
          <Typography variant="body1" style={{ flexGrow: 1 }}>
            ID: {content._id}
          </Typography>
          <Button
            color="primary"
            component={RouterLink}
            to={`/adminpanel/users/${content._id}`}
            variant="contained"
          >
            More
          </Button>
        </AccordionActions>
        <Divider />
      </Accordion>
    </Fragment>
  );
};

export default Item;

// Import the React modules
import React, { Fragment } from "react";
import { Navigate } from "react-router-dom";
import clsx from "clsx";

// Import the Redux modules
import { connect } from "react-redux";
import {
  createUser,
  clearUserResponse,
} from "reduxStates/userState/userActions";

// Import Material-UI Components
import {
  Grid,
  TextField,
  Checkbox,
  Button,
  Box,
  Typography,
  Container,
  Paper,
  MenuItem,
  ListItemText,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { LoadingButton } from "@mui/lab";

// Import Material-UI Colors
import { red, green } from "@mui/material/colors";

import { usePrompt } from "components/usePrompt/usePrompt";

const useStyles = makeStyles((theme) => ({
  buttonFail: {
    backgroundColor: `${red[500]} !important`,
    opacity: "1",
    "&:hover": {
      backgroundColor: `${red[700]} !important`,
    },
  },
  buttonSuccess: {
    backgroundColor: `${green[500]} !important`,
    opacity: "1",
    "&:hover": {
      backgroundColor: `${green[700]} !important`,
    },
  },
}));

const New = ({
  user: { loadingUser, userSuccess, userResult, loggedInUser },
  createUser,
  clearUserResponse,
}) => {
  // Import style
  const classes = useStyles();

  // // Create local state
  const [loading, setLoading] = React.useState(false);
  const [successFail, setSuccessFail] = React.useState(false);
  const [isBlocking, setIsBlocking] = React.useState(false);
  const [redirect, setRedirect] = React.useState(false);

  // First name
  const [firstName, setFirstName] = React.useState("");
  const [firstNameError, setFirstNameError] = React.useState(false);

  // Middle name
  const [middleName, setMiddleName] = React.useState("");
  const [middleNameError, setMiddleNameError] = React.useState(false);

  // Last name
  const [lastName, setLastName] = React.useState("");
  const [lastNameError, setLastNameError] = React.useState(false);

  // Email
  const [email, setEmail] = React.useState("");
  const [emailError, setEmailError] = React.useState(false);

  // Roles
  const [roles, setRoles] = React.useState([]);
  const [rolesError, setRolesError] = React.useState(false);

  // Red or green button class
  const buttonClassname = clsx({
    [classes.buttonFail]: successFail === "fail",
    [classes.buttonSuccess]: successFail === "success",
  });

  // // UseEffect functions
  // Set the is blocking when a value is changed
  React.useEffect(() => {
    if (
      firstName !== "" ||
      middleName !== "" ||
      lastName !== "" ||
      email !== "" ||
      roles.length !== 0
    ) {
      setIsBlocking(true);
    } else {
      setIsBlocking(false);
    }
  }, [firstName, middleName, lastName, email, roles]);

  // Set the appropiate errors/success with the result from backend
  React.useEffect(() => {
    if (userSuccess !== null) {
      if (userResult.origin === `createUser`) {
        if (userSuccess === true) {
          setLoading(false);
          setSuccessFail(false);
          setIsBlocking(false);

          setRedirect(`/adminpanel/users/${userResult.userID}`);

          clearUserResponse();
        } else if (userSuccess === false) {
          setLoading(false);
          setSuccessFail("fail");

          if (userResult.error === "Duplicate field value entered") {
            setEmailError("User with this email already exists");
          }
          if (userResult.error.startsWith("Welcome email could not be send")) {
            setRedirect(`/adminpanel/users/${userResult.userID}`);
          }

          clearUserResponse();
        }
      }
    }
    // Prevent any useless errors with net line:
    // eslint-disable-next-line
  }, [userResult]);

  React.useEffect(() => {
    return resetVariables();
  }, []);

  // // Button functions
  // Submit the changes to backend
  const onSubmit = () => {
    setSuccessFail(false);
    setLoading(true);

    let error = false;
    if (firstName === "") {
      setFirstNameError("First name is required");
      error = true;
    }

    if (lastName === "") {
      setLastNameError("Last name is required");
      error = true;
    }

    if (email === "") {
      setEmailError("Email is required");
      error = true;
    } else if (
      !/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        email
      )
    ) {
      setEmailError("Invalid email");
      error = true;
    }

    if (roles.length === 0) {
      setRolesError("At least one role is required");
      error = true;
    }

    if (!error) {
      createUser({
        firstName,
        middleName,
        lastName,
        email,
        roles,
      });
    } else {
      setSuccessFail("fail");
      setLoading(false);
    }
  };

  // Cancel
  const onCancel = () => {
    resetVariables();

    setRedirect("/adminpanel/users");
  };

  // // Change functions
  // First name
  const onFirstNameChange = (e) => {
    setFirstName(e.target.value);
    setFirstNameError(false);
    setSuccessFail(false);
  };

  // Middle name
  const onMiddleNameChange = (e) => {
    setMiddleName(e.target.value);
    setMiddleNameError(false);
    setSuccessFail(false);
  };

  // Last name
  const onLastNameChange = (e) => {
    setLastName(e.target.value);
    setLastNameError(false);
    setSuccessFail(false);
  };
  // Email change
  const onEmailChange = (e) => {
    setEmail(e.target.value);
    if (
      e.target.value !== "" &&
      !/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        e.target.value
      )
    ) {
      setEmailError("Invalid email");
    } else {
      setEmailError(false);
    }
    setSuccessFail(false);
  };

  // Roles
  const onRolesChange = (e) => {
    setRoles(e.target.value);
    setRolesError(false);

    setSuccessFail(false);
  };

  // Reset vars
  const resetVariables = () => {
    setLoading(false);
    setSuccessFail(false);
    setIsBlocking(false);

    setFirstName("");
    setFirstNameError(false);

    setMiddleName("");
    setMiddleNameError(false);

    setLastName("");
    setLastNameError(false);

    setEmail("");
    setEmailError(false);

    setRoles([]);
    setRolesError(false);
  };

  usePrompt(
    "Are you sure you want to leave this page? This will reset all values!",
    isBlocking
  );

  if (redirect) {
    return <Navigate to={redirect} />;
  }

  return (
    <Fragment>
      <Container>
        <Box sx={{ paddingTop: "16px", paddingBottom: "16px" }}>
          <Box sx={{ paddingBottom: "16px" }}>
            <Typography variant="h4">New user</Typography>
          </Box>
          <Paper>
            <Container>
              <Box sx={{ paddingTop: "16px", paddingBottom: "16px" }}>
                <Grid container spacing={2}>
                  {/* Name */}
                  <Grid item xs={12}>
                    <TextField
                      type="text"
                      label="First name"
                      variant="outlined"
                      value={firstName}
                      onChange={onFirstNameChange}
                      error={firstNameError !== false}
                      helperText={firstNameError}
                      disabled={loading}
                      required
                      fullWidth
                    />
                  </Grid>
                  {/* Middle name */}
                  <Grid item xs={12}>
                    <TextField
                      type="text"
                      label="Middle name"
                      variant="outlined"
                      value={middleName}
                      onChange={onMiddleNameChange}
                      error={middleNameError !== false}
                      helperText={middleNameError}
                      disabled={loading}
                      fullWidth
                    />
                  </Grid>
                  {/* Last name */}
                  <Grid item xs={12}>
                    <TextField
                      type="text"
                      label="Last name"
                      variant="outlined"
                      value={lastName}
                      onChange={onLastNameChange}
                      error={lastNameError !== false}
                      helperText={lastNameError}
                      disabled={loading}
                      required
                      fullWidth
                    />
                  </Grid>
                  {/* Email */}
                  <Grid item xs={12}>
                    <TextField
                      type="email"
                      label="Email"
                      variant="outlined"
                      spellCheck={false}
                      value={email}
                      onChange={onEmailChange}
                      error={emailError !== false}
                      helperText={emailError}
                      disabled={loading}
                      required
                      fullWidth
                    />
                  </Grid>
                  {/* Roles */}
                  <Grid item xs={12}>
                    <TextField
                      label="Roles"
                      variant="outlined"
                      value={roles}
                      onChange={onRolesChange}
                      error={rolesError !== false}
                      helperText={rolesError}
                      select
                      SelectProps={{
                        multiple: true,
                        renderValue: (selected) => selected.join(", "),
                      }}
                      fullWidth
                    >
                      <MenuItem
                        value="admin"
                        disabled={!loggedInUser.roles.includes("admin")}
                      >
                        <Checkbox
                          color="primary"
                          checked={roles.includes("admin")}
                        />
                        <ListItemText primary="Admin" />
                      </MenuItem>
                      <MenuItem
                        value="owner"
                        disabled={!loggedInUser.roles.includes("admin")}
                      >
                        <Checkbox
                          color="primary"
                          checked={roles.includes("owner")}
                        />
                        <ListItemText primary="Owner" />
                      </MenuItem>
                      <MenuItem value="teacher">
                        <Checkbox
                          color="primary"
                          checked={roles.includes("teacher")}
                        />
                        <ListItemText primary="Teacher" />
                      </MenuItem>
                      <MenuItem value="user">
                        <Checkbox
                          color="primary"
                          checked={roles.includes("user")}
                        />
                        <ListItemText primary="User" />
                      </MenuItem>
                    </TextField>
                  </Grid>
                </Grid>
              </Box>
            </Container>
          </Paper>
          <Box sx={{ paddingTop: "16px" }}>
            <Grid container spacing={2} justifyContent="flex-end">
              {/* Cancel */}
              <Grid item>
                <Button
                  variant="contained"
                  onClick={onCancel}
                  color="error"
                  disabled={loadingUser || loading}
                >
                  Cancel
                </Button>
              </Grid>
              {/* Submit */}
              <Grid item>
                <LoadingButton
                  variant="contained"
                  onClick={onSubmit}
                  color="primary"
                  className={buttonClassname}
                  disabled={loadingUser}
                  loading={loading}
                >
                  Save
                </LoadingButton>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Container>
    </Fragment>
  );
};

// Set the state for this component to the global state
const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps, { createUser, clearUserResponse })(New);

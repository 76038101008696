// Import the types
import {
  SET_USER_RESPONSE,
  CLEAR_USER_RESPONSE,
  SET_USER_LOADING_TRUE,
  SET_USER_LOADING_FALSE,
  SET_USER_ERROR,
  USER_LOADED,
  RESET_ALL,
  GET_ALL_USERS,
} from "../types";

// Create the initial state
const initialState = {
  loadingUser: false,
  userSuccess: null,
  userResult: null,
  userError: null,
  loggedInUser: null,
  users: null,
};

// Export the new new state or updated state
const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_USER_RESPONSE:
      return {
        ...state,
        userSuccess: action.payload.success,
        userResult: action.payload.data,
      };
    case CLEAR_USER_RESPONSE:
      return {
        ...state,
        userSuccess: null,
        userResult: null,
      };
    case SET_USER_LOADING_TRUE:
      return {
        ...state,
        loadingUser: true,
      };
    case SET_USER_LOADING_FALSE:
      return {
        ...state,
        loadingUser: false,
      };
    case SET_USER_ERROR:
      return {
        ...state,
        userError: action.payload,
        loadingUser: false,
      };
    case USER_LOADED:
      return {
        ...state,
        loggedInUser: action.payload,
        loadingUser: false,
      };
    case GET_ALL_USERS:
      return {
        ...state,
        users: action.payload,
        loadingUser: false,
      };
    case RESET_ALL:
      return {
        ...state,
        loadingUser: false,
        userSuccess: null,
        userResult: null,
        userError: null,
        loggedInUser: null,
      };
    default:
      return state;
  }
};

export default userReducer;

// Import the React modules
import React, { Fragment } from "react";
import clsx from "clsx";

// Import the Redux modules
import { connect } from "react-redux";
import {
  editLoggedInUsersPassword,
  clearUserResponse,
} from "reduxStates/userState/userActions";

// Import Material-UI Components
import {
  Typography,
  Divider,
  TextField,
  Grid,
  InputAdornment,
  Button,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { LoadingButton } from "@mui/lab";

// Import Material-UI Colors
import { red, green } from "@mui/material/colors";

import { usePrompt } from "components/usePrompt/usePrompt";

// Import Material-UI Icons
import VpnKey from "@mui/icons-material/VpnKey";

// Import page components
import SecurityDialog from "../SecurityDialog/SecurityDialog";
import Enable2FADialog from "./SecurityComponents/Enable2FADialog";
import Disable2FADialog from "./SecurityComponents/Disable2FADialog";

const useStyles = makeStyles((theme) => ({
  buttonFail: {
    backgroundColor: red[500],
    opacity: "1",
    "&:hover": {
      backgroundColor: red[700],
    },
  },
  buttonSuccess: {
    backgroundColor: green[500],
    opacity: "1",
    "&:hover": {
      backgroundColor: green[700],
    },
  },
  divider: {
    marginTop: "8px",
    marginBottom: "12px",
  },
}));

const Security = ({
  user: { loggedInUser, userSuccess, userResult },
  editLoggedInUsersPassword,
  clearUserResponse,
}) => {
  // Import style
  const classes = useStyles();

  // // Create local state
  const [loading, setLoading] = React.useState(false);
  const [loadingSecurity, setLoadingSecurity] = React.useState(false);
  const [successFail, setSuccessFail] = React.useState(false);
  const [isBlocking, setIsBlocking] = React.useState(false);

  // Current password
  const [currentPassword, setCurrentPassword] = React.useState("");
  const [currentPasswordError, setCurrentPasswordError] = React.useState(false);

  // New password
  const [newPassword, setNewPassword] = React.useState("");
  const [newPasswordError, setNewPasswordError] = React.useState(false);

  // Confirm password
  const [confirmPassword, setConfirmPassword] = React.useState("");
  const [confirmPasswordError, setConfirmPasswordError] = React.useState(false);

  // Security dialog
  const [securityDialogOpen, setSecurityDialogOpen] = React.useState(false);

  // Security code
  const [securityCode, setSecurityCode] = React.useState("");
  const [securityCodeError, setSecurityCodeError] = React.useState(false);

  // Enable 2FA dialog
  const [enable2FADialogOpen, setEnable2FADialogOpen] = React.useState(false);

  // Disable 2FA dialog
  const [disable2FADialogOpen, setDisable2FADialogOpen] = React.useState(false);

  // Red or green button class
  const buttonClassname = clsx({
    [classes.buttonFail]: successFail === "fail",
    [classes.buttonSuccess]: successFail === "success",
  });

  // // UseEffect functions
  // Set the blocking when a value has been entered
  React.useEffect(() => {
    if (
      currentPassword !== "" ||
      newPassword !== "" ||
      confirmPassword !== ""
    ) {
      setIsBlocking(true);
    } else {
      setIsBlocking(false);
    }
  }, [currentPassword, newPassword, confirmPassword]);

  // Set the appropiate errors/success with the result from backend
  React.useEffect(() => {
    if (userSuccess !== null) {
      if (userResult.origin === "editLoggedInUsersPassword") {
        if (userSuccess === true) {
          setLoading(false);
          setLoadingSecurity(false);
          setSuccessFail(false);

          setSecurityDialogOpen(false);
          setSecurityCode("");

          onReset();

          clearUserResponse();
        } else if (userSuccess === false) {
          setLoading(false);
          setLoadingSecurity(false);
          setSuccessFail("fail");

          if (userResult.error === "Invalid password") {
            setCurrentPasswordError("Invalid password");
            setSecurityDialogOpen(false);
            setSecurityCode("");
            setSecurityCodeError(false);
          } else if (
            userResult.error === "Password must be at least 6 characters"
          ) {
            setNewPasswordError("New password must be at least 6 characters");
            setConfirmPasswordError(
              "New password must be at least 6 characters"
            );
            setSecurityDialogOpen(false);
            setSecurityCode("");
            setSecurityCodeError(false);
          } else if (userResult.error === "Invalid OTP code") {
            setSecurityCodeError("Invalid 2FA code");
          }

          clearUserResponse();
        }
      }
    }
    // Prevent any useless errors with net line:
    // eslint-disable-next-line
  }, [userResult]);

  // // Button functions
  // Submit changes to backend
  const onSavePassword = (e) => {
    e.preventDefault();
    setSuccessFail(false);
    setLoading(true);

    let error = false;
    if (currentPassword === "") {
      setCurrentPasswordError("Current password is required");
      error = true;
    }

    if (newPassword === "") {
      setNewPasswordError("New password is required");
      error = true;
    } else {
      if (newPassword.length < 8 || newPassword.length > 20) {
        setNewPasswordError(
          "Password must be at least 8 characters and max 20 characters"
        );
        error = true;
      } else {
        if (
          /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,20}$/.test(newPassword) === false
        ) {
          setNewPasswordError(
            "Password must contain at least one number, one uppercase and one lowercase letter"
          );
          error = true;
        }
      }
    }

    if (confirmPassword === "") {
      setConfirmPasswordError("Confirm password is required");
      error = true;
    }

    if (!error) {
      if (newPassword !== confirmPassword) {
        setNewPasswordError("Passwords must match");
        setConfirmPasswordError("Passwords must match");
        error = true;
      }
    }

    if (!error) {
      if (loggedInUser.TwoFactorAuthEnabled) {
        setSecurityDialogOpen(true);
      } else {
        editLoggedInUsersPassword({ currentPassword, newPassword });
      }
    } else {
      setSuccessFail("fail");
      setLoading(false);
    }
  };

  // Submit the changes to backend if email has been changed (we need to provide 2FA code)
  const onSubmitSecurity = () => {
    setLoadingSecurity(true);

    let error = false;
    if (securityCode === "") {
      setSecurityCodeError("2FA code is required");

      error = true;
    } else {
      if (securityCode.length !== 6) {
        setSecurityCodeError("2FA code needs to be 6 numbers");
        error = true;
      }
    }

    if (!error) {
      editLoggedInUsersPassword({
        currentPassword,
        newPassword,
        otpToken: securityCode,
      });
    } else {
      setSuccessFail("fail");
      setLoadingSecurity(false);
    }
  };

  // Cancel security (close dialog and forget code)
  const onCancelSecurity = () => {
    setSecurityDialogOpen(false);
    setSecurityCode("");
    setSecurityCodeError(false);
    setLoading(false);
    setSuccessFail(false);
  };

  // Reset changes
  const onReset = () => {
    setCurrentPassword("");
    setCurrentPasswordError(false);
    setNewPassword("");
    setNewPasswordError(false);
    setConfirmPassword("");
    setConfirmPasswordError(false);
    setSuccessFail(false);
  };

  // Open enable 2fa dialog
  const openEnable2FADialog = () => {
    setEnable2FADialogOpen(true);
  };

  // Close enable 2FA dialog
  const closeEnable2FADialog = () => {
    setEnable2FADialogOpen(false);
  };

  // Open disable 2FA dialog
  const openDisable2FADialog = () => {
    setDisable2FADialogOpen(true);
  };

  // Close disable 2FA dialog
  const closeDisable2FADialog = () => {
    setDisable2FADialogOpen(false);
  };

  // // Change functions
  // Current password
  const onCurrentPasswordChange = (e) => {
    setCurrentPassword(e.target.value);
    setCurrentPasswordError(false);
    setSuccessFail(false);
  };

  // New password
  const onNewPasswordChange = (e) => {
    setNewPassword(e.target.value);
    setNewPasswordError(false);
    setSuccessFail(false);
  };

  // Confirm password
  const onConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
    setConfirmPasswordError(false);
    setSuccessFail(false);
  };

  // Security 2fa change
  const onSecurityCodeChange = (e) => {
    setSecurityCode(e.target.value);
    setSecurityCodeError(false);
    setSuccessFail(false);
  };

  usePrompt(
    "Some values have been changed, are you sure you want to leave this page? This will reset all values!",
    isBlocking || enable2FADialogOpen
  );

  return (
    <Fragment>
      <Typography variant="h4">Security</Typography>
      <Divider className={classes.divider} />
      {loggedInUser.isSwimTechNLUser ? (
        <Typography variant="h5">
          SwimTechNL users need to edit their account on SwimTechNL.nl
        </Typography>
      ) : (
        <Fragment>
          {/* Password change */}
          <Fragment>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="h6" align="center">
                  Change password
                </Typography>
              </Grid>
              {/* Current password */}
              <Grid item xs={12}>
                <TextField
                  type="password"
                  label="Current password"
                  variant="outlined"
                  spellCheck={false}
                  value={currentPassword}
                  onChange={onCurrentPasswordChange}
                  error={currentPasswordError !== false}
                  helperText={currentPasswordError}
                  disabled={loading}
                  required
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <VpnKey />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              {/* New password */}
              <Grid item xs={12}>
                <TextField
                  type="password"
                  label="New password"
                  variant="outlined"
                  spellCheck={false}
                  value={newPassword}
                  onChange={onNewPasswordChange}
                  error={newPasswordError !== false}
                  helperText={newPasswordError}
                  disabled={loading}
                  required
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <VpnKey />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              {/* Confirm new password */}
              <Grid item xs={12}>
                <TextField
                  type="password"
                  label="Confirm new password"
                  variant="outlined"
                  spellCheck={false}
                  value={confirmPassword}
                  onChange={onConfirmPasswordChange}
                  error={confirmPasswordError !== false}
                  helperText={confirmPasswordError}
                  disabled={loading}
                  required
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <VpnKey />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            </Grid>
            <Grid
              container
              spacing={2}
              justify="flex-end"
              style={{ paddingTop: "16px" }}
            >
              {/* Reset button */}
              <Grid item>
                <Button
                  variant="contained"
                  color="error"
                  type="button"
                  onClick={onReset}
                  disabled={!isBlocking || loading}
                >
                  Reset changes
                </Button>
              </Grid>
              {/* Save button */}
              <Grid item>
                <LoadingButton
                  variant="contained"
                  color="primary"
                  type="submit"
                  onClick={onSavePassword}
                  className={buttonClassname}
                  loading={loading}
                  disabled={!isBlocking}
                >
                  Save new password
                </LoadingButton>
              </Grid>
            </Grid>
          </Fragment>
          <Divider className={classes.divider} />
          {/* 2FA stuff */}
          <Fragment>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="h6" align="center">
                  2FA
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography>2FA is currently:</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography align="right">
                  {loggedInUser.TwoFactorAuthEnabled ? "enabled" : "disabled"}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                {loggedInUser.TwoFactorAuthEnabled ? (
                  <Button
                    variant="contained"
                    color="primary"
                    disabled={loading || isBlocking}
                    onClick={openDisable2FADialog}
                    fullWidth
                  >
                    Disable 2FA
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    color="primary"
                    disabled={loading || isBlocking}
                    onClick={openEnable2FADialog}
                    fullWidth
                  >
                    Enable 2FA
                  </Button>
                )}
              </Grid>
            </Grid>
          </Fragment>
        </Fragment>
      )}
      {/* Security dialog */}
      <SecurityDialog
        securityDialogOpen={securityDialogOpen}
        securityCode={securityCode}
        securityCodeError={securityCodeError}
        onSecurityCodeChange={onSecurityCodeChange}
        loadingSecurity={loadingSecurity}
        onCancelSecurity={onCancelSecurity}
        onSubmitSecurity={onSubmitSecurity}
        buttonClassname={buttonClassname}
      />
      {/* Enable 2FA dialog */}
      <Enable2FADialog
        enable2FADialogOpen={enable2FADialogOpen}
        closeEnable2FADialog={closeEnable2FADialog}
      />
      {/* Disable 2FA dialog */}
      <Disable2FADialog
        disable2FADialogOpen={disable2FADialogOpen}
        closeDisable2FADialog={closeDisable2FADialog}
      />
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps, {
  editLoggedInUsersPassword,
  clearUserResponse,
})(Security);

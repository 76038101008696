// Import the React modules
import React, { Fragment } from "react";
import { NavLink, useLocation } from "react-router-dom";
import clsx from "clsx";

// Import the Redux modules
import { connect } from "react-redux";

// Import Material-UI Components
import {
  Drawer,
  Box,
  Typography,
  Divider,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
} from "@mui/material";
import { makeStyles } from "@mui/styles";

// Import Material-UI Icons
import GroupIcon from "@mui/icons-material/Group";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: 240,
    flexShrink: 0,
    whiteSpace: "nowrap",
    boxSizing: "border-box",
  },
  drawerPaper: {
    width: 240,
  },
  hide: {
    display: "none",
  },
  drawerOffset: {
    [theme.breakpoints.down("sm")]: {
      minHeight: "65px",
    },
    [theme.breakpoints.up("sm")]: {
      minHeight: "130px",
    },
  },
  drawerOpen: {
    width: 240,
    transition: theme.transitions.create(["all"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.standard,
    }),
    overflowX: "hidden",
  },
  drawerClose: {
    transition: theme.transitions.create(["all"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.standard,
    }),
    overflowX: "hidden",
    width: `calc(${theme.spacing(7)} + 1px)`,
  },
  openCloseButton: {
    position: "absolute",
    bottom: "0px",
  },
}));

const PanelDrawer = ({ user: { loggedInUser } }) => {
  // Import style
  const classes = useStyles();

  // // Create local state
  const [drawerOpen, setDrawerOpen] = React.useState(false);

  let location = useLocation();

  const openDrawer = () => {
    setDrawerOpen(true);
  };

  const closeDrawer = () => {
    setDrawerOpen(false);
  };

  return (
    <Fragment>
      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: drawerOpen,
          [classes.drawerClose]: !drawerOpen,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: drawerOpen,
            [classes.drawerClose]: !drawerOpen,
          }),
        }}
      >
        <Box className={classes.drawerOffset} />

        <Box
          className={clsx({
            [classes.hide]: !drawerOpen,
          })}
        >
          <Box sx={{ paddingTop: "10px", textAlign: "center" }}>
            <Typography variant="h5">Logged in as:</Typography>
            <Typography variant="body1" className="name" color="primary">
              {[
                loggedInUser?.firstName,
                loggedInUser?.middleName,
                loggedInUser?.lastName,
              ]
                .filter(Boolean)
                .join(" ") || "Loading"}
            </Typography>
            <Typography variant="body1" color="primary">
              {loggedInUser?.email || "Loading"}
            </Typography>
          </Box>

          <Divider style={{ marginTop: 8 }} />
        </Box>

        <List>
          <ListItem
            button
            component={NavLink}
            to="/adminpanel/users"
            selected={location.pathname.startsWith("/adminpanel/users")}
          >
            <ListItemIcon>
              <GroupIcon />
            </ListItemIcon>
            <ListItemText primary="Users" />
          </ListItem>
        </List>
        <Box
          className={clsx({
            [classes.hide]: !drawerOpen,
          })}
        >
          <ListItem
            button
            className={classes.openCloseButton}
            onClick={closeDrawer}
          >
            <ListItemIcon>
              <ChevronLeftIcon />
            </ListItemIcon>
          </ListItem>
        </Box>
        <Box
          className={clsx({
            [classes.hide]: drawerOpen,
          })}
        >
          <ListItem
            button
            className={classes.openCloseButton}
            onClick={openDrawer}
          >
            <ListItemIcon>
              <MenuIcon />
            </ListItemIcon>
          </ListItem>
        </Box>
      </Drawer>
    </Fragment>
  );
};

// Set the state for this component to the global state
const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps, null)(PanelDrawer);

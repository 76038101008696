// Import the React modules
import React, { Fragment } from "react";
import { Navigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import clsx from "clsx";

// Import the Redux modules
import { connect } from "react-redux";
import {
  loginReset2FA,
  clearAuthResponse,
} from "reduxStates/authState/authActions";
import { setSnackbar } from "reduxStates/appState/appActions";

// Import material-ui/core components
import { Grid, Box, TextField, Typography, Container } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { LoadingButton } from "@mui/lab";

// Import Material-UI Colors
import { red, green } from "@mui/material/colors";

// Import images
import Image from "images/Design/Login/Image.webp";

import { TitleBox } from "components/Layout/PageLayout/PageLayout";

const globalStyles = makeStyles(() => ({
  imageFix: {
    fontSize: 0,
  },
}));

const mainStyles = makeStyles(() => ({
  container: {
    marginTop: "50px",
    marginLeft: "4.86%",
    marginRight: "4.86%",
    paddingBottom: "60px",
  },
  textFieldLabel: {
    fontSize: "17px",
    fontWeight: "600",
    lineHeight: "23px",
    color: "#000000",
    marginBottom: "3px",
  },
  textFieldSmall: {
    "& .MuiOutlinedInput-root": {
      height: "45px",
      borderRadius: "0px",
      "& input": {
        height: "45px",
        padding: "0 14px",
      },
      "& fieldset": {
        borderWidth: "2px",
        borderColor: "#9CD5D4",
      },
      "&.Mui-focused fieldset": {
        borderColor: "rgba(41,171,226, 0.61)",
      },
      "&:hover fieldset": {
        borderColor: "#29ABE2",
      },
    },
  },
  underButton: {
    fontSize: "14px",
    lineHeight: "19px",
    fontWeight: "400",
    fontStyle: "italic",
    textDecorationLine: "underline",
    color: "rgba(41,171,226,1)",
    // color: "rgba(0, 0, 0, 0.26)",
  },
  button: {
    textTransform: "none",
    height: "44px",
    borderRadius: "0px",
    backgroundColor: "#29ABE2",
    opacity: "0.61",
    color: "#F2F2F2",
    "&:hover": {
      backgroundColor: "#1D6E8F",
      opacity: "1",
    },
    fontSize: "17px",
    lineHeight: "23px",
    fontWeight: "400",
    "&.Mui-disabled": {
      opacity: "0.3",
      color: "#F2F2F2",
      backgroundColor: "rgba(0, 0, 0, 0.26)",
    },
  },
  buttonFail: {
    backgroundColor: red[500],
    opacity: "1",
    "&:hover": {
      backgroundColor: red[700],
    },
  },
  buttonSuccess: {
    backgroundColor: green[500],
    opacity: "1",
    "&:hover": {
      backgroundColor: green[700],
    },
  },
  image: {
    zIndex: 1,
  },
  imageContainer: {
    marginRight: "10.6%",
    position: "relative",
    marginTop: "6.5%",
    maxWidth: "417px",
  },
  imageBorder: {
    width: "97.96%",
    height: "96.91%",
    position: "absolute",
    border: "9px solid rgba(156, 213, 212, 0.61)",
    top: "-12.25%",
    left: "17.2%",
    zIndex: -1,
  },
}));

const Reset2FA = ({
  auth: { authSuccess, authResult },
  loginReset2FA,
  clearAuthResponse,
  setSnackbar,
}) => {
  const mainStyle = mainStyles();
  const globalStyle = globalStyles();

  // Create local state
  const [loading, setLoading] = React.useState(false);
  const [successFail, setSuccessFail] = React.useState(false);
  const [redirect, setRedirect] = React.useState(false);

  const [backupCode, setBackupCode] = React.useState("");
  const [backupCodeError, setBackupCodeError] = React.useState(false);

  // Get the current cookies
  const [cookies] = useCookies([]);

  const buttonClassname = clsx({
    [mainStyle.buttonFail]: successFail === "fail",
    [mainStyle.buttonSuccess]: successFail === "success",
  });

  React.useEffect(() => {
    if (authSuccess !== null) {
      if (authResult.origin === "loginReset2FA") {
        if (authSuccess === true) {
          setLoading(false);
          setSuccessFail("success");
          clearAuthResponse();
        } else if (authSuccess === false) {
          setSuccessFail("fail");
          setLoading(false);

          if (authResult.error === "Invalid backup code") {
            setBackupCodeError("Invalid backup code");
          }
          if (authResult.error === "2FA timed out") {
            setRedirect("/login");
          }

          clearAuthResponse();
        }
      }
    }
    // Prevent any useless errors with net line:
    // eslint-disable-next-line
  }, [authResult]);

  // Check if we can be here
  React.useEffect(() => {
    if (!cookies.has2FA_JWT || cookies.has2FA_JWT !== "true") {
      setRedirect("/login");
      setSnackbar("2FA timed out", "warning");
    }

    // Prevent any useless errors with net line:
    // eslint-disable-next-line
  }, [cookies]);

  const onReset2FA = (e) => {
    e.preventDefault();
    setSuccessFail(false);
    setLoading(true);

    let error = false;
    if (backupCode === "") {
      setBackupCodeError("Backup code is required");
      error = true;
    } else if (backupCode.length !== 8) {
      setBackupCodeError("Backup code needs to be 8 digits");
      error = true;
    }

    if (!error) {
      loginReset2FA({ backupCode: backupCode });
    } else {
      setSuccessFail("fail");
      setLoading(false);
    }
  };

  const onBackupCodeChange = (e) => {
    setBackupCode(e.target.value);
    if (e.target.value !== "" && e.target.value.length !== 8) {
      setBackupCodeError("Backup code needs to be 8 digits");
    } else {
      setBackupCodeError(false);
    }
    setSuccessFail(false);
  };

  if (redirect) {
    return <Navigate to={redirect} />;
  }

  return (
    <Fragment>
      <TitleBox rootColor="rgba(156, 213, 212, 0.61)">Reset 2FA</TitleBox>
      <Box className={mainStyle.container}>
        <Container disableGutters>
          <Grid container spacing={6} direction="row-reverse">
            <Grid item xs={12} sm={6} align="left">
              <Box className={mainStyle.imageContainer}>
                <Box className={mainStyle.imageBorder} />
                <Box className={`${globalStyle.imageFix} ${mainStyle.image}`}>
                  <img src={Image} style={{ width: "100%" }} alt="water" />
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} align="left">
              <Box align="left" maxWidth="367px">
                <form noValidate onSubmit={onReset2FA}>
                  <Box>
                    <Typography className={mainStyle.textFieldLabel}>
                      Backup code
                    </Typography>
                    <TextField
                      variant="outlined"
                      fullWidth
                      className={mainStyle.textFieldSmall}
                      type="number"
                      spellCheck={false}
                      value={backupCode}
                      onChange={onBackupCodeChange}
                      required
                      error={backupCodeError !== false}
                      helperText={backupCodeError}
                      disabled={loading}
                    />
                  </Box>
                  <Box paddingTop="25px">
                    <LoadingButton
                      className={`${mainStyle.button} ${buttonClassname}`}
                      fullWidth
                      type="submit"
                      loading={loading}
                    >
                      Reset 2FA
                    </LoadingButton>
                  </Box>
                </form>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </Fragment>
  );
};

// Set the state for this component to the global state
const mapStateToProps = (state) => ({
  lang: state.lang,
  auth: state.auth,
});

// Connect this component to the redux global state and bring in the state and needed functions
export default connect(mapStateToProps, {
  loginReset2FA,
  clearAuthResponse,
  setSnackbar,
})(Reset2FA);

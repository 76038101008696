// Import the React modules
import React, { Fragment } from "react";
import { connect } from "react-redux";
import { removeSnackbarNotistack } from "../../reduxStates/appState/appActions";

// Import Material-UI Components
import { IconButton } from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";

import { useSnackbar } from "notistack";

let displayed = [];

const SnackbarsTest = ({
  app: { snackbarsNotistack },
  removeSnackbarNotistack,
}) => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const storeDisplayed = (id) => {
    displayed = [...displayed, id];
  };

  const removeDisplayed = (id) => {
    displayed = [...displayed.filter((key) => id !== key)];
  };

  React.useEffect(() => {
    snackbarsNotistack.forEach(
      ({ message, key, variant, autoHideDuration, Action }) => {
        // do nothing if snackbar is already displayed
        if (displayed.includes(key)) return;
        if (key === "Invalid Token") {
          closeSnackbar();
        }

        let combinedAction;
        if (Action) {
          combinedAction = () => (
            <Fragment>
              <Action />
              <IconButton
                onClick={() => {
                  closeSnackbar(key);
                }}
              >
                <CloseIcon fontSize="small" style={{ color: "#ffffff" }} />
              </IconButton>
            </Fragment>
          );
        }

        // display snackbar using notistack
        enqueueSnackbar(message, {
          key,
          variant,
          autoHideDuration,
          preventDuplicate: true,
          onExited: (event, myKey) => {
            // removen this snackbar from redux store
            removeSnackbarNotistack(myKey);
            removeDisplayed(myKey);
          },
          action: combinedAction,
        });

        // keep track of snackbars that we've displayed
        storeDisplayed(key);
      }
    );
    // Prevent any useless errors with net line:
    // eslint-disable-next-line
  }, [snackbarsNotistack, closeSnackbar, enqueueSnackbar]);

  return null;
};

// Set the state for this component to the global state
const mapStateToProps = (state) => ({
  app: state.app,
});

export default connect(mapStateToProps, { removeSnackbarNotistack })(
  SnackbarsTest
);

// Import the React modules
import React, { Fragment } from "react";
import { Navigate, useParams } from "react-router-dom";
import clsx from "clsx";

// Import the Redux modules
import { connect } from "react-redux";

import {
  resetPasswordCheck,
  resetPasswordNew,
  clearAuthResponse,
} from "reduxStates/authState/authActions";

// Import material-ui/core components
import { Box, TextField, Typography, Container, Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { LoadingButton } from "@mui/lab";

// Import Material-UI Colors
import { red, green } from "@mui/material/colors";

// Import images
import Image from "images/Design/Login/Image.webp";

import { TitleBox } from "components/Layout/PageLayout/PageLayout";

const globalStyles = makeStyles(() => ({
  imageFix: {
    fontSize: 0,
  },
}));

const mainStyles = makeStyles(() => ({
  container: {
    marginTop: "50px",
    marginLeft: "4.86%",
    marginRight: "4.86%",
    paddingBottom: "60px",
  },
  textFieldLabel: {
    fontSize: "17px",
    fontWeight: "600",
    lineHeight: "23px",
    color: "#000000",
    marginBottom: "3px",
  },
  textFieldSmall: {
    "& .MuiOutlinedInput-root": {
      height: "45px",
      borderRadius: "0px",
      "& input": {
        height: "45px",
        padding: "0 14px",
      },
      "& fieldset": {
        borderWidth: "2px",
        borderColor: "#9CD5D4",
      },
      "&.Mui-focused fieldset": {
        borderColor: "rgba(41,171,226, 0.61)",
      },
      "&:hover fieldset": {
        borderColor: "#29ABE2",
      },
    },
  },
  underButton: {
    fontSize: "14px",
    lineHeight: "19px",
    fontWeight: "400",
    fontStyle: "italic",
    textDecorationLine: "underline",
    color: "rgba(41,171,226,1)",
    // color: "rgba(0, 0, 0, 0.26)",
  },
  button: {
    textTransform: "none",
    height: "44px",
    borderRadius: "0px",
    backgroundColor: "#29ABE2",
    opacity: "0.61",
    color: "#F2F2F2",
    "&:hover": {
      backgroundColor: "#1D6E8F",
      opacity: "1",
    },
    fontSize: "17px",
    lineHeight: "23px",
    fontWeight: "400",
    "&.Mui-disabled": {
      opacity: "0.3",
      color: "#F2F2F2",
      backgroundColor: "rgba(0, 0, 0, 0.26)",
    },
  },
  buttonFail: {
    backgroundColor: red[500],
    opacity: "1",
    "&:hover": {
      backgroundColor: red[700],
    },
  },
  buttonSuccess: {
    backgroundColor: green[500],
    opacity: "1",
    "&:hover": {
      backgroundColor: green[700],
    },
  },
  image: {
    zIndex: 1,
  },
  imageContainer: {
    marginRight: "10.6%",
    position: "relative",
    marginTop: "6.5%",
    maxWidth: "417px",
  },
  imageBorder: {
    width: "97.96%",
    height: "96.91%",
    position: "absolute",
    border: "9px solid rgba(156, 213, 212, 0.61)",
    top: "-12.25%",
    left: "17.2%",
    zIndex: -1,
  },
}));

const ResetPasswordNew = ({
  auth: { authSuccess, authResult },
  resetPasswordCheck,
  resetPasswordNew,
  clearAuthResponse,
}) => {
  let params = useParams();

  // Import styles
  const mainStyle = mainStyles();
  const globalStyle = globalStyles();

  // Create local state
  const [loading, setLoading] = React.useState(true);
  const [successFail, setSuccessFail] = React.useState(false);
  const [redirect, setRedirect] = React.useState(false);
  const [resetName, setResetName] = React.useState("");

  const [resetPasswordToken, setResetPasswordToken] = React.useState(false);

  const [password, setPassword] = React.useState("");
  const [passwordError, setPasswordError] = React.useState(false);

  const [confirmPassword, setConfirmPassword] = React.useState("");
  const [confirmPasswordError, setConfirmPasswordError] = React.useState(false);

  const buttonClassname = clsx({
    [mainStyle.buttonFail]: successFail === "fail",
    [mainStyle.buttonSuccess]: successFail === "success",
  });

  React.useEffect(() => {
    if (authSuccess !== null) {
      if (authResult.origin === "resetPasswordCheck") {
        if (authSuccess === true) {
          setLoading(false);
          setResetName(authResult.name);
          clearAuthResponse();
        } else if (authSuccess === false) {
          setRedirect("/login");
          clearAuthResponse();
        }
      } else if (authResult.origin === "resetPasswordNew") {
        if (authSuccess === true) {
          setLoading(false);
          setSuccessFail("success");

          if (authResult.result === "2FA enabled") {
            // 2FA is enabled redirecting to 2FA screen
            setRedirect("/login/2FA");
          }
          clearAuthResponse();
        } else if (authSuccess === false) {
          setSuccessFail("fail");
          setLoading(false);

          if (authResult.error === "Invalid reset password token") {
            setRedirect("/login");
          } else if (
            authResult.error === "Password must be at least 6 characters"
          ) {
            setPasswordError("Password must be at least 6 characters");
          }

          clearAuthResponse();
        }
      }
    }
    // Prevent any useless errors with net line:
    // eslint-disable-next-line
  }, [authResult]);

  React.useEffect(() => {
    if (params.resetPasswordToken) {
      setResetPasswordToken(params.resetPasswordToken);
      resetPasswordCheck(params.resetPasswordToken);
    }
    // Prevent any useless errors with net line:
    // eslint-disable-next-line
  }, []);

  const onResetPassword = (e) => {
    e.preventDefault();
    setSuccessFail(false);
    setLoading(true);

    let error = false;
    if (password === "") {
      setPasswordError("Password is required");
      error = true;
    } else {
      if (password.length < 8 || password.length > 20) {
        setPasswordError(
          "Password must be at least 8 characters and max 20 characters"
        );
        error = true;
      } else {
        if (
          /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,20}$/.test(password) === false
        ) {
          setPasswordError(
            "Password must contain at least one number, one uppercase and one lowercase letter"
          );
          error = true;
        }
      }
    }

    if (confirmPassword === "") {
      setConfirmPasswordError("Confirm password is required");
      error = true;
    }

    if (!error) {
      if (password !== confirmPassword) {
        setPasswordError("Passwords must match");
        setConfirmPasswordError("Passwords must match");
        error = true;
      }
    }

    if (!error) {
      resetPasswordNew(resetPasswordToken, { password });
    } else {
      setSuccessFail("fail");
      setLoading(false);
    }
  };

  if (redirect) {
    return <Navigate to={redirect} />;
  }

  return (
    <Fragment>
      <TitleBox rootColor="rgba(156, 213, 212, 0.61)">New password</TitleBox>
      <Box className={mainStyle.container}>
        <Container disableGutters>
          <Grid container spacing={6} direction="row-reverse">
            <Grid item xs={12} sm={6} align="left">
              <Box className={mainStyle.imageContainer}>
                <Box className={mainStyle.imageBorder} />
                <Box className={`${globalStyle.imageFix} ${mainStyle.image}`}>
                  <img src={Image} style={{ width: "100%" }} alt="water" />
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} align="left">
              <Box align="left" maxWidth="367px">
                <form noValidate onSubmit={onResetPassword}>
                  <Box>
                    <Typography variant="h5">Welcome</Typography>
                    <Typography variant="h4">
                      {resetName ? resetName : "Loading"}
                    </Typography>
                  </Box>

                  <Box paddingTop="25px">
                    <Typography className={mainStyle.textFieldLabel}>
                      Password
                    </Typography>
                    <TextField
                      type="password"
                      variant="outlined"
                      spellCheck={false}
                      fullWidth
                      className={mainStyle.textFieldSmall}
                      value={password}
                      onChange={(e) => {
                        setPassword(e.target.value);
                        setPasswordError(false);
                        setSuccessFail(false);
                      }}
                      required
                      error={passwordError !== false}
                      helperText={passwordError}
                      disabled={loading}
                    />
                  </Box>
                  <Box paddingTop="25px">
                    <Typography className={mainStyle.textFieldLabel}>
                      Confirm password
                    </Typography>
                    <TextField
                      type="password"
                      variant="outlined"
                      spellCheck={false}
                      fullWidth
                      className={mainStyle.textFieldSmall}
                      value={confirmPassword}
                      onChange={(e) => {
                        setConfirmPassword(e.target.value);
                        setConfirmPasswordError(false);
                        setSuccessFail(false);
                      }}
                      required
                      error={confirmPasswordError !== false}
                      helperText={confirmPasswordError}
                      disabled={loading}
                    />
                  </Box>

                  <Box paddingTop="25px">
                    <LoadingButton
                      className={`${mainStyle.button} ${buttonClassname}`}
                      fullWidth
                      type="submit"
                      loading={loading}
                    >
                      Reset password
                    </LoadingButton>
                  </Box>
                </form>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </Fragment>
  );
};

// Set the state for this component to the global state
const mapStateToProps = (state) => ({
  auth: state.auth,
});

// Connect this component to the redux global state and bring in the state and needed functions
export default connect(mapStateToProps, {
  resetPasswordCheck,
  resetPasswordNew,
  clearAuthResponse,
})(ResetPasswordNew);

import "./app.css";
// Import React Modules
import React, { Fragment } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { CookiesProvider } from "react-cookie";

// Import Redux Modules
import { Provider } from "react-redux";
import reduxStore from "./reduxStore";

// Import Luxon Modules
import { Settings } from "luxon";

// Import Material-UI components
import { StylesProvider } from "@mui/styles";
import {
  StyledEngineProvider,
  CssBaseline,
  ThemeProvider,
  createTheme,
  IconButton,
} from "@mui/material";

// Import Material-UI Icons
import CloseIcon from "@mui/icons-material/Close";

// Import Material-UI Pickers
import { LocalizationProvider } from "@mui/lab";
import AdapterLuxon from "@mui/lab/AdapterLuxon";

// Import Notistack components
import { SnackbarProvider } from "notistack";

// Import website components
import RootLayout from "./RootLayout";

Settings.defaultLocale = "nl";
Settings.defaultZoneName = "Europe/Amsterdam";

// This is a fix for CSSBaseLine setting <body> with lineheight of typography>body1, We dont want this but we do need CSSBaseLine for the background so we create two themes and do the globalTheme>CSSBaseLine>LineHeightTheme
const globalTheme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
  palette: {
    primary: {
      main: "#9CD5D4",
    },
    secondary: {
      main: "#1976d2",
    },
    background: {
      default: "#F2F2F2",
    },
  },
  typography: {
    fontFamily: ["Open Sans"],
    body1: {
      fontSize: 15,
    },
  },
  mixins: {
    toolbar: {
      minHeight: "61.48px",
      "@media (min-width:0px) and (orientation: landscape)": {
        minHeight: "61.48px",
      },
      "@media (min-width:600px)": {
        minHeight: "112.175px",
      },
    },
  },
});

const lineHeightTheme = createTheme({
  palette: {
    primary: {
      main: "#9CD5D4",
    },
    secondary: {
      main: "#1976d2",
    },
    background: {
      default: "#F2F2F2",
    },
  },
  typography: {
    fontFamily: ["Open Sans"],
    body1: {
      fontSize: 15,
      lineHeight: "20px",
    },
  },
  mixins: {
    toolbar: {
      minHeight: "61.48px",
      "@media (min-width:0px) and (orientation: landscape)": {
        minHeight: "61.48px",
      },
      "@media (min-width:600px)": {
        minHeight: "112.175px",
      },
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
});

// Create the website
const App = () => {
  const notistackRef = React.createRef();
  const onClickDismiss = (key) => () => {
    notistackRef.current.closeSnackbar(key);
  };
  return (
    <Fragment>
      <StyledEngineProvider injectFirst>
        <StylesProvider>
          <ThemeProvider theme={globalTheme}>
            <CssBaseline />
            <ThemeProvider theme={lineHeightTheme}>
              <Provider store={reduxStore}>
                <LocalizationProvider dateAdapter={AdapterLuxon} locale="nl">
                  <CookiesProvider>
                    <SnackbarProvider
                      ref={notistackRef}
                      action={(key) => (
                        <IconButton onClick={onClickDismiss(key)}>
                          <CloseIcon
                            fontSize="small"
                            style={{ color: "#ffffff" }}
                          />
                        </IconButton>
                      )}
                    >
                      <Router>
                        <RootLayout />
                      </Router>
                    </SnackbarProvider>
                  </CookiesProvider>
                </LocalizationProvider>
              </Provider>
            </ThemeProvider>
          </ThemeProvider>
        </StylesProvider>
      </StyledEngineProvider>
    </Fragment>
  );
};

export default App;

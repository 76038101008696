// Import the types
import {
  SET_APP_RESPONSE,
  CLEAR_APP_RESPONSE,
  SET_APP_LOADING_TRUE,
  SET_APP_LOADING_FALSE,
  SET_APP_ERROR,
  SET_SNACKBAR_NOTISTACK,
  REMOVE_SNACKBAR_NOTISTACK,
  RESET_ALL,
} from "../types";

// Create the initial state
const initialState = {
  loadingApp: false,
  appSuccess: null,
  appResult: null,
  appError: null,
  snackbarsNotistack: [],
};

// Export the new new state or updated state
const appReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_APP_RESPONSE:
      return {
        ...state,
        appSuccess: action.payload.success,
        appResult: action.payload.data,
      };
    case CLEAR_APP_RESPONSE:
      return {
        ...state,
        appSuccess: null,
        appResult: null,
      };
    case SET_APP_LOADING_TRUE:
      return {
        ...state,
        loadingApp: true,
      };
    case SET_APP_LOADING_FALSE:
      return {
        ...state,
        loadingApp: false,
      };
    case SET_APP_ERROR:
      return {
        ...state,
        appError: action.payload,
        loadingApp: false,
      };
    case SET_SNACKBAR_NOTISTACK:
      return {
        ...state,
        snackbarsNotistack: [...state.snackbarsNotistack, action.payload],
      };
    case REMOVE_SNACKBAR_NOTISTACK:
      return {
        ...state,
        snackbarsNotistack: state.snackbarsNotistack.filter(
          (snackbarNotistack) => snackbarNotistack.key !== action.payload.key
        ),
      };
    case RESET_ALL:
      return {
        ...state,
        loadingApp: false,
        appSuccess: null,
        appResult: null,
        appError: null,
        snackbarsNotistack: [],
      };
    default:
      return state;
  }
};

export default appReducer;

// Import the types
import {
  SET_WATERFIETS_RESPONSE,
  CLEAR_WATERFIETS_RESPONSE,
  SET_WATERFIETS_LOADING_TRUE,
  SET_WATERFIETS_LOADING_FALSE,
  SET_WATERFIETS_ERROR,
  WATERFIETS_CLIENTS_LOADED,
} from "../types";

// Import required modules
import axios from "axios";

// Import functions from other states
import { setSnackbar } from "../appState/appActions";
import { invalidLoginToken, resetAll } from "../authState/authActions";

// Create the json config
const config = {
  headers: {
    "Content-Type": "application/json",
  },
};

export const getWaterFietsClients = () => async (dispatch) => {
  dispatch(setLoadingTrue());
  const origin = "getWaterFietsClients";

  try {
    const response = await axios.get("/api/wf-system/getClients");

    // Save clients
    dispatch({
      type: WATERFIETS_CLIENTS_LOADED,
      payload: response.data.data,
    });
  } catch (err) {
    dispatch(waterFietsError(err, origin));
  }
};

export const getOneUseLoginJWT = (formData) => async (dispatch) => {
  dispatch(setLoadingTrue());
  const origin = "getOneUseLoginJWT";

  try {
    const response = await axios.post(
      "/api/wf-system/getOneUseLoginJWT",
      formData,
      config
    );

    // Set waterfiets response
    response.data.data["origin"] = origin;
    dispatch({
      type: SET_WATERFIETS_RESPONSE,
      payload: response.data,
    });

    // Set loading to false
    dispatch(setLoadingFalse());
  } catch (err) {
    dispatch(waterFietsError(err, origin));
  }
};

// Set loading to true
export const setLoadingTrue = () => async (dispatch) => {
  dispatch({ type: SET_WATERFIETS_LOADING_TRUE });
};

// Set loading to false
export const setLoadingFalse = () => async (dispatch) => {
  dispatch({ type: SET_WATERFIETS_LOADING_FALSE });
};

// Clear waterfiets response
export const clearWaterFietsResponse = () => async (dispatch) => {
  dispatch({ type: CLEAR_WATERFIETS_RESPONSE });
};

// WaterFiets error
export const waterFietsError = (err, origin) => async (dispatch) => {
  // Check if we got the correct response from the backend
  if (err && err.response && err.response.data && err.response.data.data) {
    // We did so lets check what went wrong on the backend
    let error = err.response.data.data.error;

    if (error === "Invalid login JWT") {
      // It was a invalid token (meaning the JWT was used elsewhere)
      dispatch(invalidLoginToken());
    } else if (error === "Not authorized to access this route") {
      // We have invalid JWT for some reason
      dispatch(resetAll());
    } else {
      // We have not handled this error show snackbar
      dispatch(setSnackbar(`${error}`, "error"));
      console.error("Unhandled waterfiets error: ", error);
    }

    // No matter what happend we will set the response and error
    // Set waterfiets Error
    dispatch({ type: SET_WATERFIETS_ERROR, payload: error });

    // Set WaterFiets reponse
    err.response.data.data["origin"] = origin;
    dispatch({
      type: SET_WATERFIETS_RESPONSE,
      payload: err.response.data,
    });
  } else {
    // The request didnt even get to the backend set error and show snackbar
    dispatch({ type: SET_WATERFIETS_ERROR, payload: err });

    // Set Waterfiets response
    let data = {
      success: false,
      data: { origin: origin, error: "Internal server error" },
    };
    dispatch({
      type: SET_WATERFIETS_RESPONSE,
      payload: data,
    });

    // Show snackbar for dev
    dispatch(setSnackbar(`${err}`, "error"));
    console.error("Unhandled waterfiets error: ", err);
  }
};

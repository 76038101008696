// Import React Modules
import React, { Fragment } from "react";
import { Link as RouterLink } from "react-router-dom";

// Import the Redux modules
import { connect } from "react-redux";

// Import Material-UI Components
import { makeStyles } from "@mui/styles";
import { Box, Typography, Button, Link, Grid } from "@mui/material";

import Footer_1 from "../../images/Design/Footer_1.svg";
import Footer_2 from "../../images/Design/Footer_2.svg";
import SBB from "../../images/Design/sbb-beeldmerk.webp";

// Create style
const useStyles = makeStyles((theme) => ({
  footer: {
    bottom: 0,
    position: "absolute",
    width: "100%",
  },
  footerContainer: {
    position: "relative",
    textAlign: "center",
  },
  footerImage: {
    fontSize: 0,
  },
  footerTextLocation: {
    position: "absolute",
    top: "45%",
    left: "50%",
    transform: "translate(-50%, -45%)",
    [theme.breakpoints.down("sm")]: {
      top: "25%",
      transform: "translate(-50%, -25%)",
    },
    [theme.breakpoints.up("sm")]: {
      top: "45%",
      transform: "translate(-50%, -45%)",
    },
  },
  footerText: {
    color: "#F2F2F2",
    fontSize: "15px",
    lineHeight: "20px",
  },
  footerButtonLocation: {
    position: "absolute",
    top: "90%",
    left: "50%",
    transform: "translate(-50%, -90%)",
  },
  footerButton: {
    textTransform: "none",
    height: "22.39px",
    borderRadius: "8px",
    backgroundColor: "#29ABE2",
    color: "#F2F2F2",
    "&:hover": {
      backgroundColor: "#1D6E8F",
    },
  },
  sbbLocation: {
    position: "absolute",
    top: "97%",
    left: "3%",
    transform: "translate(-3%, -97%)",
  },
  footerPrivacyLocation: {
    position: "absolute",
    top: "97%",
    left: "97%",
    transform: "translate(-97%, -97%)",
  },
  footerPrivacyLink: {
    fontSize: "15px",
    lineHeight: "20px",
    fontWeight: "400",
    color: "#F2F2F2",
  },
  footer_1: {
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      height: "275px",
    },
    [theme.breakpoints.up("sm")]: {
      height: "150px",
    },
  },
  footer_2: {
    width: "100%",
    height: "75px",
    [theme.breakpoints.down("sm")]: {
      height: "auto",
      width: "100%",
    },
    [theme.breakpoints.up("sm")]: {
      height: "75px",
    },
  },
  imageFix: {
    fontSize: 0,
  },
  image: {
    width: "20%",
  },
}));

const Footer = ({ lang: { language } }) => {
  // Import style
  const classes = useStyles();

  return (
    <Fragment>
      <Box>
        <Box className={classes.footerContainer}>
          <Box className={classes.footerImage}>
            <img src={Footer_2} alt="Footer_2" className={classes.footer_2} />

            <img src={Footer_1} alt="Footer_1" className={classes.footer_1} />
          </Box>
          <Box className={classes.footerTextLocation}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <Typography className={classes.footerText}>
                  {language.global.companyName}
                </Typography>
                {language.global.companyPhone.map((item) => (
                  <Typography className={classes.footerText} key={item}>
                    {item}
                  </Typography>
                ))}
                <Typography className={classes.footerText}>
                  {language.global.companyKVK} <br />
                  {language.global.companyBTW}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography className={classes.footerText}>
                  {language.global.companyAddress} <br />
                  {language.global.companyPostcode} <br />{" "}
                  {language.global.companyCity} <br />
                  {language.global.companyEmail}
                </Typography>
              </Grid>
            </Grid>
          </Box>
          <Box className={classes.sbbLocation}>
            <Box className={`${classes.imageFix} ${classes.image}`}>
              <img src={SBB} style={{ width: "100%" }} alt="SBB Keurmerk" />
            </Box>
          </Box>
          <Box className={classes.footerButtonLocation}>
            <Button
              className={classes.footerButton}
              component={RouterLink}
              to="/contact"
              variant="contained"
            >
              {language.global.contact}
            </Button>
          </Box>
          <Box className={classes.footerPrivacyLocation}>
            <Link
              component={RouterLink}
              to="/privacystatement"
              className={classes.footerPrivacyLink}
            >
              {language.global.privacy}
            </Link>
          </Box>
        </Box>
      </Box>
    </Fragment>
  );
};

// Set the state for this component to the global state
const mapStateToProps = (state) => ({
  lang: state.lang,
});

export default connect(mapStateToProps, null)(Footer);

// Import the React modules
import React, { Fragment } from "react";
import { Link as RouterLink } from "react-router-dom";

// Import the Redux modules
import { connect } from "react-redux";

// Import Mui components
import { makeStyles } from "@mui/styles";
import { Grid, Typography, Box, Link, Container } from "@mui/material";

// Import images
import Opleiding_1 from "images/Design/Opleiding_1.webp";
import Inhuur_1 from "images/Design/Inhuur_1.webp";
import Otter from "images/Design/Otter.webp";

const useStyles = makeStyles((theme) => ({
  imageFix: {
    fontSize: 0,
  },
  flexRight: {
    display: "flex",
    justifyContent: "flex-end",
  },
  root: {
    marginTop: "73.49px",
    marginLeft: "4.86%",
    marginRight: "4.86%",
    [theme.breakpoints.down("sm")]: {
      marginLeft: "15px",
      marginRight: "15px",
    },
    paddingBottom: "60px",
  },
  cardRoot: {
    [theme.breakpoints.down("sm")]: {
      maxWidth: "500px",
    },
    [theme.breakpoints.up("sm")]: {
      maxWidth: "1000px",
    },
  },
  cardImageRoot: {
    position: "relative",
  },
  cardImageContent: {
    marginTop: "37.68px",
    marginRight: "36.64px",
  },
  cardImageShadow1: {
    boxShadow: "36.64px -37.68px rgba(41,171,226, 0.61)",
  },
  cardImageShadow2: {
    boxShadow: "36.64px -37.68px rgba(241,90,36, 0.61)",
  },
  cardTitleRoot: {
    position: "absolute",
    bottom: "10px",
    left: "8.08px",
    zIndex: 1,
  },
  cardTitleContent: {
    color: "#FFFFFF",
    fontSize: "30px",
    lineHeight: "41px",
  },
  cardContentRoot: {
    marginTop: "16.8px",
  },
  cardContentLink: {
    fontSize: "17px",
    lineHeight: "23px",
  },
}));

const Content = ({ lang: { language } }) => {
  // Import style
  const classes = useStyles();

  return (
    <Fragment>
      <Box className={classes.root}>
        <Container disableGutters>
          <Grid container spacing={10} justifyContent="center">
            <Grid item xs={12} sm={6} md={4} className={classes.cardRoot}>
              <Box className={classes.cardImageRoot}>
                <Box
                  className={`${classes.imageFix} ${classes.cardImageContent} ${classes.cardImageShadow1}`}
                >
                  <img
                    src={Opleiding_1}
                    alt="Opleiding_1"
                    style={{ width: "100%" }}
                  />
                </Box>
                <Box className={classes.cardTitleRoot}>
                  <Typography
                    variant="h4"
                    color="inherit"
                    className={classes.cardTitleContent}
                  >
                    {language.home.card1.title}
                  </Typography>
                </Box>
              </Box>
              <Box className={classes.cardContentRoot}>
                <Box>
                  <Link
                    component={RouterLink}
                    to="/opleiding/zweminstructeurabc"
                    className={classes.cardContentLink}
                    style={{ color: "#000000", padding: "0px" }}
                  >
                    {`> ${language.home.card1.link1}`}
                  </Link>
                </Box>
                <Box>
                  <Link
                    component={RouterLink}
                    to="/opleiding/algehelelifeguardopleiding"
                    className={classes.cardContentLink}
                    style={{ color: "#000000", padding: "0px" }}
                  >
                    {`> ${language.home.card1.link2}`}
                  </Link>
                </Box>

                <Box>
                  <Link
                    component={RouterLink}
                    to="/opleiding/zrzopleiding"
                    className={classes.cardContentLink}
                    style={{ color: "#000000", padding: "0px" }}
                  >
                    {`> ${language.home.card1.link3}`}
                  </Link>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={4} className={classes.cardRoot}>
              <Box className={classes.cardImageRoot}>
                <Box
                  className={`${classes.imageFix} ${classes.cardImageContent}`}
                >
                  <img src={Otter} alt="Otter" style={{ width: "100%" }} />
                </Box>
              </Box>
              <Box className={classes.cardContentRoot}>
                <Box className={classes.flexRight}>
                  <Link
                    underline="none"
                    className={`${classes.cardContentLink}`}
                    style={{ color: "#999999" }}
                  >
                    [Coming Soon]
                  </Link>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={4} className={classes.cardRoot}>
              <Box className={classes.cardImageRoot}>
                <Box
                  className={`${classes.imageFix} ${classes.cardImageContent} ${classes.cardImageShadow2}`}
                >
                  <img
                    src={Inhuur_1}
                    alt="Inhuur_1"
                    style={{ width: "100%" }}
                  />
                </Box>
                <Box className={classes.cardTitleRoot}>
                  <Typography
                    variant="h4"
                    color="inherit"
                    className={classes.cardTitleContent}
                  >
                    {language.home.card3.title}
                  </Typography>
                </Box>
              </Box>
              <Box className={classes.cardContentRoot}>
                <Box>
                  {language.home.card3.content.map((item) => (
                    <Typography key={item}>{item}</Typography>
                  ))}
                </Box>

                <Box sx={{ paddingTop: "10px" }} className={classes.flexRight}>
                  <Link
                    component={RouterLink}
                    to="/inhuur"
                    underline="none"
                    className={classes.cardContentLink}
                    style={{ color: "#29ABE2" }}
                  >
                    {`${language.home.card3.link} >>`}
                  </Link>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </Fragment>
  );
};

// Set the state for this component to the global state
const mapStateToProps = (state) => ({
  lang: state.lang,
});

export default connect(mapStateToProps, null)(Content);

// Import the types
import {
  SET_LANG_LOADING_TRUE,
  SET_LANG_LOADING_FALSE,
  SET_LANG_ERROR,
  GET_LANG,
} from "../types";

// Import required modules
import axios from "axios";

// Get language from backend
export const getLang = (id) => async (dispatch) => {
  dispatch(setLoadingTrue());
  const origin = "getLang";

  try {
    let languageData = {};

    // Get the dropdowndata (only nativename and countrycode)
    const dropDownData = await axios.get(
      "/api/language?select=nativeName,countryCode"
    );

    let singleLanguage;
    // Do we have a language ID
    if (!id) {
      // We dont so we get the language in the cookie or the dutch language
      singleLanguage = await axios.get("/api/language/single");
    } else {
      // We do so lets get that language
      singleLanguage = await axios.get(`/api/language/${id}`);
    }

    languageData = singleLanguage.data.data;

    // Add the dropdowndat to language
    languageData.navbar.languageDropDownContent = dropDownData.data.data;

    // Dispatch lang
    dispatch({
      type: GET_LANG,
      payload: languageData,
    });
  } catch (err) {
    dispatch(langError(err, origin));
  }
};

// Set loading to true
export const setLoadingTrue = () => async (dispatch) => {
  dispatch({ type: SET_LANG_LOADING_TRUE });
};

// Set loading to false
export const setLoadingFalse = () => async (dispatch) => {
  dispatch({ type: SET_LANG_LOADING_FALSE });
};

// Lang error
export const langError = (err, origin) => async (dispatch) => {
  // Check if we got the correct response from the backend
  if (err && err.response && err.response.data && err.response.data.data) {
    // We did so lets check what went wrong on the backend
    let error = err.response.data.data.error;

    // Set lang error
    dispatch({
      type: SET_LANG_ERROR,
      payload: error,
    });
  } else {
    // The request didnt even get to the backend set error
    // Set lang error
    dispatch({
      type: SET_LANG_ERROR,
      payload: err,
    });
  }
};

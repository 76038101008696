// Import the React modules
import React, { Fragment } from "react";
import { Route, Routes } from "react-router-dom";

// Import Redux Modules
import { connect } from "react-redux";

// Import Material-UI Components
import { Box, CircularProgress } from "@mui/material";
import { makeStyles } from "@mui/styles";

// Import website components
import PDNE from "components/PageDoesNotExist/PageDoesNotExist";

import DashBoard from "./Components/DashBoard/DashBoard";

const useStyles = makeStyles((theme) => ({
  flexCenter: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
  },
}));
const DashBoardLayout = ({ user: { loggedInUser } }) => {
  // Import style
  const classes = useStyles();

  // Show preloader weh loading user
  if (!loggedInUser) {
    return (
      <Box sx={{ paddingTop: "32px" }} className={classes.flexCenter}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Fragment>
      <Routes>
        <Route path="/" element={<DashBoard />} />
        <Route path="*" element={<PDNE />} />
      </Routes>
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps, null)(DashBoardLayout);

// Import the React modules
import React, { Fragment } from "react";
import clsx from "clsx";

// Import the Redux modules
import { connect } from "react-redux";
import {
  editLoggedInUser,
  clearUserResponse,
} from "reduxStates/userState/userActions";

// Import Material-UI Components
import { Typography, Divider, Grid, TextField, Button } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { LoadingButton } from "@mui/lab";

// Import Material-UI Colors
import { red, green } from "@mui/material/colors";

import { usePrompt } from "components/usePrompt/usePrompt";

// Import page components
import SecurityDialog from "../SecurityDialog/SecurityDialog";

const useStyles = makeStyles((theme) => ({
  buttonFail: {
    backgroundColor: red[500],
    opacity: "1",
    "&:hover": {
      backgroundColor: red[700],
    },
  },
  buttonSuccess: {
    backgroundColor: green[500],
    opacity: "1",
    "&:hover": {
      backgroundColor: green[700],
    },
  },
  divider: {
    marginTop: "8px",
    marginBottom: "12px",
  },
}));

const EditAccount = ({
  user: { loggedInUser, userSuccess, userResult },
  editLoggedInUser,
  clearUserResponse,
}) => {
  // Import style
  const classes = useStyles();

  // // Create local state
  const [loading, setLoading] = React.useState(false);
  const [loadingSecurity, setLoadingSecurity] = React.useState(false);
  const [successFail, setSuccessFail] = React.useState(false);
  const [isBlocking, setIsBlocking] = React.useState(false);

  // First name
  const [editFirstName, setEditFirstName] = React.useState(
    loggedInUser.firstName
  );
  const [editFirstNameError, setEditFirstNameError] = React.useState(false);

  // Middle name
  const [editMiddleName, setEditMiddleName] = React.useState(
    loggedInUser.middleName
  );
  const [editMiddleNameError, setEditMiddleNameError] = React.useState(false);

  // Last name
  const [editLastName, setEditLastName] = React.useState(loggedInUser.lastName);
  const [editLastNameError, setEditLastNameError] = React.useState(false);

  // Email
  const [editEmail, setEditEmail] = React.useState(loggedInUser.email);
  const [editEmailError, setEditEmailError] = React.useState(false);

  // Security dialog
  const [securityDialogOpen, setSecurityDialogOpen] = React.useState(false);

  // Security code
  const [securityCode, setSecurityCode] = React.useState("");
  const [securityCodeError, setSecurityCodeError] = React.useState(false);

  // Red or green button class
  const buttonClassname = clsx({
    [classes.buttonFail]: successFail === "fail",
    [classes.buttonSuccess]: successFail === "success",
  });

  // // UseEffect functions
  // Set the is blocking when a value is changed
  React.useEffect(() => {
    if (
      editFirstName !== loggedInUser.firstName ||
      editMiddleName !== loggedInUser.middleName ||
      editLastName !== loggedInUser.lastName ||
      editEmail !== loggedInUser.email
    ) {
      setIsBlocking(true);
    } else {
      setIsBlocking(false);
    }
  }, [editFirstName, editMiddleName, editLastName, editEmail, loggedInUser]);

  // Set the appropiate errors/success with the result from backend
  React.useEffect(() => {
    if (userSuccess !== null) {
      if (userResult.origin === "editLoggedInUser") {
        if (userSuccess === true) {
          setLoading(false);
          setLoadingSecurity(false);
          setSuccessFail(false);
          setSecurityDialogOpen(false);
          setSecurityCode("");

          clearUserResponse();
        } else if (userSuccess === false) {
          setLoading(false);
          setLoadingSecurity(false);
          setSuccessFail("fail");

          if (userResult.error === "Duplicate field value entered") {
            setEditEmailError("User with this email already exists");
            setSecurityDialogOpen(false);
            setSecurityCode("");
            setSecurityCodeError(false);
          }

          if (userResult.error === "Invalid password") {
            setSecurityCodeError("Invalid password");
          } else if (userResult.error === "Invalid OTP code") {
            setSecurityCodeError("Invalid 2FA code");
          }

          clearUserResponse();
        }
      }
    }
    // Prevent any useless errors with net line:
    // eslint-disable-next-line
  }, [userResult]);

  // // Button functions
  // Submit the changes to backend
  const onSubmit = (e) => {
    e.preventDefault();
    setSuccessFail(false);
    setLoading(true);

    let error = false;
    if (editFirstName === "") {
      setEditFirstNameError("First name is required");
      error = true;
    }

    if (editLastName === "") {
      setEditLastNameError("Last name is required");
      error = true;
    }

    if (editEmail === "") {
      setEditEmailError("Email is required");
      error = true;
    } else if (
      !/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        editEmail
      )
    ) {
      setEditEmailError("Invalid email");
      error = true;
    }

    if (!error) {
      // Check if we changed email if so we need to provide 2FA key or password
      if (editEmail !== loggedInUser.email) {
        setSecurityDialogOpen(true);
      } else {
        // Save changes to backend
        let formData = {};

        if (editFirstName !== loggedInUser.firstName) {
          formData.editFirstName = editFirstName;
        }
        if (editMiddleName !== loggedInUser.middleName) {
          formData.editMiddleName = editMiddleName;
        }
        if (editLastName !== loggedInUser.lastName) {
          formData.editLastName = editLastName;
        }
        editLoggedInUser(formData);
      }
    } else {
      setSuccessFail("fail");
      setLoading(false);
    }
  };

  // Submit the changes to backend if email has been changed (we need to provide password of 2FA code)
  const onSubmitSecurity = () => {
    setLoadingSecurity(true);

    let error = false;
    if (securityCode === "") {
      if (loggedInUser.TwoFactorAuthEnabled) {
        setSecurityCodeError("2FA code is required");
      } else {
        setSecurityCodeError("Password is required");
      }
      error = true;
    } else {
      if (loggedInUser.TwoFactorAuthEnabled) {
        if (securityCode.length !== 6) {
          setSecurityCodeError("2FA code needs to be 6 numbers");
          error = true;
        }
      }
    }

    if (!error) {
      let formData = {};

      if (editFirstName !== loggedInUser.firstName) {
        formData.editFirstName = editFirstName;
      }
      if (editMiddleName !== loggedInUser.middleName) {
        formData.editMiddleName = editMiddleName;
      }
      if (editLastName !== loggedInUser.lastName) {
        formData.editLastName = editLastName;
      }
      if (editEmail !== loggedInUser.email) {
        formData.editEmail = editEmail;
      }

      if (loggedInUser.TwoFactorAuthEnabled) {
        formData.otpToken = securityCode;
      } else {
        formData.password = securityCode;
      }

      editLoggedInUser(formData);
    } else {
      setSuccessFail("fail");
      setLoadingSecurity(false);
    }
  };

  // Cancel security (close dialog and forget code)
  const onCancelSecurity = () => {
    setSecurityDialogOpen(false);
    setSecurityCode("");
    setSecurityCodeError(false);
    setLoading(false);
    setSuccessFail(false);
  };

  // Reset changes
  const onReset = () => {
    setEditFirstName(loggedInUser.firstName);
    setEditFirstNameError(false);
    setEditMiddleName(loggedInUser.middleName);
    setEditMiddleNameError(false);
    setEditLastName(loggedInUser.lastName);
    setEditLastNameError(false);
    setEditEmail(loggedInUser.email);
    setEditEmailError(false);
    setSuccessFail(false);
  };

  // // Change functions
  // First name
  const onFirstNameChange = (e) => {
    setEditFirstName(e.target.value);
    setEditFirstNameError(false);
    setSuccessFail(false);
  };

  // Middle name
  const onMiddleNameChange = (e) => {
    setEditMiddleName(e.target.value);
    setEditMiddleNameError(false);
    setSuccessFail(false);
  };

  // Last name
  const onLastNameChange = (e) => {
    setEditLastName(e.target.value);
    setEditLastNameError(false);
    setSuccessFail(false);
  };

  // Email
  const onEmailChange = (e) => {
    setEditEmail(e.target.value);
    if (
      e.target.value !== "" &&
      !/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        e.target.value
      )
    ) {
      setEditEmailError("Invalid email");
    } else {
      setEditEmailError(false);
    }
    setSuccessFail(false);
  };

  // Security password or 2fa change
  const onSecurityCodeChange = (e) => {
    setSecurityCode(e.target.value);
    setSecurityCodeError(false);
    setSuccessFail(false);
  };

  usePrompt(
    "Some values have been changed, are you sure you want to leave this page? This will reset all values!",
    isBlocking
  );

  // // Return the page
  return (
    <Fragment>
      <Typography variant="h4">Edit account</Typography>
      <Divider className={classes.divider} />
      {loggedInUser.isSwimTechNLUser ? (
        <Typography variant="h5">
          SwimTechNL users need to edit their account on SwimTechNL.nl
        </Typography>
      ) : (
        <Fragment>
          <form noValidate onSubmit={onSubmit}>
            <Grid container spacing={2}>
              {/* First name */}
              <Grid item xs={12}>
                <TextField
                  type="text"
                  label="First name"
                  variant="outlined"
                  value={editFirstName}
                  onChange={onFirstNameChange}
                  error={editFirstNameError !== false}
                  helperText={editFirstNameError}
                  disabled={loading}
                  required
                  fullWidth
                />
              </Grid>
              {/* Middle name */}
              <Grid item xs={12}>
                <TextField
                  type="text"
                  label="Middle name"
                  variant="outlined"
                  value={editMiddleName}
                  onChange={onMiddleNameChange}
                  error={editMiddleNameError !== false}
                  helperText={editMiddleNameError}
                  disabled={loading}
                  fullWidth
                />
              </Grid>
              {/* Last name */}
              <Grid item xs={12}>
                <TextField
                  type="text"
                  label="Last name"
                  variant="outlined"
                  value={editLastName}
                  onChange={onLastNameChange}
                  error={editLastNameError !== false}
                  helperText={editLastNameError}
                  disabled={loading}
                  required
                  fullWidth
                />
              </Grid>
              {/* Email */}
              <Grid item xs={12}>
                <TextField
                  type="email"
                  label="Email"
                  variant="outlined"
                  spellCheck={false}
                  value={editEmail}
                  onChange={onEmailChange}
                  error={editEmailError !== false}
                  helperText={editEmailError}
                  disabled={loading}
                  required
                  fullWidth
                />
              </Grid>
            </Grid>
            <Grid
              container
              spacing={2}
              justify="flex-end"
              style={{ paddingTop: "16px" }}
            >
              {/* Reset button */}
              <Grid item>
                <Button
                  variant="contained"
                  color="error"
                  type="button"
                  onClick={onReset}
                  disabled={!isBlocking || loading}
                >
                  Reset changes
                </Button>
              </Grid>
              {/* Save button */}
              <Grid item>
                <LoadingButton
                  variant="contained"
                  color="primary"
                  type="submit"
                  className={buttonClassname}
                  loading={loading}
                  disabled={!isBlocking}
                >
                  Save changes
                </LoadingButton>
              </Grid>
            </Grid>
          </form>
        </Fragment>
      )}
      {/* Security dialog */}
      <SecurityDialog
        securityDialogOpen={securityDialogOpen}
        securityCode={securityCode}
        securityCodeError={securityCodeError}
        onSecurityCodeChange={onSecurityCodeChange}
        loadingSecurity={loadingSecurity}
        onCancelSecurity={onCancelSecurity}
        onSubmitSecurity={onSubmitSecurity}
        buttonClassname={buttonClassname}
      />
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps, {
  editLoggedInUser,
  clearUserResponse,
})(EditAccount);

// Import the React modules
import React, { Fragment } from "react";

// Import the Redux modules
import { connect } from "react-redux";

// Import Material-UI Components
import {
  Divider,
  Grid,
  TextField,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  InputAdornment,
  DialogActions,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";

// Import Material-UI Icons
import VpnKey from "@mui/icons-material/VpnKey";

const SecurityDialog = ({
  user: { loggedInUser },
  securityDialogOpen,
  securityCode,
  securityCodeError,
  onSecurityCodeChange,
  loadingSecurity,
  onCancelSecurity,
  onSubmitSecurity,
  buttonClassname,
}) => {
  return (
    <Fragment>
      <Dialog open={securityDialogOpen} maxWidth="sm" fullWidth>
        <DialogTitle>
          {loggedInUser.TwoFactorAuthEnabled
            ? "Please provide 2FA code"
            : "Please provide password"}
        </DialogTitle>
        <Divider />
        <DialogContent>
          {loggedInUser.TwoFactorAuthEnabled ? (
            // Otp code
            <TextField
              type="text"
              label="2FA Code"
              variant="outlined"
              spellCheck={false}
              value={securityCode}
              onChange={onSecurityCodeChange}
              error={securityCodeError !== false}
              helperText={securityCodeError}
              disabled={loadingSecurity}
              required
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <VpnKey />
                  </InputAdornment>
                ),
              }}
            />
          ) : (
            // Password
            <TextField
              type="password"
              label="Password"
              variant="outlined"
              spellCheck={false}
              value={securityCode}
              onChange={onSecurityCodeChange}
              error={securityCodeError !== false}
              helperText={securityCodeError}
              disabled={loadingSecurity}
              required
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <VpnKey />
                  </InputAdornment>
                ),
              }}
            />
          )}
        </DialogContent>
        <Divider />
        <DialogActions>
          <Grid container spacing={2} justifyContent="flex-end">
            {/* Cancel button */}
            <Grid item xs={4}>
              <Button
                variant="contained"
                color="error"
                type="submit"
                onClick={onCancelSecurity}
                disabled={loadingSecurity}
                fullWidth
              >
                Cancel change
              </Button>
            </Grid>
            {/* Save button */}
            <Grid item xs={4}>
              <LoadingButton
                variant="contained"
                color="primary"
                type="submit"
                onClick={onSubmitSecurity}
                className={buttonClassname}
                loading={loadingSecurity}
                fullWidth
              >
                Confirm change
              </LoadingButton>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps, null)(SecurityDialog);

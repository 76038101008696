// Import the React modules
import React, { Fragment } from "react";
import clsx from "clsx";

// Import the Redux modules
import { connect } from "react-redux";
import { editUser, clearUserResponse } from "reduxStates/userState/userActions";

// Import Material-UI Components
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  TextField,
  Checkbox,
  Button,
  DialogActions,
  MenuItem,
  ListItemText,
  Divider,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { LoadingButton } from "@mui/lab";

// Import Material-UI Colors
import { red, green } from "@mui/material/colors";

import { usePrompt } from "components/usePrompt/usePrompt";

const useStyles = makeStyles((theme) => ({
  buttonFail: {
    backgroundColor: `${red[500]} !important`,
    opacity: "1",
    "&:hover": {
      backgroundColor: `${red[700]} !important`,
    },
  },
  buttonSuccess: {
    backgroundColor: `${green[500]} !important`,
    opacity: "1",
    "&:hover": {
      backgroundColor: `${green[700]} !important`,
    },
  },
}));

const EditDialog = ({
  user: { loadingUser, userSuccess, userResult, loggedInUser },
  editDialogOpen,
  closeEditDialog,
  single,
  editUser,
  clearUserResponse,
}) => {
  // Import style
  const classes = useStyles();

  // // Create local state
  const [loading, setLoading] = React.useState(false);
  const [successFail, setSuccessFail] = React.useState(false);
  const [isBlocking, setIsBlocking] = React.useState(false);

  // First name
  const [editFirstName, setEditFirstName] = React.useState(single.firstName);
  const [editFirstNameError, setEditFirstNameError] = React.useState(false);

  // Middle name
  const [editMiddleName, setEditMiddleName] = React.useState(single.middleName);
  const [editMiddleNameError, setEditMiddleNameError] = React.useState(false);

  // Last name
  const [editLastName, setEditLastName] = React.useState(single.lastName);
  const [editLastNameError, setEditLastNameError] = React.useState(false);

  // Email
  const [editEmail, setEditEmail] = React.useState(single.email);
  const [editEmailError, setEditEmailError] = React.useState(false);

  // Roles
  const [editRoles, setEditRoles] = React.useState(single.roles);
  const [editRolesError, setEditRolesError] = React.useState(false);

  // Red or green button class
  const buttonClassname = clsx({
    [classes.buttonFail]: successFail === "fail",
    [classes.buttonSuccess]: successFail === "success",
  });

  // // UseEffect functions
  // Set the is blocking when a value is changed
  React.useEffect(() => {
    if (
      editFirstName !== single.firstName ||
      editMiddleName !== single.middleName ||
      editLastName !== single.lastName ||
      editEmail !== single.email
    ) {
      setIsBlocking(true);
    } else {
      setIsBlocking(false);
    }
  }, [editFirstName, editMiddleName, editLastName, editEmail, single]);

  // Set the appropiate errors/success with the result from backend
  React.useEffect(() => {
    if (userSuccess !== null) {
      if (userResult.origin === `editUser ID: ${single._id}`) {
        if (userSuccess === true) {
          setLoading(false);
          setSuccessFail(false);
          setIsBlocking(false);

          closeEditDialog();

          clearUserResponse();
        } else if (userSuccess === false) {
          setLoading(false);
          setSuccessFail("fail");

          if (userResult.error === "Duplicate field value entered") {
            setEditEmailError("User with this email already exists");
          }

          clearUserResponse();
        }
      }
    }
    // Prevent any useless errors with net line:
    // eslint-disable-next-line
  }, [userResult]);

  // // Button functions
  // Submit the changes to backend
  const onSubmit = () => {
    setSuccessFail(false);
    setLoading(true);

    let error = false;
    if (editFirstName === "") {
      setEditFirstNameError("First name is required");
      error = true;
    }

    if (editLastName === "") {
      setEditLastNameError("Last name is required");
      error = true;
    }

    if (editEmail === "") {
      setEditEmailError("Email is required");
      error = true;
    } else if (
      !/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        editEmail
      )
    ) {
      setEditEmailError("Invalid email");
      error = true;
    }

    if (editRoles.length === 0) {
      setEditRolesError("At least one role is required");
      error = true;
    }

    if (!error) {
      // Check if we changed email if so we need to provide 2FA key or password

      // Save changes to backend
      let formData = new FormData();

      if (editFirstName !== single.firstName) {
        formData.append("editFirstName", editFirstName);
      }
      if (editMiddleName !== single.middleName) {
        formData.append("editMiddleName", editMiddleName);
      }
      if (editLastName !== single.lastName) {
        formData.append("editLastName", editLastName);
      }
      if (editEmail !== single.email) {
        formData.append("editEmail", editEmail);
      }
      if (editRoles !== single.roles) {
        formData.append("editRoles", editRoles);
      }

      let formDataLength = [...formData].length;
      if (formDataLength !== 0) {
        // Send to backend
        editUser(formData, single._id);
      } else {
        onCancel();
      }
    } else {
      setSuccessFail("fail");
      setLoading(false);
    }
  };

  // Cancel changes
  const onCancel = () => {
    setLoading(false);
    setSuccessFail(false);
    setIsBlocking(false);

    setEditFirstName(single.firstName);
    setEditFirstNameError(false);

    setEditMiddleName(single.middleName);
    setEditMiddleNameError(false);

    setEditLastName(single.lastName);
    setEditLastNameError(false);

    setEditEmail(single.email);
    setEditEmailError(false);

    setEditRoles(single.roles);
    setEditRolesError(false);

    closeEditDialog();
  };

  // // Change functions
  // First name
  const onFirstNameChange = (e) => {
    setEditFirstName(e.target.value);
    setEditFirstNameError(false);
    setSuccessFail(false);
  };

  // Middle name
  const onMiddleNameChange = (e) => {
    setEditMiddleName(e.target.value);
    setEditMiddleNameError(false);
    setSuccessFail(false);
  };

  // Last name
  const onLastNameChange = (e) => {
    setEditLastName(e.target.value);
    setEditLastNameError(false);
    setSuccessFail(false);
  };

  // Email
  const onEmailChange = (e) => {
    setEditEmail(e.target.value);
    if (
      e.target.value !== "" &&
      !/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        e.target.value
      )
    ) {
      setEditEmailError("Invalid email");
    } else {
      setEditEmailError(false);
    }
    setSuccessFail(false);
  };

  // Roles
  const onRolesChange = (e) => {
    setEditRoles(e.target.value);
    setEditRolesError(false);

    setSuccessFail(false);
  };

  usePrompt(
    "Some values have been changed, are you sure you want to leave this page? This will reset all values!",
    isBlocking
  );

  // Return the page
  return (
    <Fragment>
      <Dialog
        open={editDialogOpen}
        maxWidth="lg"
        disableEscapeKeyDown
        fullWidth
      >
        <DialogTitle>
          {`Edit: ${[single?.firstName, single?.middleName, single?.lastName]
            .filter(Boolean)
            .join(" ")}?`}
        </DialogTitle>
        <Divider />
        <DialogContent>
          <Grid container spacing={2}>
            {/* FirstName */}
            <Grid item xs={12}>
              <TextField
                type="text"
                label="First name"
                variant="outlined"
                value={editFirstName}
                onChange={onFirstNameChange}
                error={editFirstNameError !== false}
                helperText={editFirstNameError}
                disabled={loading}
                required
                fullWidth
              />
            </Grid>
            {/* Middle name */}
            <Grid item xs={12}>
              <TextField
                type="text"
                label="Middle name"
                variant="outlined"
                value={editMiddleName}
                onChange={onMiddleNameChange}
                error={editMiddleNameError !== false}
                helperText={editMiddleNameError}
                disabled={loading}
                fullWidth
              />
            </Grid>
            {/* Last name */}
            <Grid item xs={12}>
              <TextField
                type="text"
                label="Last name"
                variant="outlined"
                value={editLastName}
                onChange={onLastNameChange}
                error={editLastNameError !== false}
                helperText={editLastNameError}
                disabled={loading}
                required
                fullWidth
              />
            </Grid>
            {/* Email */}
            <Grid item xs={12}>
              <TextField
                type="email"
                label="Email"
                variant="outlined"
                spellCheck={false}
                value={editEmail}
                onChange={onEmailChange}
                error={editEmailError !== false}
                helperText={editEmailError}
                disabled={loading}
                required
                fullWidth
              />
            </Grid>
            {/* Roles */}
            <Grid item xs={12}>
              <TextField
                label="Roles"
                variant="outlined"
                value={editRoles}
                onChange={onRolesChange}
                error={editRolesError !== false}
                helperText={editRolesError}
                select
                SelectProps={{
                  multiple: true,
                  renderValue: (selected) => selected.join(", "),
                }}
                fullWidth
              >
                <MenuItem
                  value="admin"
                  disabled={!loggedInUser.roles.includes("admin")}
                >
                  <Checkbox
                    color="primary"
                    checked={editRoles.includes("admin")}
                  />
                  <ListItemText primary="Admin" />
                </MenuItem>
                <MenuItem
                  value="owner"
                  disabled={!loggedInUser.roles.includes("admin")}
                >
                  <Checkbox
                    color="primary"
                    checked={editRoles.includes("owner")}
                  />
                  <ListItemText primary="Owner" />
                </MenuItem>
                <MenuItem value="teacher">
                  <Checkbox
                    color="primary"
                    checked={editRoles.includes("teacher")}
                  />
                  <ListItemText primary="Teacher" />
                </MenuItem>
                <MenuItem value="user">
                  <Checkbox
                    color="primary"
                    checked={editRoles.includes("user")}
                  />
                  <ListItemText primary="User" />
                </MenuItem>
              </TextField>
            </Grid>
          </Grid>
        </DialogContent>
        <Divider />
        <DialogActions>
          <Grid container spacing={1} justifyContent="flex-end">
            <Grid item>
              <Button
                variant="contained"
                onClick={onCancel}
                color="error"
                disabled={loadingUser || loading}
              >
                Cancel
              </Button>
            </Grid>
            <Grid item>
              <LoadingButton
                variant="contained"
                onClick={onSubmit}
                color="primary"
                className={buttonClassname}
                disabled={loadingUser}
                loading={loading}
              >
                Save changes
              </LoadingButton>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};

// Set the state for this component to the global state
const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps, { editUser, clearUserResponse })(
  EditDialog
);

// Import the React modules
import React, { Fragment } from "react";

// Import the Redux modules
import { connect } from "react-redux";

// Import Mui components
import { Typography, Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

// Import images
import Water_Home from "images/Design/Water_Home.webp";
import TriangleShape_1 from "images/Design/TriangleShape_1.svg";

const useStyles = makeStyles((theme) => ({
  imageFix: {
    fontSize: 0,
  },
  root: {
    position: "relative",
    textAlign: "center",
  },
  textBox: {
    backgroundColor: "rgba(255, 255, 255, 0.36)",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    zIndex: 1,
    [theme.breakpoints.down("sm")]: {
      width: "97%",
      height: "57.921%",
    },
    [theme.breakpoints.up("sm")]: {
      width: "60%",
      height: "49.647%",
    },
    [theme.breakpoints.up("md")]: {
      width: "39.305%",
      height: "32.523%",
    },
  },
  text: {
    fontWeight: 600,
    [theme.breakpoints.down("sm")]: {
      fontSize: "20px",
      lineHeight: "31px",
    },
    [theme.breakpoints.up("sm")]: {
      fontSize: "30px",
      lineHeight: "41px",
    },
    color: "#FFFFFF",
    position: "relative",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
  triangleBox: {
    position: "absolute",
    bottom: "-1px",
    width: "100%",
  },
  triangle: {
    position: "relative",
    bottom: "0%",
    width: "100%",
  },
  arrow_1: {
    position: "absolute",
    top: "0%",
    left: "0%",
    transform: "translate(0%, 0%)",
    [theme.breakpoints.down("sm")]: {
      opacity: "0%",
    },
  },
  arrow_2: {
    position: "absolute",
    top: "50%",
    left: "0%",
    transform: "translate(0%, -50%)",
    [theme.breakpoints.down("sm")]: {
      opacity: "0%",
    },
  },
  arrow_3: {
    position: "absolute",
    top: "100%",
    left: "0%",
    transform: "translate(0%, -100%)",
    [theme.breakpoints.down("sm")]: {
      opacity: "0%",
    },
  },
}));

const Header = ({ lang: { language } }) => {
  // Import style
  const classes = useStyles();

  return (
    <Fragment>
      <Box className={classes.root}>
        <Box className={classes.imageFix}>
          <img src={Water_Home} alt="Water_Home" style={{ width: "100%" }} />
        </Box>
        <Box className={classes.textBox}>
          <Typography className={classes.text}>
            {language.global.companyName} <br /> {language.global.companyMotto}
          </Typography>
        </Box>
        <Box className={classes.triangleBox}>
          <Box className={`${classes.imageFix} ${classes.triangle}`}>
            <img
              src={TriangleShape_1}
              alt="TriangleShape_1"
              style={{ width: "100%", maxHeight: "84px" }}
            />
          </Box>
          <Box className={`${classes.imageFix} ${classes.arrow_1}`}>
            <ExpandMoreIcon style={{ fontSize: 50 }} />
          </Box>
          <Box className={`${classes.imageFix} ${classes.arrow_2}`}>
            <ExpandMoreIcon style={{ fontSize: 50 }} />
          </Box>
          <Box className={`${classes.imageFix} ${classes.arrow_3}`}>
            <ExpandMoreIcon style={{ fontSize: 50 }} />
          </Box>
        </Box>
      </Box>
    </Fragment>
  );
};

// Set the state for this component to the global state
const mapStateToProps = (state) => ({
  lang: state.lang,
});

export default connect(mapStateToProps, null)(Header);

// Import the React modules
import React, { Fragment } from "react";
import { Route, Routes } from "react-router-dom";

// Import website components
import PDNE from "components/PageDoesNotExist/PageDoesNotExist";

import ZwemInstructeurABC from "./Pages/ZwemInstructeurABC/ZwemInstructeurABC";
import AlgeheleLifeguardOpleiding from "./Pages/AlgeheleLifeguardOpleiding/AlgeheleLifeguardOpleiding";
import ZRZOpleiding from "./Pages/ZRZOpleiding/ZRZOpleiding";
import Enlist from "./Pages/Enlist/Enlist";

const StudiesLayout = () => {
  return (
    <Fragment>
      <Routes>
        <Route path="zweminstructeurabc" element={<ZwemInstructeurABC />} />
        <Route
          path="algehelelifeguardopleiding"
          element={<AlgeheleLifeguardOpleiding />}
        />
        <Route path="zrzopleiding" element={<ZRZOpleiding />} />
        <Route path="inschrijven/:source" element={<Enlist />} />
        <Route path="*" element={PDNE} />
      </Routes>
    </Fragment>
  );
};

export default StudiesLayout;

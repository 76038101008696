// Import the React modules
import React, { Fragment } from "react";
import clsx from "clsx";

// Import the Redux modules
import { connect } from "react-redux";
import {
  sendContactEmail,
  clearAppResponse,
} from "reduxStates/appState/appActions";

// Import material-ui/core components
import { Box, Typography, Grid, Container, TextField } from "@mui/material";
import { makeStyles } from "@mui/styles";
import LoadingButton from "@mui/lab/LoadingButton";

// Import Material-UI Colors
import { red, green } from "@mui/material/colors";

// Import images
import Image from "images/Design/Contact/Image.webp";

import { TitleBox } from "components/Layout/PageLayout/PageLayout";

const globalStyles = makeStyles(() => ({
  imageFix: {
    fontSize: 0,
  },
}));

const mainStyles = makeStyles(() => ({
  container: {
    marginTop: "50px",
    marginLeft: "4.86%",
    marginRight: "4.86%",
  },
  image: {
    zIndex: 1,
  },
  imageContainer: {
    marginRight: "10.6%",
    position: "relative",
    marginTop: "6.5%",
    maxWidth: "367px",
  },
  imageBorder: {
    width: "97.96%",
    height: "96.91%",
    position: "absolute",
    border: "9px solid rgba(41, 171, 226, 0.61)",
    top: "-15.25%",
    left: "17%",
    zIndex: -1,
  },
  textFieldLabel: {
    fontSize: "17px",
    fontWeight: "600",
    lineHeight: "23px",
    color: "#000000",
    marginBottom: "3px",
  },
  textFieldSmall: {
    height: "45px",
    "& .MuiOutlinedInput-root": {
      borderRadius: "0px",
      "& input": {
        height: "45px",
        padding: "0 14px",
      },
      "& fieldset": {
        borderWidth: "2px",
        borderColor: "#9CD5D4",
      },
      "&.Mui-focused fieldset": {
        borderColor: "rgba(41,171,226, 0.61)",
      },
      "&:hover fieldset": {
        borderColor: "#29ABE2",
      },
    },
  },
  textFieldLarge: {
    "& .MuiOutlinedInput-root": {
      borderRadius: "0px",
      "& fieldset": {
        borderWidth: "2px",
        borderColor: "#9CD5D4",
      },
      "&.Mui-focused fieldset": {
        borderColor: "rgba(41,171,226, 0.61)",
      },
      "&:hover fieldset": {
        borderColor: "#29ABE2",
      },
    },
  },
  button: {
    textTransform: "none",
    height: "44.52px",
    width: "156px",
    borderRadius: "0px",
    backgroundColor: "#29ABE2",
    opacity: "0.61",
    color: "#F2F2F2",
    "&:hover": {
      backgroundColor: "#1D6E8F",
      opacity: "1",
    },
    fontSize: "17px",
    lineHeight: "23px",
    "&.Mui-disabled": {
      opacity: "0.3",
      color: "#F2F2F2",
      backgroundColor: "rgba(0, 0, 0, 0.26)",
    },
  },
  buttonFail: {
    backgroundColor: red[500],
    opacity: "1",
    "&:hover": {
      backgroundColor: red[700],
    },
  },
  buttonSuccess: {
    backgroundColor: green[500],
    opacity: "1",
    "&:hover": {
      backgroundColor: green[700],
    },
  },
}));

const Contact = ({
  lang: { language },
  app: { appSuccess, appResult },
  sendContactEmail,
  clearAppResponse,
}) => {
  const mainStyle = mainStyles();
  const globalStyle = globalStyles();

  const [loading, setLoading] = React.useState(false);
  const [successFail, setSuccessFail] = React.useState(false);

  const [name, setName] = React.useState("");
  const [nameError, setNameError] = React.useState(false);

  const [email, setEmail] = React.useState("");
  const [emailError, setEmailError] = React.useState(false);

  const [message, setMessage] = React.useState("");
  const [messageError, setMessageError] = React.useState(false);

  const buttonFail = clsx({
    [mainStyle.buttonFail]: successFail === "fail",
    [mainStyle.buttonSuccess]: successFail === "success",
  });

  React.useEffect(() => {
    if (appSuccess !== null) {
      if (appResult.origin === "sendContactEmail") {
        if (appSuccess === true) {
          clearAppResponse();
          setSuccessFail("success");
          setLoading(false);
        } else if (appSuccess === false) {
          setSuccessFail("fail");
          setLoading(false);

          clearAppResponse();
        }
      }
    }
    // Prevent any useless errors with net line:
    // eslint-disable-next-line
  }, [appResult]);

  const sendMessage = () => {
    setSuccessFail(false);
    setLoading(true);

    let error = false;

    if (name === "") {
      setNameError("Naam mag niet leeg zijn");
      error = true;
    }
    if (
      !/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        email
      )
    ) {
      setEmailError("Dit is geen geldig email address");
      error = true;
    }
    if (email === "") {
      setEmailError("Email mag niet leeg zijn");
      error = true;
    }
    if (message === "") {
      setMessageError("Bericht mag niet leeg zijn");
      error = true;
    }

    if (!error) {
      sendContactEmail({
        name,
        email,
        message,
      });
    } else {
      setSuccessFail("fail");
      setLoading(false);
    }
  };

  return (
    <Fragment>
      <TitleBox rootColor="rgba(41,171,226, 0.61)">
        {language.contact.pageHeader}
      </TitleBox>
      <Box className={mainStyle.container}>
        <Container disableGutters>
          <Grid container spacing={8}>
            <Grid item xs={12} sm={6} align="left">
              <Box className={mainStyle.imageContainer}>
                <Box className={mainStyle.imageBorder} />
                <Box className={`${globalStyle.imageFix} ${mainStyle.image}`}>
                  <img src={Image} style={{ width: "100%" }} alt="swimmer" />
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Box>
                <Typography className={mainStyle.textFieldLabel} align="left">
                  {language.contact.contactForm.name}
                </Typography>
                <TextField
                  variant="outlined"
                  fullWidth
                  className={mainStyle.textFieldSmall}
                  onChange={(e) => {
                    setName(e.target.value);
                    setNameError(false);
                  }}
                  error={nameError !== false}
                  helperText={nameError}
                  value={name}
                />
              </Box>
              <Box sx={{ paddingTop: "25px" }}>
                <Typography className={mainStyle.textFieldLabel} align="left">
                  {language.contact.contactForm.eMail}
                </Typography>
                <TextField
                  variant="outlined"
                  fullWidth
                  className={mainStyle.textFieldSmall}
                  onChange={(e) => {
                    setEmail(e.target.value);
                    setEmailError(false);
                  }}
                  error={emailError !== false}
                  helperText={emailError}
                  value={email}
                />
              </Box>
              <Box sx={{ paddingTop: "25px" }}>
                <Typography className={mainStyle.textFieldLabel} align="left">
                  {language.contact.contactForm.message}
                </Typography>
                <TextField
                  variant="outlined"
                  fullWidth
                  className={mainStyle.textFieldLarge}
                  multiline
                  minRows={6}
                  maxRows={11}
                  onChange={(e) => {
                    setMessage(e.target.value);
                    setMessageError(false);
                  }}
                  error={messageError !== false}
                  helperText={messageError}
                  value={message}
                />
              </Box>
              <Box
                sx={{
                  paddingTop: "25px",
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <Box sx={{ width: "156px" }}>
                  <LoadingButton
                    className={`${mainStyle.button} ${buttonFail}`}
                    onClick={sendMessage}
                    loading={loading}
                    fullWidth
                  >
                    {`${language.contact.contactForm.send} >>`}
                  </LoadingButton>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </Fragment>
  );
};

// Set the state for this component to the global state
const mapStateToProps = (state) => ({
  lang: state.lang,
  app: state.app,
});

// Connect this component to the redux global state and bring in the state and needed functions
export default connect(mapStateToProps, { sendContactEmail, clearAppResponse })(
  Contact
);

// Import the React modules
import React, { Fragment } from "react";
import { Link as RouterLink } from "react-router-dom";

// Import the Redux modules
import { connect } from "react-redux";
import { getLang } from "reduxStates/langState/langActions";
import { logout } from "reduxStates/authState/authActions";

// Import material-ui components
import { Divider, Box, Collapse, IconButton, Button } from "@mui/material";
import { makeStyles } from "@mui/styles";

import Flag from "react-world-flags";

// Import material-ui icons
import MenuIcon from "@mui/icons-material/Menu";

import { checkRoles } from "helpers/other";

// Create the styles
const useStyles = makeStyles((theme) => ({
  main: {
    backgroundColor: "#1D6E8F",
    height: "100%",
  },
  header: {
    maxHeight: "61.48px",
    marginTop: theme.spacing(1.25),
    marginBottom: theme.spacing(1),
    display: "flex",
    alignItems: "center",
    width: "100%",
  },
  menuButton: {
    width: "43.5px",
    height: "43.5px",
    marginLeft: "8px",
    color: "#F2F2F2",
  },
  button: {
    textTransform: "none",
    color: "#F2F2F2",
    fontSize: "17px",
    justifyContent: "left",
    paddingLeft: "20px",
  },
  insetButton: {
    paddingLeft: "45px",
  },
  doubleInsetButton: {
    paddingLeft: "70px",
  },
  divider: {
    width: "94px",
    backgroundColor: "#F2F2F2",
    marginLeft: "20px",
  },
  insetDivider: {
    width: "126px",
    backgroundColor: "#F2F2F2",
    marginLeft: "20px",
  },
  doubleInsetDivider: {
    width: "280px",
    backgroundColor: "#F2F2F2",
    marginLeft: "20px",
  },
}));

const SideNav = ({
  auth: { isAuthenticated },
  user: { loggedInUser },
  lang: { language },
  getLang,
  logout,
  setUserSideNavOpen,
}) => {
  // Import the styles
  const classes = useStyles();

  // Create the local states
  const [languageCollapseOpen, setLanguageCollapseOpen] = React.useState(false);
  const [servicesCollapseOpen, setServicesCollapseOpen] = React.useState(false);
  const [studiesCollapseOpen, setStudiesCollapseOpen] = React.useState(false);

  const [userCollapseOpen, setUserCollapseOpen] = React.useState(false);

  const exitSideNav = () => {
    closeServicesCollapse();
    closeStudiesCollapse();
    closeLanguageCollapse();
    closeUserCollapse();
    closeSideNav();
  };
  const closeSideNav = () => {
    setUserSideNavOpen(false);
  };

  const closeServicesCollapse = () => {
    setServicesCollapseOpen(false);
  };

  const toggleServicesCollapse = () => {
    setServicesCollapseOpen(!servicesCollapseOpen);
  };

  const closeStudiesCollapse = () => {
    setStudiesCollapseOpen(false);
  };

  const toggleStudiesCollapse = () => {
    setStudiesCollapseOpen(!studiesCollapseOpen);
  };

  const closeLanguageCollapse = () => {
    setLanguageCollapseOpen(false);
  };

  const toggleLanguageCollapse = () => {
    setLanguageCollapseOpen(!languageCollapseOpen);
  };

  const closeUserCollapse = () => {
    setUserCollapseOpen(false);
  };

  const toggleUserCollapse = () => {
    setUserCollapseOpen(!userCollapseOpen);
  };

  // Change the language on click
  const onLanguageClick = (id) => {
    if (language._id !== id) {
      getLang(id);
    }
  };

  // Guest links
  const guestLinks = (
    <Fragment>
      <Button
        fullWidth
        className={classes.button}
        component={RouterLink}
        to="/login"
        onClick={exitSideNav}
      >
        {language.navbar.login}
      </Button>
      <Divider variant="middle" className={classes.divider} />
    </Fragment>
  );

  // Auth links
  const authLinks = () => (
    <Fragment>
      <Button fullWidth className={classes.button} onClick={toggleUserCollapse}>
        {!loggedInUser
          ? "loading"
          : [
              loggedInUser.firstName,
              loggedInUser.middleName,
              loggedInUser.lastName,
            ]
              .filter(Boolean)
              .join(" ")}
      </Button>
      <Divider variant="middle" className={classes.divider} />
      <Collapse in={userCollapseOpen} timeout="auto" unmountOnExit>
        {checkRoles(["admin", "owner", "teacher"], loggedInUser.roles) && (
          <Fragment>
            <Button
              fullWidth
              className={`${classes.button} ${classes.doubleInsetButton}`}
              style={{ whiteSpace: "nowrap" }}
              component={RouterLink}
              to="/dashboard"
              onClick={exitSideNav}
            >
              Dashboard
            </Button>
            <Divider variant="middle" className={classes.doubleInsetDivider} />
          </Fragment>
        )}
        {checkRoles(["admin", "owner"], loggedInUser.roles) && (
          <Fragment>
            <Button
              fullWidth
              className={`${classes.button} ${classes.doubleInsetButton}`}
              style={{ whiteSpace: "nowrap" }}
              component={RouterLink}
              to="/adminpanel/users"
              onClick={exitSideNav}
            >
              Admin
            </Button>
            <Divider variant="middle" className={classes.doubleInsetDivider} />
          </Fragment>
        )}
        <Button
          fullWidth
          className={`${classes.button} ${classes.doubleInsetButton}`}
          style={{ whiteSpace: "nowrap" }}
          component={RouterLink}
          to="/profile"
          onClick={exitSideNav}
        >
          Profile
        </Button>
        <Divider variant="middle" className={classes.doubleInsetDivider} />

        <Button
          fullWidth
          className={`${classes.button} ${classes.doubleInsetButton}`}
          style={{ whiteSpace: "nowrap" }}
          onClick={() => {
            exitSideNav();
            logout();
          }}
        >
          Logout
        </Button>
      </Collapse>
    </Fragment>
  );

  // Return the component
  return (
    <Fragment>
      <Box className={classes.main}>
        <Box className={classes.header}>
          <IconButton onClick={closeSideNav} className={classes.menuButton}>
            <MenuIcon />
          </IconButton>
        </Box>
        <Button
          fullWidth
          className={classes.button}
          component={RouterLink}
          to="/"
          onClick={exitSideNav}
        >
          Home
        </Button>
        <Divider variant="middle" className={classes.divider} />
        <Button
          fullWidth
          className={classes.button}
          onClick={toggleServicesCollapse}
        >
          {language.navbar.services}
        </Button>
        <Divider variant="middle" className={classes.divider} />
        <Collapse in={servicesCollapseOpen} timeout="auto" unmountOnExit>
          <Button
            fullWidth
            className={`${classes.button} ${classes.insetButton}`}
            onClick={toggleStudiesCollapse}
          >
            {language.navbar.servicesDropDownContent.study}
          </Button>
          <Divider variant="middle" className={classes.insetDivider} />
          <Collapse in={studiesCollapseOpen} timeout="auto" unmountOnExit>
            <Button
              fullWidth
              className={`${classes.button} ${classes.doubleInsetButton}`}
              style={{ whiteSpace: "nowrap" }}
              component={RouterLink}
              to="/opleiding/zweminstructeurabc"
              onClick={exitSideNav}
            >
              {language.navbar.servicesDropDownContent.studyContent.link1}
            </Button>
            <Divider variant="middle" className={classes.doubleInsetDivider} />
            <Button
              fullWidth
              className={`${classes.button} ${classes.doubleInsetButton}`}
              style={{ whiteSpace: "nowrap" }}
              component={RouterLink}
              to="/opleiding/algehelelifeguardopleiding"
              onClick={exitSideNav}
            >
              {language.navbar.servicesDropDownContent.studyContent.link2}
            </Button>
            <Divider variant="middle" className={classes.doubleInsetDivider} />
            <Button
              fullWidth
              className={`${classes.button} ${classes.doubleInsetButton}`}
              style={{ whiteSpace: "nowrap" }}
              component={RouterLink}
              to="/opleiding/zrzopleiding"
              onClick={exitSideNav}
            >
              {language.navbar.servicesDropDownContent.studyContent.link3}
            </Button>
            <Divider variant="middle" className={classes.doubleInsetDivider} />
          </Collapse>
          <Button
            fullWidth
            className={`${classes.button} ${classes.insetButton}`}
            disabled
          >
            {language.navbar.servicesDropDownContent.swimSchool}
          </Button>
          <Divider variant="middle" className={classes.insetDivider} />
          <Button
            fullWidth
            className={`${classes.button} ${classes.insetButton}`}
            component={RouterLink}
            to="/inhuur"
            onClick={exitSideNav}
          >
            {language.navbar.servicesDropDownContent.hire}
          </Button>
          <Divider variant="middle" className={classes.insetDivider} />
        </Collapse>
        <Button
          fullWidth
          className={classes.button}
          component={RouterLink}
          to="/contact"
          onClick={exitSideNav}
        >
          {language.navbar.contact}
        </Button>
        <Divider variant="middle" className={classes.divider} />
        <Button
          fullWidth
          className={classes.button}
          component={RouterLink}
          to="/about"
          onClick={exitSideNav}
        >
          Over ons
        </Button>
        <Divider variant="middle" className={classes.divider} />
        {isAuthenticated ? authLinks() : guestLinks}
        {language.navbar.languageDropDownContent.length !== 1 && (
          <Fragment>
            <Button
              fullWidth
              className={classes.button}
              onClick={toggleLanguageCollapse}
            >
              <Flag
                code={language.countryCode}
                height="29"
                className={classes.flag}
              />
            </Button>
            <Divider variant="middle" className={classes.divider} />
            <Collapse in={languageCollapseOpen} timeout="auto" unmountOnExit>
              {language.navbar.languageDropDownContent.map(
                (dropDownLanguage) => (
                  <Fragment key={dropDownLanguage._id}>
                    <Button
                      fullWidth
                      className={classes.insetButton}
                      onClick={() => {
                        exitSideNav();
                        onLanguageClick(dropDownLanguage._id);
                      }}
                    >
                      <Flag
                        code={dropDownLanguage.countryCode}
                        height="29"
                        className={classes.flag}
                      />
                    </Button>
                    <Divider
                      variant="middle"
                      className={classes.insetDivider}
                    />
                  </Fragment>
                )
              )}
            </Collapse>
          </Fragment>
        )}
      </Box>
    </Fragment>
  );
};

// Set the state for this component to the global state
const mapStateToProps = (state) => ({
  lang: state.lang,
  auth: state.auth,
  user: state.user,
});

// Connect this component to the redux global state and bring in the state and needed functions
export default connect(mapStateToProps, { getLang, logout })(SideNav);

// Import the React modules
import React from "react";

// Import Material-UI Components
import { Box, Typography } from "@mui/material";

const NotAuthorized = () => {
  return (
    <Box sx={{ paddingTop: "32px", width: "100%" }}>
      <Typography variant="h3" gutterBottom align="center">
        You are not authorized to access this page
      </Typography>
      <Typography variant="h4" align="center">
        Are you sure you are on the right page?
      </Typography>
    </Box>
  );
};

// Connect this component to the redux global state and bring in the state and needed functions
export default NotAuthorized;

// Import the React modules
import React, { Fragment } from "react";
import { Route, Routes, Navigate } from "react-router-dom";

// Import the Redux modules
import { connect } from "react-redux";

// Import website components
import PDNE from "components/PageDoesNotExist/PageDoesNotExist";

import Register from "./Pages/Register/Register";
import VerifyEmailSend from "./Pages/VerifyEmailSend/VerifyEmailSend";
import VerifyEmail from "./Pages/VerifyEmail/VerifyEmail";

const RegisterLayout = ({ auth: { isAuthenticated } }) => {
  // If we are logged in we dont have to be here, so we redirect to dashboard
  if (isAuthenticated) {
    return <Navigate to="/dashboard" />;
  }

  return (
    <Fragment>
      <Routes>
        <Route path="/" element={<Register />} />
        <Route path="/verifyemailsend" element={<VerifyEmailSend />} />
        <Route
          path="/verifyemail/:verifyEmailToken"
          element={<VerifyEmail />}
        />
        <Route path="*" element={<PDNE />} />
      </Routes>
    </Fragment>
  );
};

// Set the state for this component to the global state
const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, null)(RegisterLayout);

// Import the React modules
import React, { Fragment } from "react";
import { Navigate, useParams } from "react-router-dom";
import clsx from "clsx";

// Import the Redux modules
import { connect } from "react-redux";
import {
  sendEnlistEmail,
  clearAppResponse,
} from "reduxStates/appState/appActions";

// Import material-ui/core components
import {
  Grid,
  Box,
  TextField,
  Typography,
  Container,
  RadioGroup,
  Radio,
  FormControlLabel,
  FormHelperText,
} from "@mui/material";
import { makeStyles } from "@mui/styles";

import { LoadingButton, MobileDatePicker } from "@mui/lab";

// Import Material-UI Colors
import { red, green } from "@mui/material/colors";

import Wave from "images/Design/Opleiding/Wave_2.svg";

import { TitleBox } from "components/Layout/PageLayout/PageLayout";

const mainStyles = makeStyles(() => ({
  container: {
    marginTop: "50px",
    marginLeft: "4.86%",
    marginRight: "4.86%",
    paddingBottom: "60px",
  },
  textFieldLabel: {
    fontSize: "17px",
    fontWeight: "600",
    lineHeight: "23px",
    color: "#000000",
    marginBottom: "3px",
  },
  textFieldSmall: {
    height: "45px",
    "& .MuiOutlinedInput-root": {
      borderRadius: "0px",
      "& input": {
        height: "45px",
        padding: "0 14px",
      },
      "& fieldset": {
        borderWidth: "2px",
        borderColor: "#9CD5D4",
      },
      "&.Mui-focused fieldset": {
        borderColor: "rgba(41,171,226, 0.61)",
      },
      "&:hover fieldset": {
        borderColor: "#29ABE2",
      },
    },
  },
  textFieldLarge: {
    "& .MuiOutlinedInput-root": {
      borderRadius: "0px",
      "& fieldset": {
        borderWidth: "2px",
        borderColor: "#9CD5D4",
      },
      "&.Mui-focused fieldset": {
        borderColor: "rgba(41,171,226, 0.61)",
      },
      "&:hover fieldset": {
        borderColor: "#29ABE2",
      },
    },
  },
  button: {
    textTransform: "none",
    height: "64px",
    borderRadius: "0px",
    backgroundColor: "#29ABE2",
    opacity: "0.61",
    color: "#F2F2F2",
    "&:hover": {
      backgroundColor: "#1D6E8F",
      opacity: "1",
    },
    fontSize: "30px",
    "&.Mui-disabled": {
      opacity: "0.3",
      color: "#F2F2F2",
      backgroundColor: "rgba(0, 0, 0, 0.26)",
    },
  },
  buttonFail: {
    backgroundColor: red[500],
    opacity: "1",
    "&:hover": {
      backgroundColor: red[700],
    },
  },
  buttonSuccess: {
    backgroundColor: green[500],
    opacity: "1",
    "&:hover": {
      backgroundColor: green[700],
    },
  },
  radio: {
    "& .MuiRadio-root": {
      "&.Mui-checked": {
        color: "#9CD5D4",
      },
      "&:hover": {
        backgroundColor: "rgba(156, 213, 212, 0.15)",
      },
    },
  },
  wave: {
    width: "100%",
    height: "24px",
  },
  waveImg: {
    background: `url(${Wave})`,
    height: "24px",
    width: "100%",
    backgroundRepeat: "repeat-x",
  },
}));

const Enlist = ({
  lang: { language },
  app: { appSuccess, appResult },
  sendEnlistEmail,
  clearAppResponse,
}) => {
  let params = useParams();

  const mainStyle = mainStyles();

  // Create local state
  const [sourceReadable, setSourceReadable] = React.useState();

  const [redirect, setRedirect] = React.useState(false);

  const [loading, setLoading] = React.useState(false);
  const [successFail, setSuccessFail] = React.useState(false);

  const [firstName, setFirstName] = React.useState("");
  const [firstNameError, setFirstNameError] = React.useState(false);

  const [initials, setInitials] = React.useState("");
  const [initialsError, setInitialsError] = React.useState(false);

  const [middleName, setMiddleName] = React.useState("");
  const [middleNameError, setMiddleNameError] = React.useState(false);

  const [lastName, setLastName] = React.useState("");
  const [lastNameError, setLastNameError] = React.useState(false);

  const [dateOfBirth, setDateOfBirth] = React.useState(null);
  const [dateOfBirthError, setDateOfBirthError] = React.useState(false);

  const [gender, setGender] = React.useState("");
  const [genderError, setGenderError] = React.useState(false);

  const [country, setCountry] = React.useState("");
  const [countryError, setCountryError] = React.useState(false);

  const [zipcode, setZipcode] = React.useState("");
  const [zipcodeError, setZipcodeError] = React.useState(false);

  const [houseNumber, setHouseNumber] = React.useState("");
  const [houseNumberError, setHouseNumberError] = React.useState(false);

  const [email, setEmail] = React.useState("");
  const [emailError, setEmailError] = React.useState(false);

  const [mobileNumber, setMobileNumber] = React.useState("");
  const [mobileNumberError, setMobileNumberError] = React.useState(false);

  const [comment, setComment] = React.useState("");
  const [commentError, setCommentError] = React.useState(false);

  const [customerType, setCustomerType] = React.useState("Particulier");

  const [companyName, setCompanyName] = React.useState("");
  const [companyNameError, setCompanyNameError] = React.useState(false);

  const [kvkNumber, setKvkNumber] = React.useState("");
  const [kvkNumberError, setKvkNumberError] = React.useState(false);

  const [btwNumber, setBtwNumber] = React.useState("");
  const [btwNumberError, setBtwNumberError] = React.useState(false);

  const [invoiceEmail, setInvoiceEmail] = React.useState("");
  const [invoiceEmailError, setInvoiceEmailError] = React.useState(false);

  const [contactPersonName, setContactPersonName] = React.useState("");
  const [contactPersonNameError, setContactPersonNameError] =
    React.useState(false);

  const [contactPersonEmail, setContactPersonEmail] = React.useState("");
  const [contactPersonEmailError, setContactPersonEmailError] =
    React.useState(false);

  const [contactPersonNumber, setContactPersonNumber] = React.useState("");
  const [contactPersonNumberError, setContactPersonNumberError] =
    React.useState(false);

  const buttonFail = clsx({
    [mainStyle.buttonFail]: successFail === "fail",
    [mainStyle.buttonSuccess]: successFail === "success",
  });

  React.useEffect(() => {
    if (!params.source) {
      setRedirect("/");
    } else {
      if (params.source === "zweminstructeurabc") {
        setSourceReadable("Zweminstructeur ABC");
      } else if (params.source === "algehelelifeguardopleiding") {
        setSourceReadable("Algehele Lifeguard Opleiding");
      } else if (params.source === "zrzopleiding") {
        setSourceReadable("ZRZ Opleiding");
      } else {
        setRedirect("/");
      }
    }
    // Prevent any useless errors with net line:
    // eslint-disable-next-line
  }, []);

  React.useEffect(() => {
    if (appSuccess !== null) {
      if (appResult.origin === "sendEnlistEmail") {
        if (appSuccess === true) {
          clearAppResponse();
          setSuccessFail("success");
          setLoading(false);
        } else if (appSuccess === false) {
          setSuccessFail("fail");
          setLoading(false);

          clearAppResponse();
        }
      }
    }
    // Prevent any useless errors with net line:
    // eslint-disable-next-line
  }, [appResult]);

  const sendEnlistForm = () => {
    setSuccessFail(false);
    setLoading(true);

    let error = false;

    if (firstName === "") {
      setFirstNameError("Voornaam mag niet leeg zijn");
      error = true;
    }
    if (initials === "") {
      setInitialsError("Voorletters mag niet leeg zijn");
      error = true;
    }
    if (lastName === "") {
      setLastNameError("Achternaam mag niet leeg zijn");
      error = true;
    }
    if (dateOfBirth === null) {
      setDateOfBirthError("Geboortedatum mag niet leeg zijn");
      error = true;
    }
    if (gender === "") {
      setGenderError("Geslacht is verplicht");
      error = true;
    }
    if (country === "") {
      setCountryError("Land mag niet leeg zijn");
      error = true;
    }
    if (zipcode === "") {
      setZipcodeError("Postcode mag niet leeg zijn");
      error = true;
    }
    if (houseNumber === "") {
      setHouseNumberError("Huisnummer mag niet leeg zijn");
      error = true;
    }
    if (
      !/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        email
      )
    ) {
      setEmailError("Dit is geen geldig email address");
      error = true;
    }
    if (email === "") {
      setEmailError("Email mag niet leeg zijn");
      error = true;
    }

    if (mobileNumber === "") {
      setMobileNumberError("Mobiel nummer mag niet leeg zijn");
      error = true;
    }

    if (customerType === "Zakelijk") {
      if (companyName === "") {
        setCompanyNameError("Bedrijfsnaam mag niet leeg zijn");
        error = true;
      }

      if (kvkNumber === "") {
        setKvkNumberError("KvK-Nummer mag niet leeg zijn");
        error = true;
      }

      if (contactPersonName === "") {
        setContactPersonNameError("Contact persoon naam mag niet leeg zijn");
        error = true;
      }

      if (contactPersonEmail === "") {
        setContactPersonEmailError("Email mag niet leeg zijn");
        error = true;
      } else if (
        !/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
          contactPersonEmail
        )
      ) {
        setContactPersonEmailError("Dit is geen geldig email address");
        error = true;
      }
    }

    if (!error) {
      // send form
      sendEnlistEmail({
        firstName,
        initials,
        middleName,
        lastName,
        dateOfBirth: dateOfBirth.toLocaleString({ locale: "nl" }),
        gender,
        country,
        zipcode,
        houseNumber,
        email,
        mobileNumber,
        comment,
        customerType,
        companyName,
        kvkNumber,
        btwNumber,
        invoiceEmail,
        contactPersonName,
        contactPersonEmail,
        contactPersonNumber,
        source: sourceReadable,
      });
    } else {
      setSuccessFail("fail");
      setLoading(false);
    }
  };

  if (redirect) {
    return <Navigate to={redirect} />;
  }
  return (
    <Fragment>
      <TitleBox rootColor="rgba(156, 213, 212, 0.61)">
        {language.enlist.pageHeader}
      </TitleBox>
      <Box className={mainStyle.container}>
        <Container disableGutters>
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <Typography variant="h4" align="center">
                {language.enlist.enlistFor} {sourceReadable}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography className={mainStyle.textFieldLabel} align="left">
                {language.enlist.enlistForm.firstName} *
              </Typography>
              <TextField
                variant="outlined"
                fullWidth
                className={mainStyle.textFieldSmall}
                onChange={(e) => {
                  setFirstName(e.target.value);
                  setFirstNameError(false);
                }}
                error={firstNameError !== false}
                helperText={firstNameError}
                value={firstName}
                disabled={loading}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography className={mainStyle.textFieldLabel} align="left">
                {language.enlist.enlistForm.initials} *
              </Typography>
              <TextField
                variant="outlined"
                fullWidth
                className={mainStyle.textFieldSmall}
                onChange={(e) => {
                  setInitials(e.target.value);
                  setInitialsError(false);
                }}
                error={initialsError !== false}
                helperText={initialsError}
                value={initials}
                disabled={loading}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography className={mainStyle.textFieldLabel} align="left">
                {language.enlist.enlistForm.middleName}
              </Typography>
              <TextField
                variant="outlined"
                fullWidth
                className={mainStyle.textFieldSmall}
                onChange={(e) => {
                  setMiddleName(e.target.value);
                  setMiddleNameError(false);
                }}
                error={middleNameError !== false}
                helperText={middleNameError}
                value={middleName}
                disabled={loading}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography className={mainStyle.textFieldLabel} align="left">
                {language.enlist.enlistForm.lastName} *
              </Typography>
              <TextField
                variant="outlined"
                fullWidth
                className={mainStyle.textFieldSmall}
                onChange={(e) => {
                  setLastName(e.target.value);
                  setLastNameError(false);
                }}
                error={lastNameError !== false}
                helperText={lastNameError}
                value={lastName}
                disabled={loading}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography className={mainStyle.textFieldLabel} align="left">
                {language.enlist.enlistForm.dateOfBirth} *
              </Typography>
              <MobileDatePicker
                clearable
                renderInput={(props) => (
                  <TextField
                    variant="outlined"
                    fullWidth
                    {...props}
                    className={mainStyle.textFieldSmall}
                    error={dateOfBirthError !== false}
                    helperText={dateOfBirthError}
                  />
                )}
                disableFuture
                openTo="year"
                views={["year", "month", "day"]}
                value={dateOfBirth}
                onChange={(date) => {
                  setDateOfBirth(date);
                  setDateOfBirthError(false);
                }}
                disabled={loading}
                showDaysOutsideCurrentMonth
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography className={mainStyle.textFieldLabel} align="left">
                {language.enlist.enlistForm.gender} *
              </Typography>
              <RadioGroup
                row
                value={gender}
                onChange={(e) => {
                  setGender(e.target.value);
                  setGenderError(false);
                }}
              >
                <FormControlLabel
                  control={<Radio />}
                  value="Man"
                  label="Man"
                  className={mainStyle.radio}
                  disabled={loading}
                />
                <FormControlLabel
                  control={<Radio />}
                  value="Vrouw"
                  label="Vrouw"
                  className={mainStyle.radio}
                  disabled={loading}
                />
                <FormControlLabel
                  control={<Radio />}
                  value="Anders"
                  label="Anders"
                  className={mainStyle.radio}
                  disabled={loading}
                />
              </RadioGroup>
              <FormHelperText style={{ color: "#f44336", marginLeft: "14px" }}>
                {genderError}
              </FormHelperText>
            </Grid>

            <Grid item xs={12}>
              <Typography className={mainStyle.textFieldLabel} align="left">
                {language.enlist.enlistForm.country} *
              </Typography>
              <TextField
                variant="outlined"
                fullWidth
                className={mainStyle.textFieldSmall}
                onChange={(e) => {
                  setCountry(e.target.value);
                  setCountryError(false);
                }}
                error={countryError !== false}
                helperText={countryError}
                value={country}
                disabled={loading}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography className={mainStyle.textFieldLabel} align="left">
                {language.enlist.enlistForm.zipcode} *
              </Typography>
              <TextField
                variant="outlined"
                fullWidth
                className={mainStyle.textFieldSmall}
                onChange={(e) => {
                  setZipcode(e.target.value);
                  setZipcodeError(false);
                }}
                error={zipcodeError !== false}
                helperText={zipcodeError}
                value={zipcode}
                disabled={loading}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography className={mainStyle.textFieldLabel} align="left">
                {language.enlist.enlistForm.houseNumber} *
              </Typography>
              <TextField
                variant="outlined"
                fullWidth
                className={mainStyle.textFieldSmall}
                onChange={(e) => {
                  setHouseNumber(e.target.value);
                  setHouseNumberError(false);
                }}
                error={houseNumberError !== false}
                helperText={houseNumberError}
                value={houseNumber}
                disabled={loading}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography className={mainStyle.textFieldLabel} align="left">
                {language.enlist.enlistForm.eMail} *
              </Typography>
              <TextField
                variant="outlined"
                fullWidth
                className={mainStyle.textFieldSmall}
                onChange={(e) => {
                  setEmail(e.target.value);
                  setEmailError(false);
                }}
                error={emailError !== false}
                helperText={emailError}
                value={email}
                disabled={loading}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography className={mainStyle.textFieldLabel} align="left">
                {language.enlist.enlistForm.mobileNumber} *
              </Typography>
              <TextField
                variant="outlined"
                fullWidth
                className={mainStyle.textFieldSmall}
                onChange={(e) => {
                  setMobileNumber(e.target.value);
                  setMobileNumberError(false);
                }}
                error={mobileNumberError !== false}
                helperText={mobileNumberError}
                value={mobileNumber}
                disabled={loading}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography className={mainStyle.textFieldLabel} align="left">
                {language.enlist.enlistForm.comment}
              </Typography>
              <TextField
                variant="outlined"
                fullWidth
                className={mainStyle.textFieldLarge}
                multiline
                minRows={6}
                maxRows={11}
                onChange={(e) => {
                  setComment(e.target.value);
                  setCommentError(false);
                }}
                error={commentError !== false}
                helperText={commentError}
                value={comment}
                disabled={loading}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography className={mainStyle.textFieldLabel} align="left">
                Soort klant *
              </Typography>
              <RadioGroup
                row
                value={customerType}
                onChange={(e) => {
                  setCustomerType(e.target.value);
                }}
              >
                <FormControlLabel
                  control={<Radio />}
                  value="Particulier"
                  label="Particulier"
                  className={mainStyle.radio}
                  disabled={loading}
                />
                <FormControlLabel
                  control={<Radio />}
                  value="Zakelijk"
                  label="Zakelijk"
                  className={mainStyle.radio}
                  disabled={loading}
                />
              </RadioGroup>
            </Grid>

            {customerType === "Zakelijk" && (
              <>
                <Grid item xs={12}>
                  <Box
                    sx={{
                      borderWidth: "2px",

                      borderColor: "#9CD5D4",
                      borderStyle: "solid",

                      padding: "10px",
                    }}
                  >
                    <Grid container spacing={6}>
                      <Grid item xs={12}>
                        <Typography variant="h5" align="center">
                          Zakelijke klant
                        </Typography>
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        <Typography
                          className={mainStyle.textFieldLabel}
                          align="left"
                        >
                          Bedrijfsnaam *
                        </Typography>
                        <TextField
                          variant="outlined"
                          fullWidth
                          className={mainStyle.textFieldSmall}
                          onChange={(e) => {
                            setCompanyName(e.target.value);
                            setCompanyNameError(false);
                          }}
                          error={companyNameError !== false}
                          helperText={companyNameError}
                          value={companyName}
                          disabled={loading}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Typography
                          className={mainStyle.textFieldLabel}
                          align="left"
                        >
                          KvK-Nummer *
                        </Typography>
                        <TextField
                          variant="outlined"
                          fullWidth
                          className={mainStyle.textFieldSmall}
                          onChange={(e) => {
                            setKvkNumber(e.target.value);
                            setKvkNumberError(false);
                          }}
                          error={kvkNumberError !== false}
                          helperText={kvkNumberError}
                          value={kvkNumber}
                          disabled={loading}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Typography
                          className={mainStyle.textFieldLabel}
                          align="left"
                        >
                          BTW-Nummer
                        </Typography>
                        <TextField
                          variant="outlined"
                          fullWidth
                          className={mainStyle.textFieldSmall}
                          onChange={(e) => {
                            setBtwNumber(e.target.value);
                            setBtwNumberError(false);
                          }}
                          error={btwNumberError !== false}
                          helperText={btwNumberError}
                          value={btwNumber}
                          disabled={loading}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Typography
                          className={mainStyle.textFieldLabel}
                          align="left"
                        >
                          Factuur Email
                        </Typography>
                        <TextField
                          variant="outlined"
                          fullWidth
                          className={mainStyle.textFieldSmall}
                          onChange={(e) => {
                            setInvoiceEmail(e.target.value);
                            setInvoiceEmailError(false);
                          }}
                          error={invoiceEmailError !== false}
                          helperText={invoiceEmailError}
                          value={invoiceEmail}
                          disabled={loading}
                        />
                      </Grid>

                      <Grid item xs={12}>
                        <Typography
                          className={mainStyle.textFieldLabel}
                          align="center"
                        >
                          Contact persoon
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sx={{
                          paddingTop: "24px !important",
                        }}
                      >
                        <Typography
                          className={mainStyle.textFieldLabel}
                          align="left"
                        >
                          Naam *
                        </Typography>
                        <TextField
                          variant="outlined"
                          fullWidth
                          className={mainStyle.textFieldSmall}
                          onChange={(e) => {
                            setContactPersonName(e.target.value);
                            setContactPersonNameError(false);
                          }}
                          error={contactPersonNameError !== false}
                          helperText={contactPersonNameError}
                          value={contactPersonName}
                          disabled={loading}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Typography
                          className={mainStyle.textFieldLabel}
                          align="left"
                        >
                          Email *
                        </Typography>
                        <TextField
                          variant="outlined"
                          fullWidth
                          className={mainStyle.textFieldSmall}
                          onChange={(e) => {
                            setContactPersonEmail(e.target.value);
                            setContactPersonEmailError(false);
                          }}
                          error={contactPersonEmailError !== false}
                          helperText={contactPersonEmailError}
                          value={contactPersonEmail}
                          disabled={loading}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Typography
                          className={mainStyle.textFieldLabel}
                          align="left"
                        >
                          Telefoon Nummer
                        </Typography>
                        <TextField
                          variant="outlined"
                          fullWidth
                          className={mainStyle.textFieldSmall}
                          onChange={(e) => {
                            setContactPersonNumber(e.target.value);
                            setContactPersonNumberError(false);
                          }}
                          error={contactPersonNumberError !== false}
                          helperText={contactPersonNumberError}
                          value={contactPersonNumber}
                          disabled={loading}
                        />
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
              </>
            )}

            <Grid item xs={4} sm={6} md={8} style={{ paddingRight: "0px" }}>
              <Box
                className={mainStyle.wave}
                display="flex"
                alignItems="center"
                style={{ height: "100%" }}
              >
                <div className={mainStyle.waveImg} />
              </Box>
            </Grid>
            <Grid item xs={8} sm={6} md={4} style={{ paddingLeft: "9px" }}>
              <LoadingButton
                className={`${mainStyle.button} ${buttonFail}`}
                onClick={sendEnlistForm}
                loading={loading}
                fullWidth
              >
                {`${language.enlist.enlistForm.send} >>`}
              </LoadingButton>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </Fragment>
  );
};

// Set the state for this component to the global state
const mapStateToProps = (state) => ({
  lang: state.lang,
  app: state.app,
});

// Connect this component to the redux global state and bring in the state and needed functions
export default connect(mapStateToProps, { sendEnlistEmail, clearAppResponse })(
  Enlist
);

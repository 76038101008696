// Import the React modules
import React, { Fragment } from "react";

// Import Material-UI components
import { Box, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";

// Create style
const useStyles = makeStyles(() => ({
  TitleBoxRoot: {
    minWidth: "180.82px",
    height: "89.85px",
    backgroundColor: "rgba(241, 90, 36, 0.61)",
    paddingLeft: "20px",
    paddingRight: "20px",
  },
  TitleBoxText: {
    color: "#F2F2F2",
    fontSize: "30px",
    lineHeight: "41px",
    position: "relative",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
}));

export const TitleBox = ({ rootColor, textColor, children }) => {
  const classes = useStyles();
  return (
    <Fragment>
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <Box
          className={classes.TitleBoxRoot}
          sx={{ backgroundColor: `${rootColor} !important` }}
        >
          <Typography
            className={classes.TitleBoxText}
            sx={{ color: `${textColor} !important` }}
            align="center"
          >
            {children}
          </Typography>
        </Box>
      </Box>
    </Fragment>
  );
};

// Import the types
import {
  SET_WATERFIETS_RESPONSE,
  CLEAR_WATERFIETS_RESPONSE,
  SET_WATERFIETS_LOADING_TRUE,
  SET_WATERFIETS_LOADING_FALSE,
  SET_WATERFIETS_ERROR,
  WATERFIETS_CLIENTS_LOADED,
  RESET_ALL,
} from "../types";

// Create the initial state
const initialState = {
  loadingWaterFiets: false,
  waterFietsSuccess: null,
  waterFietsResult: null,
  waterFietsError: null,
  waterFietsClients: null,
};

// Export the new new state or updated state
const waterFietsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_WATERFIETS_RESPONSE:
      return {
        ...state,
        waterFietsSuccess: action.payload.success,
        waterFietsResult: action.payload.data,
      };
    case CLEAR_WATERFIETS_RESPONSE:
      return {
        ...state,
        waterFietsSuccess: null,
        waterFietsResult: null,
      };
    case SET_WATERFIETS_LOADING_TRUE:
      return {
        ...state,
        loadingWaterFiets: true,
      };
    case SET_WATERFIETS_LOADING_FALSE:
      return {
        ...state,
        loadingWaterFiets: false,
      };
    case SET_WATERFIETS_ERROR:
      return {
        ...state,
        waterFietsError: action.payload,
        loadingWaterFiets: false,
      };
    case WATERFIETS_CLIENTS_LOADED:
      return {
        ...state,
        waterFietsClients: action.payload,
        loadingWaterFiets: false,
      };
    case RESET_ALL:
      return {
        ...state,
        loadingWaterFiets: false,
        waterFietsSuccess: null,
        waterFietsResult: null,
        waterFietsError: null,
        waterFietsClients: null,
      };
    default:
      return state;
  }
};

export default waterFietsReducer;

// Import the React modules
import React, { Fragment } from "react";
import { Route, Routes } from "react-router-dom";

// Import the Redux modules
import { connect } from "react-redux";

// Import Material-UI Components
import { Box } from "@mui/material";
import { makeStyles } from "@mui/styles";

// Import website components
import PDNE from "components/PageDoesNotExist/PageDoesNotExist";

import PanelDrawer from "./Components/PanelDrawer/PanelDrawer";
import UsersLayout from "./Pages/Users/UsersLayout";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    paddingTop: "10px",
  },
}));

const AdminPanelLayout = () => {
  // Import style
  const classes = useStyles();

  return (
    <Fragment>
      <Box className={classes.root}>
        <PanelDrawer />
        <Routes>
          <Route path="/users/*" element={<UsersLayout />} />
          <Route path="*" element={<PDNE />} />
        </Routes>
      </Box>
    </Fragment>
  );
};

export default connect(null, null)(AdminPanelLayout);

// Import the React modules
import React, { useEffect } from "react";

// Import the Redux modules
import { connect } from "react-redux";
import { getLang } from "../../reduxStates/langState/langActions";

// Import material-ui components
import { Typography } from "@mui/material";

const LoadingTimeout = ({ getLang }) => {
  // Create the local states
  const [count, setCount] = React.useState(10);
  const [lastCount, setLastCount] = React.useState(10);

  useEffect(() => {
    if (count > 0) {
      // Countdown
      setTimeout(() => setCount(count - 1), 1000);
    } else if (count === 0) {
      // Get language
      getLang();
      setCount(lastCount + 10);
      setLastCount(lastCount + 10);
    }
    // Prevent any useless errors with net line:
    // eslint-disable-next-line
  }, [count]);

  React.useEffect(() => {
    return () => {
      setCount(-1);
    };
  }, []);

  // Return the component
  return (
    <div>
      <Typography variant="h3" align="center" gutterBottom>
        We couldn't load the necessery information, if this problem presists
        please contact SwimTechNL
      </Typography>
      <Typography variant="h4" align="center">
        We will try again in {count}
      </Typography>
    </div>
  );
};

export default connect(null, { getLang })(LoadingTimeout);

// Import the React modules
import React from "react";

// Import material-ui components
import { Box, Typography } from "@mui/material";

const PageDoesNotExist = () => {
  return (
    <Box sx={{ paddingTop: "32px", width: "100%" }}>
      <Typography variant="h3" gutterBottom align="center">
        This page does not exist
      </Typography>
      <Typography variant="h4" align="center">
        Are you sure you are on the right page?
      </Typography>
    </Box>
  );
};

// Connect this component to the redux global state and bring in the state and needed functions
export default PageDoesNotExist;

// Import the React modules
import React, { Fragment } from "react";

import Header from "./Components/Header";
import Content from "./Components/Content";

const Home = () => {
  //   Return the page
  return (
    <Fragment>
      <Header />
      <Content />
    </Fragment>
  );
};

// Connect this component to the redux global state and bring in the state and needed functions
export default Home;

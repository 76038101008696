// Import the React modules
import React, { Fragment } from "react";

// Import Luxon Modules
import { DateTime } from "luxon";

// Import the Redux modules
import { connect } from "react-redux";

// Import Material-UI Components
import {
  Typography,
  Divider,
  Table,
  TableBody,
  TableRow,
  TableCell,
} from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  divider: {
    marginTop: "8px",
    marginBottom: "8px",
  },
}));

const Summary = ({ user: { loggedInUser } }) => {
  // Import style
  const classes = useStyles();

  return (
    <Fragment>
      <Typography variant="h4">Profile summary</Typography>
      <Divider className={classes.divider} />
      <Table>
        <TableBody>
          <TableRow className={classes.table}>
            <TableCell>First name</TableCell>
            <TableCell align="right">{loggedInUser.firstName}</TableCell>
          </TableRow>
          <TableRow className={classes.table} selected>
            <TableCell>Middle name</TableCell>
            <TableCell align="right">{loggedInUser.middleName}</TableCell>
          </TableRow>
          <TableRow className={classes.table}>
            <TableCell>Last name</TableCell>
            <TableCell align="right">{loggedInUser.lastName}</TableCell>
          </TableRow>
          <TableRow className={classes.table} selected>
            <TableCell>Email</TableCell>
            <TableCell align="right">{loggedInUser.email}</TableCell>
          </TableRow>
          <TableRow className={classes.table}>
            <TableCell>2FA enabled</TableCell>
            <TableCell align="right">{`${loggedInUser.TwoFactorAuthEnabled}`}</TableCell>
          </TableRow>
          <TableRow className={classes.table} selected>
            <TableCell>Roles</TableCell>
            <TableCell align="right">{loggedInUser.roles.join(", ")}</TableCell>
          </TableRow>
          <TableRow className={classes.table}>
            <TableCell>Registered on</TableCell>
            <TableCell align="right">
              {DateTime.fromISO(loggedInUser.createdAt)
                .setLocale("nl")
                .toLocaleString()}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps, null)(Summary);

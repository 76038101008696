// Import the React modules
import React, { Fragment } from "react";
import { Link as RouterLink, useParams } from "react-router-dom";

// Import Luxon Modules
import { DateTime } from "luxon";

// Import the Redux modules
import { connect } from "react-redux";

// Import Material-UI Components
import {
  Box,
  CircularProgress,
  Container,
  Typography,
  Grid,
  Breadcrumbs,
  Link,
  Paper,
  Divider,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Button,
} from "@mui/material";
import { makeStyles } from "@mui/styles";

// Import Material-UI Icons
import EditIcon from "@mui/icons-material/Edit";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";

// Import website components
import PDNE from "components/PageDoesNotExist/PageDoesNotExist";
import DeleteDialog from "./DeleteDialog";
import EditDialog from "./EditDialog";

// Import helpers
import { checkRoles } from "helpers/other";

const useStyles = makeStyles((theme) => ({
  divider: {
    marginTop: "8px",
    marginBottom: "8px",
  },
}));

const Single = ({ user: { loggedInUser, users, loadingUser } }) => {
  let params = useParams();

  // Import style
  const classes = useStyles();

  // // Create local state
  const [single, setSingle] = React.useState();

  const [deleteDialogOpen, setDeleteDialogOpen] = React.useState(false);
  const [editDialogOpen, setEditDialogOpen] = React.useState(false);

  // // UseEffect functions
  // Find the selected user and save in single state
  React.useEffect(() => {
    if (users) {
      let found = users.find((singleUser) => singleUser._id === params.id);

      if (!found) {
        setSingle("Not found");
      } else {
        setSingle(found);
      }
    }
    // Prevent any useless errors with net line:
    // eslint-disable-next-line
  }, [users]);

  // // Button functions
  const openDeleteDialog = () => {
    setDeleteDialogOpen(true);
  };

  const closeDeleteDialog = () => {
    setDeleteDialogOpen(false);
  };

  const openEditDialog = () => {
    setEditDialogOpen(true);
  };

  const closeEditDialog = () => {
    setEditDialogOpen(false);
  };

  if (!single) {
    return (
      <Box align="center">
        <CircularProgress />
      </Box>
    );
  }

  if (single === "Not found") {
    return <PDNE />;
  }

  return (
    <Fragment>
      <Container>
        <Box sx={{ paddingTop: "16px", paddingBottom: "16px" }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Breadcrumbs>
                <Link
                  color="inherit"
                  component={RouterLink}
                  to="/adminpanel/users"
                >
                  Users
                </Link>
                <Typography color="textPrimary">
                  {[single?.firstName, single?.middleName, single?.lastName]
                    .filter(Boolean)
                    .join(" ")}
                </Typography>
              </Breadcrumbs>
            </Grid>
            <Grid item xs={12}>
              <Paper>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography variant="h4" align="center">
                      {[single?.firstName, single?.middleName, single?.lastName]
                        .filter(Boolean)
                        .join(" ")}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Divider className={classes.divider} />
                  </Grid>

                  <Grid item xs={12}>
                    <Table>
                      <TableBody>
                        <TableRow>
                          <TableCell>First name</TableCell>
                          <TableCell align="right">
                            {single.firstName}
                          </TableCell>
                        </TableRow>
                        <TableRow selected>
                          <TableCell>Middle name</TableCell>
                          <TableCell align="right">
                            {single.middleName}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Last name</TableCell>
                          <TableCell align="right">{single.lastName}</TableCell>
                        </TableRow>
                        <TableRow selected>
                          <TableCell>Email</TableCell>
                          <TableCell align="right">{single.email}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Email verified</TableCell>
                          <TableCell align="right">{`${single.emailVerified}`}</TableCell>
                        </TableRow>
                        <TableRow selected>
                          <TableCell>2FA enabled</TableCell>
                          <TableCell align="right">{`${single.TwoFactorAuthEnabled}`}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Roles</TableCell>
                          <TableCell align="right">
                            {single.roles.join(", ")}
                          </TableCell>
                        </TableRow>
                        <TableRow selected>
                          <TableCell>Is inactive</TableCell>
                          <TableCell align="right">{`${single.isInactive}`}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Registered on</TableCell>
                          <TableCell align="right">
                            {DateTime.fromISO(single.createdAt)
                              .setLocale("nl")
                              .toLocaleString()}
                          </TableCell>
                        </TableRow>
                        <TableRow selected>
                          <TableCell>Last updated at</TableCell>
                          <TableCell align="right">
                            {DateTime.fromISO(single.updatedAt)
                              .setLocale("nl")
                              .toLocaleString()}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </Grid>
                </Grid>
                <Grid
                  container
                  spacing={2}
                  justifyContent="flex-end"
                  sx={{ padding: "16px" }}
                >
                  <Grid item>
                    <Button
                      variant="contained"
                      onClick={openDeleteDialog}
                      color="error"
                      disabled={
                        loadingUser ||
                        single?.isSwimTechNLUser ||
                        (single?.roles.includes("admin") &&
                          !loggedInUser.roles.includes("admin")) ||
                        single?._id === loggedInUser?._id ||
                        !checkRoles(["admin"], loggedInUser.roles)
                      }
                    >
                      <DeleteForeverIcon style={{ marginRight: "5px" }} />{" "}
                      Delete
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      variant="contained"
                      onClick={openEditDialog}
                      color="primary"
                      disabled={
                        loadingUser ||
                        single?.isSwimTechNLUser ||
                        (single?.roles.includes("admin") &&
                          !loggedInUser.roles.includes("admin")) ||
                        single?._id === loggedInUser?._id ||
                        !checkRoles(["admin"], loggedInUser.roles)
                      }
                    >
                      <EditIcon style={{ marginRight: "5px" }} /> Edit
                    </Button>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        </Box>
      </Container>
      <DeleteDialog
        deleteDialogOpen={deleteDialogOpen}
        closeDeleteDialog={closeDeleteDialog}
        single={single}
      />
      <EditDialog
        editDialogOpen={editDialogOpen}
        closeEditDialog={closeEditDialog}
        single={single}
      />
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps, null)(Single);
